import {
  DELETE_SUPPORT_MESSAGE,
  DELETE_SUPPORT_MESSAGE_FAILURE,
  DELETE_SUPPORT_MESSAGE_SUCCESS,
  GET_ALL_SUPPORT_MESSAGES,
  GET_ALL_SUPPORT_MESSAGES_FAILURE,
  GET_ALL_SUPPORT_MESSAGES_SUCCESS,
  GET_USER_EMAILS,
  GET_USER_EMAILS_FAILURE,
  GET_USER_EMAILS_SUCCESS,
  SEND_CUSTOM_EMAIL,
  SEND_CUSTOM_EMAIL_FAILURE,
  SEND_CUSTOM_EMAIL_SUCCESS,
  SEND_SUPPORT_MESSAGE_REPLY,
  SEND_SUPPORT_MESSAGE_REPLY_FAILURE,
  SEND_SUPPORT_MESSAGE_REPLY_SUCCESS,
} from "../constants";

const initialState = {
  count: 0,
  userEmails: [],
  supportMessages: [],
  supportMessage: {},
  error: null,
  isLoading: false,
};

export default function ContactSupportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUPPORT_MESSAGES:
    case DELETE_SUPPORT_MESSAGE:
    case SEND_SUPPORT_MESSAGE_REPLY:
    case GET_USER_EMAILS:
    case SEND_CUSTOM_EMAIL:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_SUPPORT_MESSAGES_SUCCESS:
      state = {
        ...state,
        supportMessages: action?.payload?.data?.supportMessages,
        count: action?.payload?.data?.count,
        isLoading: false,
      };
      break;
    case GET_USER_EMAILS_SUCCESS:
      state = {
        ...state,
        userEmails: action?.payload?.data?.users,
        isLoading: false,
      };
      break;
    case DELETE_SUPPORT_MESSAGE_SUCCESS:
    case SEND_SUPPORT_MESSAGE_REPLY_SUCCESS:
    case SEND_CUSTOM_EMAIL_SUCCESS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_SUPPORT_MESSAGES_FAILURE:
    case DELETE_SUPPORT_MESSAGE_FAILURE:
    case SEND_SUPPORT_MESSAGE_REPLY_FAILURE:
    case GET_USER_EMAILS_FAILURE:
    case SEND_CUSTOM_EMAIL_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
