import React from 'react'
import { Row, Col, Table, Space } from "antd";
const AntDesignTable = ({style, columns, data, onRow, props, className,loading}) => {
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
    <Table
      style={style}
      columns={columns}
      pagination={false}
      dataSource={data}
      onRow={onRow}
      className={className}
      loading={loading}
      {...props}
    />
    </Col>
  )
}

export default AntDesignTable
