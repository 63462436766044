import { yearPickerClasses } from "@mui/lab";
import { all, takeLatest } from "redux-saga/effects";

import {ADD_SERVICE,ADD_SUB_ADMIN,BAN_USER,DELETE_COMMENT,DELETE_POLL,DELETE_POST,DELETE_SERVICE,DELETE_SUB_ADMIN,DELETE_SUB_SERVICE,DELETE_SUPPORT_MESSAGE,FORGET_PASSWORD,GET_ALL_COMMENTS,GET_ALL_POSTS,GET_ALL_PROFILES,GET_ALL_SERVICES,GET_ALL_SERVICE_PROVIDERS,GET_ALL_SERVICE_USERS,GET_ALL_SUB_ADMINS,GET_ALL_SUB_SERVICES,GET_ALL_SUPPORT_MESSAGES,GET_NO_OF_SERVICE_STATS,GET_POST_CREATED_STATS,GET_SERVICE_PROVIDER_DETAILS,GET_SERVICE_USER_STATS,GET_SUBSCRIPTION_STATS,GET_USER_EMAILS,GET_USER_STATS,IGNORE_COMMENT_REPORT,IGNORE_POST_REPORT,IGNORE_PROFILE_REPORT,LOGOUT,RESET_PASSWORD,SEND_CUSTOM_EMAIL,SEND_SERVICE_PROVIDER_EMAIL,SEND_SERVICE_USER_EMAIL,SEND_SUPPORT_MESSAGE_REPLY,SERVICE_REQUESTED_STATS,SIGNIN, SUSPEND_USER, UPDATE_SERVICE, UPDATE_SERVICE_PROVIDER_STATUS, UPDATE_SERVICE_USER_STATUS, UPDATE_SUB_ADMIN, VERIFY_CODE, GET_ADS_REQUEST, UPDATE_AD_REQUEST, DELETE_AD_REQUEST, EDIT_AD_REQUEST, GET_ALL_PROMOS, UPDATE_PROMO_STATUS, DELETE_PROMO, ADD_PROMO, EDIT_PROMO, SERVICE_PROVIDER_BUSINESS_STATUS, GET_COUNT, ACTION_REQ, SIGNUPS, DELETED_ACC, SERVICE_PROVIDER, JOBS, ADS, SERVICES, POST_AND_REPORTS} from "../constants";
import {
  AuthMiddleware,
  ContactSupportMiddleware,
  DashboardMiddleware,
  ReportsMiddleware,
  ServiceMiddleware,
  ServiceProvidersMiddleware,
  ServiceUsersMiddleware,
  SubAdminMiddleware,
  AdManagementMiddleware,
  PromoManagementMiddleware,
} from "../middlewares";

export function* Sagas() {
  yield all([
    //Login flow saga
    yield takeLatest(SIGNIN, AuthMiddleware.SignIn),
    yield takeLatest(LOGOUT, AuthMiddleware.Logout),
    yield takeLatest(FORGET_PASSWORD, AuthMiddleware.ForgotPassword),
    yield takeLatest(VERIFY_CODE, AuthMiddleware.VerifyCode),
    yield takeLatest(RESET_PASSWORD, AuthMiddleware.ResetPassword),

    //Service providers saga
    yield takeLatest(GET_ALL_SERVICE_PROVIDERS, ServiceProvidersMiddleware.getServiceProviders),
    yield takeLatest(UPDATE_SERVICE_PROVIDER_STATUS, ServiceProvidersMiddleware.updateServiceProviderStatus),
    yield takeLatest(SEND_SERVICE_PROVIDER_EMAIL, ServiceProvidersMiddleware.sendServiceProviderEmail),
    yield takeLatest(GET_SERVICE_PROVIDER_DETAILS, ServiceProvidersMiddleware.getServiceProviderDetails),
    yield takeLatest(SERVICE_PROVIDER_BUSINESS_STATUS, ServiceProvidersMiddleware.updateServiceProviderBusinessStatus),

    //Services saga
    yield takeLatest(GET_ALL_SERVICES, ServiceMiddleware.getServices),
    yield takeLatest(GET_ALL_SUB_SERVICES, ServiceMiddleware.getSubServices),
    yield takeLatest(ADD_SERVICE, ServiceMiddleware.addService),
    yield takeLatest(DELETE_SERVICE, ServiceMiddleware.deleteService),
    yield takeLatest(UPDATE_SERVICE, ServiceMiddleware.updateService),
    yield takeLatest(DELETE_SUB_SERVICE, ServiceMiddleware.deleteSubService),

    //Service users saga
    yield takeLatest(GET_ALL_SERVICE_USERS, ServiceUsersMiddleware.getServiceUsers),
    yield takeLatest(UPDATE_SERVICE_USER_STATUS, ServiceUsersMiddleware.updateServiceUserStatus),
    yield takeLatest(SEND_SERVICE_USER_EMAIL, ServiceUsersMiddleware.sendServiceUserEmail),

    //Sub admins saga
    yield takeLatest(GET_ALL_SUB_ADMINS, SubAdminMiddleware.getSubAdmins),
    yield takeLatest(ADD_SUB_ADMIN, SubAdminMiddleware.addSubAdmin),
    yield takeLatest(DELETE_SUB_ADMIN, SubAdminMiddleware.deleteSubAdmin),
    yield takeLatest(UPDATE_SUB_ADMIN, SubAdminMiddleware.updateSubAdmin),

    //Reports saga
    yield takeLatest(GET_ALL_PROFILES, ReportsMiddleware.getAllProfiles),
    yield takeLatest(IGNORE_PROFILE_REPORT, ReportsMiddleware.ignoreProfileReport),
    yield takeLatest(GET_ALL_COMMENTS, ReportsMiddleware.getAllComments),
    yield takeLatest(IGNORE_COMMENT_REPORT, ReportsMiddleware.ignoreCommentReport),
    yield takeLatest(DELETE_COMMENT, ReportsMiddleware.deleteComment),

    yield takeLatest(GET_ALL_POSTS, ReportsMiddleware.getAllPosts),
    yield takeLatest(IGNORE_POST_REPORT, ReportsMiddleware.ignorePostReport),
    yield takeLatest(DELETE_POST, ReportsMiddleware.deletePost),
    yield takeLatest(DELETE_POLL, ReportsMiddleware.deletePoll),

    yield takeLatest(BAN_USER, ReportsMiddleware.banUser),
    yield takeLatest(SUSPEND_USER, ReportsMiddleware.suspendUser),
    yield takeLatest(GET_USER_STATS, DashboardMiddleware.getUserStats),
    yield takeLatest(GET_POST_CREATED_STATS, DashboardMiddleware.getPostCreated),
    yield takeLatest(GET_SERVICE_USER_STATS, DashboardMiddleware.getServiceUser),
    yield takeLatest(GET_NO_OF_SERVICE_STATS, DashboardMiddleware.getNoOfServices),
    yield takeLatest(SERVICE_REQUESTED_STATS, DashboardMiddleware.serviceRequested),
    yield takeLatest(GET_SUBSCRIPTION_STATS, DashboardMiddleware.getSubscriptionStats),
    yield takeLatest(GET_COUNT, DashboardMiddleware.getCount),
    yield takeLatest(ACTION_REQ, DashboardMiddleware.getActionReq),
    yield takeLatest(SIGNUPS, DashboardMiddleware.getSignups),
    yield takeLatest(DELETED_ACC, DashboardMiddleware.getDeletedAcc),
    yield takeLatest(SERVICE_PROVIDER, DashboardMiddleware.getServiceProviders),
    yield takeLatest(JOBS, DashboardMiddleware.getJobs),
    yield takeLatest(ADS, DashboardMiddleware.getAds),
    yield takeLatest(SERVICES, DashboardMiddleware.getServices),
    yield takeLatest(POST_AND_REPORTS, DashboardMiddleware.getPostAndReports),

    //Contact Support saga
    yield takeLatest(GET_ALL_SUPPORT_MESSAGES, ContactSupportMiddleware.getAllSupportMessages),
    yield takeLatest(DELETE_SUPPORT_MESSAGE, ContactSupportMiddleware.deleteSupportMessage),
    yield takeLatest(SEND_SUPPORT_MESSAGE_REPLY, ContactSupportMiddleware.sendSupportMessageReply),
    yield takeLatest(SEND_CUSTOM_EMAIL, ContactSupportMiddleware.sendCustomEmail),
    yield takeLatest(GET_USER_EMAILS, ContactSupportMiddleware.getEmails),
    yield takeLatest(GET_ADS_REQUEST, AdManagementMiddleware.getAdsRequest),
    yield takeLatest(UPDATE_AD_REQUEST, AdManagementMiddleware.updateAdRequest),
    yield takeLatest(DELETE_AD_REQUEST, AdManagementMiddleware.deleteAdRequest),
    yield takeLatest(EDIT_AD_REQUEST, AdManagementMiddleware.editAdRequest),

    //Promo Management Sagas
    yield takeLatest(GET_ALL_PROMOS, PromoManagementMiddleware.getPromos),
    yield takeLatest(ADD_PROMO, PromoManagementMiddleware.addPromo),
    yield takeLatest(EDIT_PROMO, PromoManagementMiddleware.editPromo),
    yield takeLatest(UPDATE_PROMO_STATUS, PromoManagementMiddleware.updatePromoStatus),
    yield takeLatest(DELETE_PROMO, PromoManagementMiddleware.deletePromo)
  ]);
}
