import React from "react";
import { Row, Col } from "antd";
import { useMedia } from "use-media";
const LegendBox = (props) => {
  const { title, color } = props;
  const isXs = useMedia({ minWidth: "290px" });
  const isSm = useMedia({ minWidth: "576px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  return (
    <Row align="middle" style={{ flexWrap: "nowrap" }} gutter={[10, 0]}>
      <Col
        style={{
          height: "10px",
          width: "10px",
          backgroundColor: color ? color : "",
        }}
      ></Col>
      <Col
        xl={18}
        xxl={18}
        align={props.isEnd ? "middle" : "left"}
        style={{
          fontSize: isXxl ? "14px" : "12px",
          textTransform: "capitalize",
          color:'black'
        }}
      >
        {title}
      </Col>
    </Row>
  );
};

export default LegendBox;