import {
  GET_ALL_SERVICE_PROVIDERS,
  GET_ALL_SERVICE_PROVIDERS_FAILURE,
  GET_ALL_SERVICE_PROVIDERS_SUCCESS,
  GET_SERVICE_PROVIDER_DETAILS,
  GET_SERVICE_PROVIDER_DETAILS_FAILURE,
  GET_SERVICE_PROVIDER_DETAILS_SUCCESS,
  SEND_SERVICE_PROVIDER_EMAIL,
  SEND_SERVICE_PROVIDER_EMAIL_FAILURE,
  SEND_SERVICE_PROVIDER_EMAIL_SUCCESS,
  UPDATE_SERVICE_PROVIDER_STATUS,
  UPDATE_SERVICE_PROVIDER_STATUS_FAILURE,
  UPDATE_SERVICE_PROVIDER_STATUS_SUCCESS,
  SERVICE_PROVIDER_BUSINESS_STATUS,
  SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS,
  SERVICE_PROVIDER_BUSINESS_STATUS_FAILURE
} from "../constants";

export default class ServiceProvidersAction {
  static getAllServiceProviders(payload, cb) {
    console.log("my payload",payload)
    console.log("my cb", cb)
    return {
      type: GET_ALL_SERVICE_PROVIDERS,
      payload,
      cb,
    };
  }
  static getAllServiceProvidersSuccess(payload) {

    return {
      type: GET_ALL_SERVICE_PROVIDERS_SUCCESS,
      payload,
    };
  }
  static getAllServiceProvidersFailure(payload) {
    return {
      type: GET_ALL_SERVICE_PROVIDERS_FAILURE,
      payload,
    };
  }
  static updateServiceProviderStatus(id, payload, cb) {
    console.log("my payload2",payload)
    console.log("my cb2")
    return {
      type: UPDATE_SERVICE_PROVIDER_STATUS,
      id,
      payload,
      cb,
    };
  }
  static updateServiceProviderStatusSuccess(payload) {
    return {
      type: UPDATE_SERVICE_PROVIDER_STATUS_SUCCESS,
      payload,
    };
  }
  static updateServiceProviderStatusFailure(payload) {
    return {
      type: UPDATE_SERVICE_PROVIDER_STATUS_FAILURE,
      payload,
    };
  }
  static sendServiceProviderEmail(id, payload, cb) {
    return {
      type: SEND_SERVICE_PROVIDER_EMAIL,
      id,
      payload,
      cb,
    };
  }
  static sendServiceProviderEmailSuccess(payload) {
    return {
      type: SEND_SERVICE_PROVIDER_EMAIL_SUCCESS,
      payload,
    };
  }
  static sendServiceProviderEmailFailure(payload) {
    return {
      type: SEND_SERVICE_PROVIDER_EMAIL_FAILURE,
      payload,
    };
  }
  static getServiceProviderDetails(id, cb) {
    return {
      type: GET_SERVICE_PROVIDER_DETAILS,
      id,
      cb,
    };
  }
  static getServiceProviderDetailsSuccess(payload) {
    return {
      type: GET_SERVICE_PROVIDER_DETAILS_SUCCESS,
      payload,
    };
  }
  static getServiceProviderDetailsFailure(payload) {
    return {
      type: GET_SERVICE_PROVIDER_DETAILS_FAILURE,
      payload,
    };
  }
  static updateServiceProviderBusinessDocumentStatus(id,payload,cb) {
    return {
      type:SERVICE_PROVIDER_BUSINESS_STATUS,
      id,
      payload,
      cb
    }
  }
  static updateServiceProviderBusinessDocumentStatusSuccess(payload) {
    return {
      type:SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS,
      payload

    }
  }
  static updateServiceProviderBusinessDocumentStatusFailure(payload) {
    return {
      type:SERVICE_PROVIDER_BUSINESS_STATUS_FAILURE,
      payload

    }
  }
}
