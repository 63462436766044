import { Col, Pagination, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import * as Icon from "react-icons/all";
import useMedia from "use-media";

import { Box, Button, Modal, Table, Text, TextField } from "../../components";
import { Colors, Fonts, Images, Utils } from "../../config";
import { SubAdminAction } from "../../store/actions";

import "./style.css";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

function SubAdminManagement() {
  const isTablet = useMedia({ minWidth: "768px" });
  const isMobile = useMedia({ minWidth: "450px" });
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const subAdmins = useSelector((state) => state.subAdmins);
  const profileImgRef = useRef();
  const [ids, setIds] = useState([]);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    imageUrl: null,
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    imageUrl: "",
  });
  const [file, setFile] = useState(null);
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
  });

  const handleDeleteUser = () => {
    dispatch(
      SubAdminAction.deleteSubAdmin(user.id, () => {
        setDeleteModal(false);
        setUser({
          name: "",
          email: "",
          validEmail: true,
          validPass: true,
          password: "",
          imageUrl: null,
        });
        dispatch(SubAdminAction.getAllSubAdmins(state));
      })
    );
  };

  const handleAddUser = () => {
    if (!user.name.trim()) {
      setError({
        ...error,
        name: "Name is required",
      });
    } else if (!user.email.trim()) {
      setError({
        ...error,
        name: "",
        email: "Email is required",
      });
    } else if (!editUser && !user.validEmail) {
      setError({
        ...error,
        name: "",
        email: "Email is not valid",
      });
    } else if (!user.password.trim()) {
      setError({
        ...error,
        email: "",
        password: "Password is required",
      });
    } else if (!editUser && !user.validPass) {
      setError({
        ...error,
        email: "",
        password: "Password is not valid",
      });
    } else if (!user.imageUrl) {
      setError({
        ...error,
        password: "",
        name: "",
        imageUrl: "Image is required",
      });
    } else {
      const formData = new FormData();
      formData.append("fullName", user.name);
      formData.append("email", user.email);
      formData.append("password", user.password);
      formData.append("picture", file);
      editUser
        ? dispatch(
            SubAdminAction.updateSubAdmin(user.id, formData, (res) => {
              setShowModal(false);
              setUser({
                name: "",
                email: "",
                validEmail: true,
                validPass: true,
                password: "",
                imageUrl: null,
              });
              setEditUser(false);
              if (res.status === 200) {
                dispatch(SubAdminAction.getAllSubAdmins(state));
              }
            })
          )
        : dispatch(
            SubAdminAction.addSubAdmin(formData, (res) => {
              setShowModal(false);
              setUser({
                name: "",
                email: "",
                validEmail: true,
                validPass: true,
                password: "",
                imageUrl: null,
              });
              if (res.status === 200) {
                dispatch(SubAdminAction.getAllSubAdmins(state));
              }
            })
          );
    }
  };

  const validateEmail = (email) => {
    let validEmail = Utils.emailRegex.test(String(email).toLowerCase());
    setUser({
      ...user,
      email,
      validEmail,
    });
    setError({
      ...error,
      email: "",
    });
  };

  const validatePass = (password) => {
    let validPass = Utils.passwordRegex.test(String(password));
    setUser({
      ...user,
      password,
      validPass,
    });
    setError({
      ...error,
      password: "",
    });
  };

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }
    setFile(file);
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setUser({
            ...user,
            imageUrl: result,
          });
          setError({
            ...error,
            imageUrl: "",
          });
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  useEffect(() => {
    dispatch(SubAdminAction.getAllSubAdmins(state));
  }, [state]);

  return (
    <>
      <Box>
        <Row justify="space-between">
          <Col className="flex-row-start">
            <Text.Heading
              fontSize={isMobile ? 24 : 20}
              fontFamily={Fonts["Poppins-SemiBold"]}
              color={Colors.Black}
              fontWeight={600}
              text={"Sub-Admin Management"}
            />
          </Col>
          <Col
            md={8}
            sm={21}
            xs={24}
            className={
              isTablet
                ? "flex-end"
                : isMobile
                ? "flex-row-start mt-20"
                : "row-flex-center mt-20"
            }
          >
            <Button.Basic
              onClick={() => {
                setShowModal(true);
              }}
              text={"Add Sub-Admin"}
              startIcon={<Icon.ImUserPlus color="white" />}
              className="add-admin-btn"
            />
          </Col>
        </Row>
        <Row justify="space-between" className="mt-20">
          <Col span={24}>
            <Table.AntDesignTable
              className="sub-admin-table"
              columns={[
                {
                  title: "Image",
                  dataIndex: "imageThumbUrl",
                  key: "imageThumbUrl",
                  render: (text) => (
                    <span>
                      <img className="sub-admin-avatar" src={text} />
                    </span>
                  ),
                },
                {
                  title: "Admin Name",
                  dataIndex: "fullName",
                  key: "fullName",
                  render: (text) => {
                    return <span>{text}</span>;
                  },
                },
                {
                  title: "Email Address",
                  dataIndex: "email",
                  key: "email",
                  render: (text) => {
                    return <span>{text}</span>;
                  },
                },
                {
                  title: "Password",
                  dataIndex: "password",
                  key: "password",
                  render: (text, record) => {
                    return (
                      <span className="user-password">
                        {ids.includes(record.id)
                          ? text
                          : Array.from(text).map((i) => "*")}
                      </span>
                    );
                  },
                },
                {
                  tite: "",
                  dataIndex: "",
                  key: "",
                  render: (text, record) => {
                    if (ids.includes(record.id)) {
                      return (
                        <Icon.AiOutlineEyeInvisible
                          color={Colors.Primary}
                          fontSize={"26px"}
                          onClick={() =>
                            setIds(ids.filter((id) => id !== record.id))
                          }
                        />
                      );
                    } else {
                      return (
                        <Icon.AiOutlineEye
                          color={Colors.Primary}
                          fontSize={"26px"}
                          onClick={() => setIds([...ids, record.id])}
                        />
                      );
                    }
                  },
                },
                {
                  title: "",
                  dataIndex: "",
                  key: "",
                  render: (text, record) => {
                    return (
                      <span className="flex-row">
                        <img
                          onClick={() => {
                            setShowModal(true);
                            setEditUser(true);
                            setUser({
                              id: record?.id,
                              name: record?.fullName,
                              email: record?.email,
                              password: record?.password,
                              imageUrl: record?.imageThumbUrl,
                            });
                          }}
                          src={Images.EditIcon}
                        />{" "}
                        <img
                          src={Images.DeleteIcon}
                          onClick={() => {  
                            setUser(record);
                            setDeleteModal(true);
                          }}
                        />
                      </span>
                    );
                  },
                },
              ]}
              data={subAdmins?.subAdmins}
              pagination={true}
              rowKey="key"
              loading={false}
            />
            <Row justify="center" className="mt-20">
              <Col>
                <Pagination
                  responsive="true"
                  showLessItems="true"
                  pageSize={10}
                  current={page}
                  onChange={handlePaginationChange}
                  total={subAdmins?.count}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Box>
      {deleteModal && (
        <Modal.ConfirmationModal
          title={""}
          isModalVisible={deleteModal}
          heading={"Are you sure you want to delete this user?"}
          handleCancel={() => setDeleteModal(false)}
          handleDelete={handleDeleteUser}
        ></Modal.ConfirmationModal>
      )}
      {showModal && (
        <Modal.AntDesignModal
          centered={true}
          title=""
          isModalVisible={showModal}
          handleCancel={() => {
            setShowModal(false);
            setEditUser(false);
            setFile(null);
            setUser({
              name: "",
              email: "",
              password: "",
              validEmail: true,
              validPass: true,
              imageUrl: null,
            });
            setError({
              name: "",
              email: "",
              password: "",
              imageUrl: "",
            });
          }}
        >
          <Row justify="center">
            <Col>
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={editUser ? "Edit Sub-Admin" : "Add Sub-Admin"}
              />
            </Col>
          </Row>
          <Row justify="center" className="mt-20">
            <Col>
              <div className="avatar-box">
                <img
                  src={
                    user?.imageUrl === null
                      ? Images?.DefaultUser
                      : user?.imageUrl
                  }
                  alt="user"
                  className="avatar-img"
                />
                <img
                  src={Images.UploadUser}
                  width={25}
                  alt="upload"
                  className="avatar-camera"
                  onClick={() => profileImgRef.current.click()}
                />
                <input
                  type={"file"}
                  ref={profileImgRef}
                  accept=".png, .jpg, .jpeg"
                  onChange={changeHandler}
                  className="avatar-file-upload"
                />
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={error?.imageUrl}
                textAlign="center"
                className="mb-10"
              />
            </Col>
          </Row>
          <Row justify="center" className="mt-10">
            <Col span={24}>
              <TextField.CustomInput
                label="Admin Name: "
                placeholder="Enter admin name"
                value={user?.name}
                size="large"
                type="text"
                className="sub-admin-input"
                onChange={(e) => setUser({ ...user, name: e.target.value })}
              />
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={error?.name}
                textAlign="center"
                className="mb-10"
              />
            </Col>
          </Row>
          <Row justify="center" className="mt-20">
            <Col span={24}>
              <TextField.CustomInput
                label="Email Address: "
                placeholder="Enter admin email"
                value={user?.email}
                size="large"
                type="text"
                className="sub-admin-input"
                onChange={(e) => validateEmail(e.target.value)}
              />
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={error?.email}
                textAlign="center"
                className="mb-10"
              />
            </Col>
          </Row>
          <Row justify="center" className="mt-20">
            <Col span={24}>
              <TextField.CustomInput
                password
                label="Password: "
                placeholder="Enter admin password"
                value={user?.password}
                size="large"
                type="text"
                className="sub-admin-input"
                onChange={(e) => validatePass(e.target.value)}
              />
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={error?.password}
                textAlign="center"
                className="mb-10"
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Button.Basic
                text={"Submit"}
                onClick={handleAddUser}
                className="sub-admin-add-btn mt-20"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
    </>
  );
}

export default SubAdminManagement;
