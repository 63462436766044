import { Modal, Row, Col } from "antd";
import React,{useState} from "react";
import TextField from "../CommonComponents/TextField";
import { Button, Text } from "../index";
import { Colors, Fonts } from "../../config";


const InputModal = ({
    title,
    isModalVisible,
    handleCancel,
    handleDelete,
    style,
    children,
    closable,
    centered,
    className,
    heading,
    subHeading,

}) => {

    const [reason,setreason] = useState('');
    const [showerror,setshowerror] = useState(false)


    const sendreason = () => {
            if(reason.length > 0 && reason.length <= 10){
                setshowerror(true)
            }
            else{
                handleDelete(reason)
                setreason('')
                setshowerror(!showerror)
            }
            
            // handleCancel()
        // }
    }

    return (
        <Row justify="space-between">
            <Col span={24}>
                <Modal
                    className={className ? className : "ant-modal"}
                    title={title}
                    style={style}
                    centered={centered}
                    visible={isModalVisible}
                    // onCancel={handleCancel}
                    footer={null}
                    cancelButtonProps={{ style: { display: "none" } }}
                    // closable={true}
                >
                    <button type="button" aria-label="Close" className="ant-modal-close" onClick={() => handleCancel()}><span class="ant-modal-close-x"><span role="img" aria-label="close" class="anticon anticon-close ant-modal-close-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></span></span></button>
                    <Row justify="center">
                        <Col
                            style={{
                                textAlign: "center",
                                margin: "10px 10px 0px 0px"
                            }}
                        >
                            <Text.Heading
                                fontSize={24}
                                fontFamily={Fonts["Poppins-Medium"]}
                                color={Colors.Black}
                                fontWeight={500}
                                text={heading}
                                textAlign="center"
                            />
                        </Col>
                    </Row>

                    {
                        subHeading ?
                            <Row justify="left">
                                <Col
                                    style={{
                                        textAlign: "left",
                                        margin: "30px 10px 0px 0px"
                                    }}
                                >
                                    <Text.Heading
                                        fontSize={20}
                                        fontFamily={Fonts["Poppins-Medium"]}
                                        color={Colors.DarkGray}
                                        fontWeight={400}
                                        text={subHeading}
                                        textAlign="left"
                                    />
                                </Col>
                            </Row>
                            :
                            <Row style={{ margin: '10px 10px 0px 0px' }}>
                                <Col span={24} />
                            </Row>
                    }

                    <Row style={{ marginTop: '10px', marginLeft: '5px', marginBottom: '40px' }}>
                        <Col span={24} >
                            <TextField.Textarea
                                size="large"
                                placeholder={"Your Message"}
                                maxLength={10000}
                                style={{
                                    border: "1px solid #F5F5F5",
                                    boxShadow: "0px 3px 6px #0000001A",
                                    borderRadius: 10,
                                }}
                            value={reason}
                            onChange={(e) => setreason(e.target.value)}
                            />

                            {   
                            
                            showerror ?
                                     <span style={{ color: "red" }}>Reason length should be atleast 10 characters</span> 
                                     :
                                ""
                            }

                        </Col>
                    </Row>

                    <Col span={24}>
                        <Button.Basic
                            // onClick={() => {
                            //   if (!reasonOfRejection.length) {
                            //     setErrors({
                            //       ...errors,
                            //       reasonOfRejectionError: "Please Enter the reason",
                            //     });
                            //     return;
                            //   } 
                            // }}
                            // onClick={()=>{sendreason}}
                            onClick={() => sendreason()}

                            text="Send"
                            style={{
                                backgroundColor: Colors.Primary,
                                borderColor: Colors.Primary,
                                color: Colors.White,
                                fontSize: 16,
                                fontFamily: Fonts["Poppins-SemiBold"],
                                fontWeight: 600,
                                padding: "10px 20px",
                                borderRadius: "10px",
                                width: "100%",
                                height: "50px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textTransform: "capitalize",
                                cursor: "pointer",
                            }}
                        />
                    </Col>



                </Modal>
            </Col>
        </Row>
    );
};

export default InputModal;
