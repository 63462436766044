import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./styles";
import { Utils } from "../../config"
import {
    Button,
    Loader,
    Select,
    TextField,
    Pagination,
    Switch,
    View,
    Picker,
    Menu,
    Popup,
    Modal,
    Badge,
    Avatar,
    Charts,
    Table,
    Skeleton
} from "../../components"
import { green } from '@mui/material/colors';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import InboxIcon from '@mui/icons-material/MoveToInbox';
// import PageviewIcon from '@mui/icons-material/Pageview';
// import { Modal } from "@mui/material";
import {
    LineChart,
    PieChart,
    BarChart,
    ResponsiveContainerWrapper
} from '../../components/Charts'



class ComponentsSample extends Component {
    constructor() {
        super()
        this.state = {
            toggle: "",
            date: "",
            time: "",
            open: false,
            snackBar: false,
            paginationCount: 5,
            paginationOffset: 0
        }
    }

    handlePagination = (pageNumber, limit = 10) => {
        let paginationOffset = (pageNumber - 1) * limit;
        this.setState({ paginationOffset })
        console.log({ pageNumber, paginationOffset })
        // getdata(limit, offset)
    };

    render() {
        console.log(this.state.open, 'UFFFFFFFFFF')
        const { ShadowView } = View

        return (
            <div style={styles.container}>
                <ShadowView elevation={10} containerStyle={styles.shadowViewContainer}>
                    <Button.Floating
                        variant="extended"
                        text="Add"
                        sx={{
                            color: 'white',
                            bgcolor: green[500],
                            '&:hover': {
                                bgcolor: green[600],
                            },
                        }}
                        buttonStyle={{ bottom: 30, right: 30 }}
                        onClick={() => { Utils.showSnackBar({ message: "Added Successfully" }) }}
                    />
                    <Menu.Drawer
                        containerStyle={{ position: "absolute", top: 20, left: 20 }}
                        data={[
                            { label: "Inbox", icon: <InboxIcon /> },
                            { label: "Starred", icon: <InboxIcon /> },
                            { label: "Send email", icon: <InboxIcon /> },
                            { label: "Drafts", icon: <InboxIcon /> },
                            { divider: true },
                            { label: "All mail", icon: <InboxIcon /> },
                            { label: "Trash", icon: <InboxIcon /> },
                        ]}
                        position={"left"}
                        onClick={(label) => { alert(label) }}
                    />
                    <Menu.Basic
                        data={[
                            { label: "Profile" },
                            { label: "My account" },
                            { divider: true },
                            { label: "Logout" },
                        ]}
                        containerStyle={{ position: "absolute", top: 20, right: 20 }}
                        onClick={(label) => alert(label)}
                    />
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Loader.Circular
                            size={20}
                            containerStyle={{ margin: 5 }}
                            sx={{ color: 'red' }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Button.Basic
                            variant="outlined"
                            text="Button"
                            href={"https://google.com/"}
                            onClick={() => alert("button click")}
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Button.Upload
                            variant="outlined"
                            text="Upload"
                            onChange={(e) => console.log(e.target.value)}
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={{ ...styles.ShadowViewSample, flex: 5 }}>
                        <Select.Radio
                            data={[
                                { value: "male", label: "Male" },
                                { value: "female", label: "Female" },
                                { value: "other", label: "Other (disabled)", disabled: true },
                            ]}
                            title={"Gender"}
                            defaultValue={"female"}
                            containerStyle={{ margin: 5 }}
                            onChange={(e) => console.log(e.target.value)}
                            sx={{
                                color: 'lightblue',
                            }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Select.Checkbox
                            data={[
                                { value: "apple", label: "Apple" },
                                { value: "orange", label: "Orange" },
                                { value: "banana", label: "Banana", defaultChecked: true }
                            ]}
                            containerStyle={{ margin: 5 }}
                            onChange={(e) => console.log(e.target.value)}
                            sx={{
                                color: 'lightblue',
                            }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <TextField.Basic
                            fieldStyle={{ backgroundColor: "#eee" }}
                            id={"outlined-basic"} //outlined-basic //filled-basic //standard-basic //outlined-read-only-input
                            label={"Email"} //Outlined //Filled //Standard
                            // variant={"filled"} //outlined //filled //standard
                            type={"email"}
                            defaultValue={"abc"}
                            placeholder={"placeholder"}
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <TextField.IconField
                            inputLabel="Password"
                            startIcon={<AccountCircle />}
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <TextField.SelectField
                            label="Fruits"
                            data={[
                                { value: "apple", label: "Apple" },
                                { value: "orange", label: "Orange" },
                            ]}
                            selectStyle={{ width: 200 }}
                            helperText="Please select fruit"
                            variant="filled"
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Button.Toggle
                            data={[
                                { value: "web", label: "Web" },
                                { value: "android", label: "Android" },
                                { value: "iOS", label: "iOS" }
                            ]}
                            onChange={(e) => this.setState({ toggle: e.target.value })}
                            value={this.state.toggle}
                            containerStyle={{ margin: 5 }}
                            color="warning"
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <TextField.SearchTextField
                            options={[
                                "web",
                                "android",
                                "iOS"
                            ]}
                            containerStyle={{ margin: 5 }}
                            placeholder="Search"
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Picker.DatePicker
                            value={this.state.date}
                            onChange={(date) => this.setState({ date })}
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Picker.TimePicker
                            value={this.state.time}
                            onChange={(time) => this.setState({ time })}
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Switch.Basic
                            color="secondary"
                            checked={true}
                            onChange={() => { }}
                            containerStyle={{ margin: 5 }}
                        />
                        <Switch.IOSwitch
                            checked={true}
                            onChange={() => { }}
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Pagination.Basic
                            count="10"
                            color="secondary"
                            paginationStyle={{ width: 200 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Popup.Alert
                            severity="warning"
                            title="you will get success"
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Button.Basic
                            variant="filled"
                            text="Open Modal"
                            onClick={() => this.setState({ open: true })}
                        />
                        <Modal.Basic
                            RightButtonText="Disagree"
                            LeftButtonText="Agree"
                            maxWidth="sm"
                            open={this.state.open}
                            onClickLeft={() => this.setState({ open: false })}
                            onClickRight={() => this.setState({ open: false })}
                            onClose={() => this.setState({
                                open: false
                            })}
                            dialogContent="let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running"
                            dialogTitle="Use Google's location service?"
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Badge.Basic
                            badgeContent="1000"
                            color="success"
                            max="999"
                            variant="dot"
                            Icon={ShoppingCartIcon}
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={styles.ShadowViewSample}>
                        <Avatar.Basic
                            alt="nora"
                            sx={{ width: 60, height: 60, backgroundColor: "lightgray" }}
                            // Icon={PageviewIcon}
                            src="https://blindspotetc.com/wp-content/uploads/2019/03/dummy-man-570x570.png"
                            containerStyle={{ margin: 5 }}
                        />
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={{ ...styles.ShadowViewSample, minWidth: 500 }}>
                        <Charts.ResponsiveContainer
                            style={{ margin: "27px 0px" }}
                            height={350}
                        >
                            {[
                                {
                                    MinutesWithVoiceChat: 3000,
                                    name: "Aug",
                                    MinutesWithoutVoiceChat: 1000,
                                },
                                {
                                    MinutesWithVoiceChat: 2500,
                                    name: "Sept",
                                    MinutesWithoutVoiceChat: 1500,
                                },
                                {
                                    MinutesWithVoiceChat: 2000,
                                    name: "Oct",
                                    MinutesWithoutVoiceChat: 2500,
                                },
                                {
                                    MinutesWithVoiceChat: 1500,
                                    name: "Nov",
                                    MinutesWithoutVoiceChat: 3000,
                                },
                            ]
                                .map((val) => val.MinutesWithoutVoiceChat)
                                .reduce((total, value) => total + value, 0) > 0 ? (
                                <Charts.LineChart
                                    data={[
                                        {
                                            MinutesWithVoiceChat: 3000,
                                            name: "Aug",
                                            MinutesWithoutVoiceChat: 1000,
                                        },
                                        {
                                            MinutesWithVoiceChat: 2500,
                                            name: "Sept",
                                            MinutesWithoutVoiceChat: 1500,
                                        },
                                        {
                                            MinutesWithVoiceChat: 2000,
                                            name: "Oct",
                                            MinutesWithoutVoiceChat: 2500,
                                        },
                                        {
                                            MinutesWithVoiceChat: 1500,
                                            name: "Nov",
                                            MinutesWithoutVoiceChat: 3000,
                                        },
                                    ]}
                                    lineColor={["#4BE5C0", "#4E5FF8"]}
                                    loading={this.state.appDownloadsLoading}
                                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                                />
                            ) : (
                                <h4
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    No Data Available for this month range!
                                </h4>
                            )}
                        </Charts.ResponsiveContainer>
                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={{ ...styles.ShadowViewSample, minWidth: 400 }}>
                        <Charts.ResponsiveContainer
                            // style={{ margin: "27px 0px" }}
                            height={400}
                            width={"100%"}
                        >
                            {[
                                { name: "Group A", price: 400 },
                                { name: "Group B", price: 300 },
                                { name: "Group C", price: 300 },
                            ]
                                .map((val) => val.price)
                                .reduce((total, value) => total + value, 0) > 0 ? (
                                <Charts.PieChart
                                    data={
                                        [
                                            { name: "Group A", price: 10 },
                                            { name: "Group B", price: 300 },
                                            { name: "Group C", price: 300 },
                                        ]

                                    }
                                    lineColor={["#4BE5C0", "#4E5FF8"]}
                                    loading={this.state.appDownloadsLoading}
                                // margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                                />
                            ) : (
                                <h4
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    No Data Available for this month range!
                                </h4>
                            )}
                        </Charts.ResponsiveContainer>
                    </ShadowView >
                    {/* <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={{ ...styles.ShadowViewSample, minWidth: 500 }}>
                          
                        //   <div style={{ height: 400, width: '100%' }}>
                        //   <Table.Basic
                        //         style={{ width: 500 }}
                        //         columns={[
                        //             { field: "id", headerName: "ID", width: 100 },
                        //             { field: "firstName", headerName: "First name", width: 180 },
                        //             { field: "age", headerName: "Age", width: 130 },
                        //         ]
                        //         }
                        //         rows={[
                        //             { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
                        //             { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
                        //         ]}
                        //         hideFooter
                        //         hideFooterPagination
                        //     />
                        //     </div>
                    </ShadowView> */}
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={{ ...styles.ShadowViewSample, display: 'flex', flexDirection: "column" }}>
                        <Skeleton.Basic
                            variant="circular"
                            width={50}
                            height={50}
                            animation="pulse"
                        />
                        <Skeleton.Basic
                            variant="Text"
                            width={200}
                            height={20}
                            animation="pulse"
                        />

                        <Skeleton.Basic
                            variant="circular"
                            width={50}
                            height={50}
                            animation="pulse"
                        />

                        <Skeleton.Basic
                            variant="rectangular"
                            width={200}
                            height={200}
                            animation="pulse"

                        />

                    </ShadowView>
                    <ShadowView elevation={styles.SampleViewElevation.elevation} containerStyle={{ ...styles.ShadowViewSample }}>
                        <Table.Simple
                            containerStyle={{ border: "1px solid lightgray", borderRadius:10 }}
                            alignContent="left"
                            data={[
                                { name: "test", values: ["a", "b", "c"] },
                                { name: "test2", values: ["a1", "b1", "c1"] },
                                { name: "test3", values: ["a8", "b8", "c8"] },
                                { name: "test4", values: ["a9", "b9", "c9"] },
                            ]}
                            paginationCount={this.state.paginationCount}
                            onChangePage={(e) => this.handlePagination(e.target.innerText, 10)}
                            defaultPage={1}
                        />
                    </ShadowView>
                </ShadowView>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(null, mapDispatchToProps)(ComponentsSample);