import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../../config'

function Container({children}) {
  return (
    <Box>
        {children}
    </Box>
  )
}

export default Container

const Box = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${Colors.White};
    border-radius: 10px;
    box-shadow: 0px 3px 6px ${Colors.ShadowColor};
    padding: 25px;
`