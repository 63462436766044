import {
  BAN_USER,
  BAN_USER_FAILURE,
  BAN_USER_SUCCESS,
  DELETE_COMMENT,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_SUCCESS,
  DELETE_POLL,
  DELETE_POLL_FAILURE,
  DELETE_POLL_SUCCESS,
  DELETE_POST,
  DELETE_POST_FAILURE,
  DELETE_POST_SUCCESS,
  GET_ALL_COMMENTS,
  GET_ALL_COMMENTS_FAILURE,
  GET_ALL_COMMENTS_SUCCESS,
  GET_ALL_POSTS,
  GET_ALL_POSTS_FAILURE,
  GET_ALL_POSTS_SUCCESS,
  GET_ALL_PROFILES,
  GET_ALL_PROFILES_FAILURE,
  GET_ALL_PROFILES_SUCCESS,
  IGNORE_COMMENT_REPORT,
  IGNORE_COMMENT_REPORT_FAILURE,
  IGNORE_COMMENT_REPORT_SUCCESS,
  IGNORE_POST_REPORT,
  IGNORE_POST_REPORT_FAILURE,
  IGNORE_POST_REPORT_SUCCESS,
  IGNORE_PROFILE_REPORT,
  IGNORE_PROFILE_REPORT_FAILURE,
  IGNORE_PROFILE_REPORT_SUCCESS,
  SUSPEND_USER,
  SUSPEND_USER_FAILURE,
  SUSPEND_USER_SUCCESS,
} from "../constants";

export default class ReportsAction {
  static getAllProfiles(payload, cb) {
    return {
      type: GET_ALL_PROFILES,
      payload,
      cb,
    };
  }
  static getAllProfilesSuccess(payload) {
    return {
      type: GET_ALL_PROFILES_SUCCESS,
      payload,
    };
  }
  static getAllProfilesFailure(payload) {
    return {
      type: GET_ALL_PROFILES_FAILURE,
      payload,
    };
  }
  static ignoreProfileReport(id, cb) {
    return {
      type: IGNORE_PROFILE_REPORT,
      id,
      cb,
    };
  }
  static ignoreProfileReportSuccess(payload) {
    return {
      type: IGNORE_PROFILE_REPORT_SUCCESS,
      payload,
    };
  }
  static ignoreProfileReportFailure(payload) {
    return {
      type: IGNORE_PROFILE_REPORT_FAILURE,
      payload,
    };
  }

  static getAllComments(payload, cb) {
    return {
      type: GET_ALL_COMMENTS,
      payload,
      cb,
    };
  }
  static getAllCommentsSuccess(payload) {
    return {
      type: GET_ALL_COMMENTS_SUCCESS,
      payload,
    };
  }
  static getAllCommentsFailure(payload) {
    return {
      type: GET_ALL_COMMENTS_FAILURE,
      payload,
    };
  }
  static ignoreCommentReport(id, cb) {
    return {
      type: IGNORE_COMMENT_REPORT,
      id,
      cb,
    };
  }
  static ignoreCommentReportSuccess(payload) {
    return {
      type: IGNORE_COMMENT_REPORT_SUCCESS,
      payload,
    };
  }
  static ignoreCommentReportFailure(payload) {
    return {
      type: IGNORE_COMMENT_REPORT_FAILURE,
      payload,
    };
  }
  static deleteComment(id, cb) {
    return {
      type: DELETE_COMMENT,
      id,
      cb,
    };
  }
  static deleteCommentSuccess(payload) {
    return {
      type: DELETE_COMMENT_SUCCESS,
      payload,
    };
  }
  static deleteCommentFailure(payload) {
    return {
      type: DELETE_COMMENT_FAILURE,
      payload,
    };
  }

  static getAllPosts(payload, cb) {
    return {
      type: GET_ALL_POSTS,
      payload,
      cb,
    };
  }
  static getAllPostsSuccess(payload) {
    return {
      type: GET_ALL_POSTS_SUCCESS,
      payload,
    };
  }
  static getAllPostsFailure(payload) {
    return {
      type: GET_ALL_POSTS_FAILURE,
      payload,
    };
  }
  static ignorePostReport(id, cb) {
    return {
      type: IGNORE_POST_REPORT,
      id,
      cb,
    };
  }
  static ignorePostReportSuccess(payload) {
    return {
      type: IGNORE_POST_REPORT_SUCCESS,
      payload,
    };
  }
  static ignorePostReportFailure(payload) {
    return {
      type: IGNORE_POST_REPORT_FAILURE,
      payload,
    };
  }
  static deletePost(id, cb) {
    return {
      type: DELETE_POST,
      id,
      cb,
    };
  }
  static deletePostSuccess(payload) {
    return {
      type: DELETE_POST_SUCCESS,
      payload,
    };
  }
  static deletePostFailure(payload) {
    return {
      type: DELETE_POST_FAILURE,
      payload,
    };
  }
  static deletePoll(id, cb) {
    return {
      type: DELETE_POLL,
      id,
      cb,
    };
  }
  static deletePollSuccess(payload) {
    return {
      type: DELETE_POLL_SUCCESS,
      payload,
    };
  }
  static deletePollFailure(payload) {
    return {
      type: DELETE_POLL_FAILURE,
      payload,
    };
  }

  static banUser(id, cb) {
    return {
      type: BAN_USER,
      id,
      cb,
    };
  }
  static banUserSuccess(payload) {
    return {
      type: BAN_USER_SUCCESS,
      payload,
    };
  }
  static banUserFailure(payload) {
    return {
      type: BAN_USER_FAILURE,
      payload,
    };
  }
  static suspendUser(id, cb) {
    return {
      type: SUSPEND_USER,
      id,
      cb,
    };
  }
  static suspendUserSuccess(payload) {
    return {
      type: SUSPEND_USER_SUCCESS,
      payload,
    };
  }
  static suspendUserFailure(payload) {
    return {
      type: SUSPEND_USER_FAILURE,
      payload,
    };
  }
}
