import {
  BAN_USER,
  BAN_USER_FAILURE,
  BAN_USER_SUCCESS,
  DELETE_COMMENT,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_SUCCESS,
  DELETE_POLL,
  DELETE_POLL_FAILURE,
  DELETE_POLL_SUCCESS,
  DELETE_POST,
  DELETE_POST_FAILURE,
  DELETE_POST_SUCCESS,
  GET_ALL_COMMENTS,
  GET_ALL_COMMENTS_FAILURE,
  GET_ALL_COMMENTS_SUCCESS,
  GET_ALL_POSTS,
  GET_ALL_POSTS_FAILURE,
  GET_ALL_POSTS_SUCCESS,
  GET_ALL_PROFILES,
  GET_ALL_PROFILES_FAILURE,
  GET_ALL_PROFILES_SUCCESS,
  IGNORE_COMMENT_REPORT,
  IGNORE_COMMENT_REPORT_FAILURE,
  IGNORE_COMMENT_REPORT_SUCCESS,
  IGNORE_POST_REPORT,
  IGNORE_POST_REPORT_FAILURE,
  IGNORE_POST_REPORT_SUCCESS,
  IGNORE_PROFILE_REPORT,
  IGNORE_PROFILE_REPORT_FAILURE,
  IGNORE_PROFILE_REPORT_SUCCESS,
  SUSPEND_USER,
  SUSPEND_USER_FAILURE,
  SUSPEND_USER_SUCCESS,
} from "../constants";

const initialState = {
  count: 0,
  profiles: [],
  comments: [],
  posts: [],
  error: null,
  isLoading: false,
};

export default function ReportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PROFILES:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_PROFILES_SUCCESS:
      state = {
        ...state,
        profiles: action?.payload?.data?.reports,
        count: action?.payload?.data?.count,
        isLoading: false,
      };
      break;
    case GET_ALL_PROFILES_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case IGNORE_PROFILE_REPORT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case IGNORE_PROFILE_REPORT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case IGNORE_PROFILE_REPORT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;

    case GET_ALL_COMMENTS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_COMMENTS_SUCCESS:
      state = {
        ...state,
        comments: action?.payload?.data?.reports,
        count: action?.payload?.data?.count,
        isLoading: false,
      };
      break;
    case GET_ALL_COMMENTS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case IGNORE_COMMENT_REPORT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case IGNORE_COMMENT_REPORT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case IGNORE_COMMENT_REPORT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case DELETE_COMMENT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case DELETE_COMMENT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case DELETE_COMMENT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;

    case GET_ALL_POSTS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_POSTS_SUCCESS:
      state = {
        ...state,
        posts: action?.payload?.data?.reports,
        count: action?.payload?.data?.count,
        isLoading: false,
      };
      break;
    case GET_ALL_POSTS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case IGNORE_POST_REPORT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case IGNORE_POST_REPORT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case IGNORE_POST_REPORT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case DELETE_POST:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case DELETE_POST_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case DELETE_POST_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case DELETE_POLL:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case DELETE_POLL_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case DELETE_POLL_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;

    case BAN_USER:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case BAN_USER_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case BAN_USER_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case SUSPEND_USER:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case SUSPEND_USER_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case SUSPEND_USER_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
