import { AiOutlineUser } from "react-icons/ai";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useState } from "react";
import useMedia from "use-media";

import { AuthAction } from "../../store/actions";
import { Button, ContainerBox, Text, TextField } from "../../components";
import { Colors, Fonts, Images, Utils } from "../../config";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    emailErrMsg: "",
    iconClick: false,
    validEmail: true,
  });

  const isMobile = useMedia({ minWidth: "450px" });

  const forgotPassword = () => {
    const { email, validEmail } = state;
    if (!email) {
      setState({
        ...state,
        emailErrMsg: "Email can not be left empty.",
        validEmail: false,
      });
    } else if (!validEmail && email) {
      setState({
        ...state,
        emailErrMsg: "Please enter a valid email address.",
      });
    } else {
      const body = {
        email: state.email,
      };
      dispatch(
        AuthAction.forgotPassword(body, (response) => {
          navigate("/verify-code", {
            state: {
              email: state.email,
            },
          });
        })
      );
    }
  };

  const validateEmail = (email) => {
    let validEmail = Utils.emailRegex.test(String(email).toLowerCase());
    setState({ ...state, email, validEmail, emailErrMsg: "" });
  };

  return (
    <ContainerBox>
      <Row justify="center" style={{ width: "100%" }}>
        <Col
          xl={8}
          lg={9}
          md={12}
          sm={14}
          xs={20}
          style={{
            backgroundColor: Colors.White,
            borderRadius: "10px",
          }}
        >
          <Row
            justify="center"
            gutter={[0, 24]}
            style={{ width: "100%", marginBlock: "35px" }}
          >
            <Col>
              <img src={Images.Logo} alt="logo"></img>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Text.Heading
                fontSize={isMobile ? 30 : 24}
                fontFamily={Fonts["Poppins-Bold"]}
                color={Colors.Black}
                fontWeight={700}
                text={"Forgot Password?"}
              />
              <Text.Heading
                fontSize={isMobile ? 15 : 13}
                fontFamily={Fonts["Poppins-Regular"]}
                color={Colors.LightTextColor}
                fontWeight={400}
                textALign={"center"}
                text={"Enter your email to reset your password"}
              />
            </Col>
            <Col
              span={18}
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField.CustomInput
                label="Email Address"
                size="large"
                color={Colors.Black}
                type={"email"}
                defaultValue={state.email}
                placeholder={"hello@mobrage.com"}
                suffix={<AiOutlineUser />}
                style={{
                  border: "1px solid #F5F5F5",
                  boxShadow: "0px 3px 6px #0000001A",
                  borderRadius: 10,
                }}
                onChange={(e) => validateEmail(e.target.value)}
              />
              <Text.ErrorText text={state.emailErrMsg} />
            </Col>

            <Col span={18}>
              <Button.Basic
                variant="outlined"
                style={{
                  backgroundColor: Colors.Primary,
                  textTransform: "capitalize",
                  color: Colors.White,
                  width: "100%",
                  borderRadius: 8,
                  borderColor: Colors.Transparent,
                  fontFamily: `${Fonts["Poppins-Regular"]}`,
                }}
                text="Continue"
                onClick={forgotPassword}
                containerStyle={{ margin: "10px 0" }}
                size="large"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerBox>
  );
};

export default ForgotPassword;
