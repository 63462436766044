import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import useMedia from "use-media";
import Action1 from '../../assets/images/Action1.png';
import Action2 from '../../assets/images/Action2.png';
import Action3 from '../../assets/images/Action3.png';
import Action4 from '../../assets/images/Action4.png';
import Signups1 from '../../assets/images/Signups1.png';
import Signups2 from '../../assets/images/Signups2.png';
import Signups3 from '../../assets/images/Signups3.png';
import Signups4 from '../../assets/images/Signups4.png';
import Signups5 from '../../assets/images/Signups5.png';
import DAccounts1 from '../../assets/images/DAccounts1.png';
import DAccounts2 from '../../assets/images/DAccounts2.png';
import SProvider1 from '../../assets/images/SProvider1.png';
import SProvider2 from '../../assets/images/SProvider2.png';
import SProvider3 from '../../assets/images/SProvider3.png';
import SProvider4 from '../../assets/images/SProvider4.png';
import SProvider5 from '../../assets/images/SProvider5.png';
import Jobs1 from '../../assets/images/Jobs1.png';
import Jobs2 from '../../assets/images/Jobs2.png';
import Jobs3 from '../../assets/images/Jobs3.png';
import Jobs4 from '../../assets/images/Jobs4.png';
import Ads1 from '../../assets/images/Ads1.png';
import Ads2 from '../../assets/images/Ads2.png';
import Ads3 from '../../assets/images/Ads3.png';
import Ads4 from '../../assets/images/Ads4.png';
import Services1 from '../../assets/images/Services1.png';
import Services2 from '../../assets/images/Services2.png';
import Services3 from '../../assets/images/Services3.png';
import Services4 from '../../assets/images/Services4.png';
import Services5 from '../../assets/images/Services5.png';
import Services6 from '../../assets/images/Services6.png';
import Services7 from '../../assets/images/Services7.png';
import Services8 from '../../assets/images/Services8.png';
import Services9 from '../../assets/images/Services9.png';
import Services10 from '../../assets/images/Services10.png';
import Services11 from '../../assets/images/Services11.png';
import Services12 from '../../assets/images/Services12.png';
import PostsReports1 from '../../assets/images/Post&Report1.png';
import PostsReports2 from '../../assets/images/Post&Report2.png';
import { Charts, Picker, Text, TextField } from "../../components";
import Texts from "../../components/Texts";
import { Colors, Fonts } from "../../config";
import { DashboardAction } from "../../store/actions";

import "./index.css";
import { colors } from "@mui/material";

const Dashboard = () => {
  const dispatch = useDispatch();
  const isTablet = useMedia({ minWidth: "992px" });

  const [serviceFilter, setServiceFilter] = useState("");
  const [servicesData, setServicesData] = useState([]);
  const [userStatsFilter, setuserStatsFilter] = useState("");
  const [userStatsData, setUserStatsData] = useState([]);
  const [serviceUsersFilter, setServiceUsersFilter] = useState("");
  const [serviceUsersData, setserviceUsersData] = useState([]);

  const [postCreatedFilter, setPostCreatedFilter] = useState({
    startDate: moment().subtract(11, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [serviceUserFilter, setServiceUserFilter] = useState({
    startDate: moment().subtract(11, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [noOfServicesFilter, setNoOfServicesFilter] = useState({
    startDate: moment().subtract(11, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [servicesRequestedFilter, setServicesRequestedFilter] = useState({
    startDate: moment().subtract(11, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [signupFilter, setSignupFilter] = useState({
    startDate: moment().subtract(11, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [subscriptionsFilter, setSubscriptionsFilter] = useState({
    startDate: moment().subtract(11, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const handleServiceRequestFilter = (e) => {
    setServiceFilter(e);
    let filterData = [];
    if (e === "") {
      filterData = noOfServices;
    } else {
      noOfServices?.forEach((i) => {
        filterData.push({
          name: i.name,
          [e]:
            e === "accepted"
              ? i.accepted
              : e === "rejected"
                ? i.rejected
                : e === "pending"
                  ? i.pending
                  : e === "completed"
                    ? i.completed
                    : i.total,
        });
      });
    }
    setServicesData(filterData);
  };

  const handleUserStatsFilter = (e) => {
    setuserStatsFilter(e);
    let filterData = [];
    if (e === "") {
      filterData = userStats;
    } else {
      userStats?.forEach((i) => {
        filterData.push({
          name: i.name,
          [e]: e === "Service " ? i.ios : e === "android" ? i.android : i.total,
        });
      });
    }
    setUserStatsData(filterData);
  };

  const handleServiceUsersFilter = (e) => {
    setServiceUsersFilter(e);
    let filterData = [];
    if (e === "") {
      filterData = serviceUserStats;
    } else {
      serviceUserStats?.forEach((i) => {
        filterData.push({
          name: i.name,
          [e]:
            e === "Service Provider"
              ? i["Service Provider"]
              : e === "Service User"
                ? i["Service User"]
                : i.total,
        });
      });
    }
    setserviceUsersData(filterData);
  };

  const handleFilterChange = (date, type) => {
    if (date && type === "user stats") {
      setSignupFilter({
        startDate: moment(date[0]).startOf("month")?.format("YYYY-MM-DD"),
        endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
      });
      dispatch(
        DashboardAction.getUserStats({
          startDate: moment(date[0]).startOf("month")?.format("YYYY-MM-DD"),
          endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
        })
      );
    } else if (date && type === "services requested") {
      setServicesRequestedFilter({
        startDate: moment(date[0]).startOf("month")?.format("YYYY-MM-DD"),
        endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
      });
      dispatch(
        DashboardAction.serviceRequested({
          startDate: moment(date[0]).startOf("month")?.format("YYYY-MM-DD"),
          endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
        })
      );
    } else if (date && type === "services data") {
      setNoOfServicesFilter({
        startDate: moment(date?.[0] || new Date())
          .startOf("month")
          ?.format("YYYY-MM-DD"),
        endDate: moment(date?.[1] || new Date())
          .endOf("month")
          ?.format("YYYY-MM-DD"),
      });
      dispatch(
        DashboardAction.getNoOfService({
          startDate: moment(date?.[0] || new Date())
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: moment(date?.[1] || new Date())
            .endOf("month")
            ?.format("YYYY-MM-DD"),
        })
      );
    } else if (date && type === "service user stats") {
      setServiceUserFilter({
        startDate: moment(date[0]).startOf("month")?.format("YYYY-MM-DD"),
        endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
      });
      dispatch(
        DashboardAction.getServiceUserStats({
          startDate: moment(date[0]).startOf("month")?.format("YYYY-MM-DD"),
          endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
        })
      );
    } else if (date && type === "post stats") {
      setPostCreatedFilter({
        startDate: moment(date[0]).startOf("month")?.format("YYYY-MM-DD"),
        endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
      });
      dispatch(
        DashboardAction.getPostCreatedStats({
          startDate: moment(date[0]).startOf("month").format("YYYY-MM-DD"),
          endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
        })
      );
    } else if (date && type === "subscription stats") {
      setSubscriptionsFilter({
        startDate: moment(date[0]).startOf("month")?.format("YYYY-MM-DD"),
        endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
      });
      dispatch(
        DashboardAction.getSubscription({
          startDate: moment(date[0]).startOf("month")?.format("YYYY-MM-DD"),
          endDate: moment(date[1]).endOf("month")?.format("YYYY-MM-DD"),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(DashboardAction.getUserStats(signupFilter));
    dispatch(DashboardAction.getPostCreatedStats(postCreatedFilter));
    dispatch(DashboardAction.getServiceUserStats(serviceUserFilter));
    dispatch(DashboardAction.getNoOfService(noOfServicesFilter));
    dispatch(DashboardAction.serviceRequested(servicesRequestedFilter));
    dispatch(DashboardAction.getSubscription(subscriptionsFilter));
    dispatch(DashboardAction.getActionReq());
    dispatch(DashboardAction.getSignups());
    dispatch(DashboardAction.getDeletedAcc());
    dispatch(DashboardAction.getServiceProviders());
    dispatch(DashboardAction.getJobs());
    dispatch(DashboardAction.getAds());
    dispatch(DashboardAction.getServices());
    dispatch(DashboardAction.getPostAndReports());
  }, []);

  const {
    userStats,
    postStats,
    serviceUserStats,
    noOfServices,
    servicesRequested,
    subscriptionStats,
    action,
    signups,
    deletedAcc,
    servicePro,
    jobs,
    ads,
    service,
    postAndReports
  } = useSelector((store) => store?.dashboard);



  const actionData = action?.data?.data;
  const signupsData = signups?.data?.data;
  const deletedAccData = deletedAcc?.data?.data;
  const serviceProData = servicePro?.data?.data;
  const jobsData = jobs?.data?.data;
  const adsData = ads?.data?.data;
  const serviceData = service?.data?.data;
  const postAndReportsData = postAndReports?.data?.data;


  console.log("This is the action req of the dashboard", postAndReports)

  useEffect(() => {
    let filterData = [];
    if (serviceFilter === "") {
      filterData = noOfServices;
    } else {
      noOfServices?.forEach((i) => {
        filterData.push({
          name: i.name,
          [serviceFilter]:
            serviceFilter === "accepted"
              ? i.accepted
              : serviceFilter === "rejected"
                ? i.rejected
                : serviceFilter === "pending"
                  ? i.pending
                  : serviceFilter === "completed"
                    ? i.completed
                    : i.total,
        });
      });
    }
    setServicesData(filterData);
  }, [noOfServices]);

  useEffect(() => {
    let filterData = [];
    if (userStatsFilter === "") {
      filterData = userStats;
    } else {
      userStats?.forEach((i) => {
        filterData.push({
          name: i.name,
          [userStatsFilter]:
            userStatsFilter === "ios"
              ? i.ios
              : userStatsFilter === "android"
                ? i.android
                : i.total,
        });
      });
    }
    setUserStatsData(filterData);
  }, [userStats]);

  useEffect(() => {
    let filterData = [];
    if (serviceUsersFilter === "") {
      filterData = serviceUserStats;
    } else {
      serviceUserStats?.forEach((i) => {
        filterData.push({
          name: i.name,
          [serviceUsersFilter]:
            serviceUsersFilter === "Service Provider"
              ? i["Service Provider"]
              : serviceUsersFilter === "Service User"
                ? i["Service User"]
                : i.total,
        });
      });
    }
    setserviceUsersData(filterData);
  }, [serviceUserStats]);

  return (
    <>

      <Row style={{ justifyContent: "space-between" }}
        justify="space-between"
        className="row-div mb-10" >
        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '15px' }}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Action Required"}
          /></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className='py-2 mb-8 px-2' >

          <Row className="flex-row-align-start-not-space mx-20"
            gutter={[20, 0]}>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Action1} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={actionData?.pendingDocuments ? actionData?.pendingDocuments : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Docs Pending</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Action2} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={actionData?.pendingBookings ? actionData?.pendingBookings : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Jobs Pending</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Action3} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={actionData?.pendingReports ? actionData?.pendingReports : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Reports</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Action4} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={actionData?.pendingAdvertisement ? actionData?.pendingAdvertisement : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Ads</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ justifyContent: "space-between" }}
        justify="space-between"
        className="row-div mb-10" >
        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '15px' }}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Signups"}
          /></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className='py-2 mb-8 px-2' >

          <Row className="flex-row-align-start-not-space mx-20"
            gutter={[20, 0]}>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Signups1} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={signupsData?.serviceUsers ? signupsData?.serviceUsers : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Service Users</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Signups2} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={signupsData?.serviceProviders ? signupsData?.serviceProviders : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Service Providers</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Signups3} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={signupsData?.signUpViaApple ? signupsData?.signUpViaApple : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Apple</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Signups5} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={signupsData?.signUpViaGoogle ? signupsData?.signUpViaGoogle : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Google</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Signups4} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={signupsData?.totalUsers ? signupsData?.totalUsers : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Total Users</p></Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="mt-20 mx-0 mb-10"
        gutter={[30, 0]}
      >
        <Col xs={24} xxl={12} xl={12} lg={12} md={24} sm={24}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Apple - Google Signups"}
          />
          <Row className="flex-column chart-div-grid">
            <Col className="flex-end">
              <div>
                <TextField.SelectField
                  placeholder="Select"
                  size="large"
                  id="provider-management-select"
                  type="text"
                  className="mb-10"
                  disabled={false}
                  required={false}
                  value={userStatsFilter}
                  label=""
                  defaultValue="All"
                  error={false}
                  helperText=""
                  onChange={handleUserStatsFilter}
                  // className="mb-8"
                  data={[
                    {
                      label: "All",
                      value: "",
                    },
                    {
                      label: "IOS",
                      value: "ios",
                    },
                    {
                      label: "Android",
                      value: "android",
                    },
                  ]}
                />
                <Picker.AntdDatePicker
                  value={[
                    moment(signupFilter.startDate).startOf("month"),
                    moment(signupFilter.endDate).endOf("month"),
                  ]}
                  month
                  disabledDate={(current) => {
                    var startDate = moment().subtract(12, "month");
                    var endDate = moment();
                    return current < startDate || current > endDate;
                  }}
                  format={"DD-MM-YYYY"}
                  allowClear={false}
                  handleChange={(date) =>
                    handleFilterChange(date, "user stats")
                  }
                  containerStyle={{ margin: 5 }}
                />
              </div>
            </Col>
            <Col>
              <Charts.ResponsiveContainer
                style={{ margin: "27px 0px" }}
                height={358}
              >
                {userStatsData
                  .map((val) => {
                    return !(val.ios || val.android)
                      ? 0
                      : val.ios || val.android;
                  })
                  .reduce((total, value) => total + value, 0) > 0 ? (
                  <Charts.LineChart
                    data={userStatsData}
                    lineColor={{
                      android: "#FE6779",
                      ios: "#5596F8",
                    }} // loading={this.state.appDownloadsLoading}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                    legendsAlignment={"bottom"}
                    isLegend={true}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    No Data Available for this month range!
                  </h4>
                )}
              </Charts.ResponsiveContainer>
            </Col>
          </Row>
        </Col>
        <Col xs={24} xxl={12} xl={12} lg={12} md={24} sm={24}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Service users and Service providers"}
          />
          <Row className="flex-column chart-div-grid">
            <Col className="flex-end">
              <div>

                <TextField.SelectField
                  placeholder="Select"
                  size="large"
                  id="provider-management-select"
                  type="text"
                  disabled={false}
                  required={false}
                  value={serviceUsersFilter}
                  label=""
                  defaultValue="All"
                  error={false}
                  helperText=""
                  onChange={handleServiceUsersFilter}
                  className="mb-10"
                  data={[
                    {
                      label: "All",
                      value: "",
                    },
                    {
                      label: "Service Users",
                      value: "Service User",
                    },
                    {
                      label: "Service Providers",
                      value: "Service Provider",
                    },
                  ]}
                />


                <Picker.AntdDatePicker
                  value={[
                    moment(serviceUserFilter.startDate).startOf("month"),
                    moment(serviceUserFilter.endDate).endOf("month"),
                  ]}
                  disabledDate={(current) => {
                    var startDate = moment().subtract(12, "month");
                    var endDate = moment();
                    return current < startDate || current > endDate;
                  }}
                  month
                  format={"DD-MM-YYYY"}
                  allowClear={false}
                  handleChange={(date) =>
                    handleFilterChange(date, "service user stats")
                  }
                  containerStyle={{ margin: 5 }}
                />
              </div>

            </Col>
            <Col>
              <Charts.ResponsiveContainer
                style={{ margin: "27px 0px" }}
                height={350}
              >
                {serviceUsersData
                  .map((val) => {
                    return !(val["Service User"] || val["Service Provider"])
                      ? 0
                      : val["Service User"] || val["Service Provider"];
                  })
                  .reduce((total, value) => total + value, 0) > 0 ? (
                  <Charts.LineChart
                    data={serviceUsersData}
                    lineColor={{
                      "Service User": "#FE6779",
                      "Service Provider": "#55BAF9",
                    }} // loading={this.state.appDownloadsLoading}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                    legendsAlignment={"bottom"}
                    isLegend={false}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    No Data Available for this month range!
                  </h4>
                )}
              </Charts.ResponsiveContainer>
            </Col>
          </Row>
        </Col>

      </Row>
      <Row style={{ justifyContent: "space-between" }}
        justify="space-between"
        className="row-div mb-10 mt-20" >
        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '15px' }}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Deleted Accounts"}
          /></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className='py-2 mb-8 px-2' >

          <Row className="flex-row-align-start-not-space mx-20" gutter={[20, 0]}>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={DAccounts1} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={deletedAccData?.serviceUsers ? deletedAccData?.serviceUsers : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Service Users</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={DAccounts2} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={deletedAccData?.serviceProviders ? deletedAccData?.serviceProviders : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Service Providers</p></Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }}
        justify="space-between"
        className="row-div mb-10 mt-20" >
        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '15px' }}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Service Providers"}
          /></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className='py-2 mb-8 px-2' >

          <Row className="flex-row-align-start-not-space mx-20" gutter={[20, 0]}>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={SProvider1} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={serviceProData?.pendingDocuments ? serviceProData?.pendingDocuments : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Pending Docs</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={SProvider2} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={serviceProData?.documentsNotSubmitted ? serviceProData?.documentsNotSubmitted : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Docs Not Submitted</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={SProvider3} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={serviceProData?.profileNotCompleted ? serviceProData?.profileNotCompleted : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Profile Not Completed</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={SProvider4} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={serviceProData?.profileSwitched ? serviceProData?.profileSwitched : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Profile Switched</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            {/* <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={SProvider5} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>SUs to SPs</p></Col>
              </Row>
            </Col> */}
          </Row>
        </Col>
      </Row>


      <Row style={{ justifyContent: "space-between" }}
        justify="space-between"
        className="row-div mb-10 mt-20" >
        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '15px' }}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Jobs"}
          /></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className='py-2 mb-8 px-2' >

          <Row className="flex-row-align-start-not-space mx-20"
            gutter={[20, 0]}>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Jobs1} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={jobsData?.pending ? jobsData?.pending : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Pending</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Jobs2} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={jobsData?.accepted ? jobsData?.accepted : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Accepted</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Jobs3} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={jobsData?.completed ? jobsData?.completed : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Completed</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Jobs4} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={jobsData?.rejected ? jobsData?.rejected : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Rejected</p></Col>
              </Row>
            </Col>

          </Row>
        </Col>
      </Row>
      <Row justify="space-between" className="text-row-div">
        <Col>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Number of services requested"}
          />
        </Col>
        <Col xs={16} xl={9} lg={11} md={12} sm={14}>
          <Row justify="space-between">
            <Col>
              <TextField.SelectField
                placeholder="Select"
                size="large"
                id="provider-management-select"
                type="text"
                disabled={false}
                required={false}
                value={serviceFilter}
                label=""
                defaultValue="All"
                error={false}
                helperText=""
                onChange={handleServiceRequestFilter}
                data={[
                  {
                    label: "All",
                    value: "",
                  },
                  {
                    label: "Accepted",
                    value: "accepted",
                  },
                  {
                    label: "Rejected",
                    value: "rejected",
                  },
                  {
                    label: "Pending",
                    value: "pending",
                  },
                  {
                    label: "Completed",
                    value: "completed",
                  },
                  {
                    label: "Total",
                    value: "total",
                  },
                ]}
              />
            </Col>
            <Col>
              <Picker.AntdDatePicker
                value={[
                  moment(noOfServicesFilter.startDate).startOf("month"),
                  moment(noOfServicesFilter.endDate).endOf("month"),
                ]}
                disabledDate={(current) => {
                  var startDate = moment().subtract(12, "month");
                  var endDate = moment();
                  return current < startDate || current > endDate;
                }}
                month
                format={"DD-MM-YYYY"}
                allowClear={false}
                handleChange={(date) =>
                  handleFilterChange(date, "services data")
                }
                containerStyle={{ margin: 5 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="row-div">
        <Col xs={24} lg={24} md={24} sm={24}>
          <Charts.ResponsiveContainer height={350}>
            {servicesData
              .map((val) => {
                return !(
                  val.accepted ||
                  val.completed ||
                  val.total ||
                  val.rejected ||
                  val.pending
                )
                  ? 0
                  : val.accepted ||
                  val.completed ||
                  val.total ||
                  val.rejected ||
                  val.pending;
              })
              .reduce((total, value) => total + value, 0) > 0 ? (
              <Charts.LineChart
                data={servicesData}
                lineColor={{
                  accepted: "#7700FF",
                  completed: "#55BAF9",
                  pending: "#FAB700",
                  rejected: "#FF4C57",
                  total: "#2A2A2A",
                }}
                // loading={this.state.appDownloadsLoading}
                margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                legendsAlignment={"top"}
                isLegend={true}
              />
            ) : (
              <h4
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                No Data Available for this month range!
              </h4>
            )}
          </Charts.ResponsiveContainer>
        </Col>
      </Row>

      <Row style={{ justifyContent: "space-between" }}
        justify="space-between"
        className="row-div mb-10 mt-20" >
        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '15px' }}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Ads"}
          /></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className='py-2 mb-8 px-2' >

          <Row className="flex-row-align-start-not-space mx-20"
            gutter={[20, 0]}>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Ads1} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={adsData?.active ? adsData?.active : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Live</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Ads2} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={adsData?.pending ? adsData?.pending : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Pending</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Ads3} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={adsData?.inActive ? adsData?.inActive : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Expired</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={Ads4} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={adsData?.rejected ? adsData?.rejected : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Rejected</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
              <Row>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={SProvider5} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={adsData?.freeze ? adsData?.freeze : 0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Freeze</p></Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between" }}
        justify="space-between"
        className="row-div mb-10 mt-20" >
        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '15px' }}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Services"}
          /></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className='py-2 mb-8 px-2' >

          <Row className="flex-row-align-start-not-space mx-20" gutter={[20, 0]}>
            {serviceData?.map(mainServices => (
              <>
                <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
                  <Row>
                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                      <Row>
                        <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                        <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={mainServices?.imageUrl ? mainServices?.imageUrl : Services1} height={'90%'} width={'60%'} /></Col>
                      </Row>
                    </Col>
                    <Col xl={16} lg={16} md={16} sm={16} xs={16}>
                      <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={mainServices?.providersCount ? mainServices?.providersCount : 0} />
                      <p style={{ fontSize: '0.7rem', color: Colors.Black }}>{mainServices.name}</p></Col>
                  </Row>
                </Col>
                <Col xl={1} lg={1}></Col></>
            ))}

          </Row>
        </Col>
      </Row>

      <Row
        justify="space-between"
        className="mt-20 mx-0 mb-10"
        gutter={[30, 0]}
      >
        <Col xs={24} xxl={12} xl={12} lg={12} md={24} sm={24}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Services Requested"}
          />
          <Row className="flex-column chart-div-grid">
            <Col className="flex-end">

              <Picker.AntdDatePicker
                value={[
                  moment(servicesRequestedFilter.startDate).startOf("month"),
                  moment(servicesRequestedFilter.endDate).endOf("month"),
                ]}
                disabledDate={(current) => {
                  var startDate = moment().subtract(12, "month");
                  var endDate = moment();
                  return current < startDate || current > endDate;
                }}
                format={"DD-MM-YYYY"}
                allowClear={false}
                month
                handleChange={(date) =>
                  handleFilterChange(date, "services requested")
                }
                containerStyle={{ margin: 5 }}
              />
            </Col>
            <Col>
              <Row style={{ marginTop: '-2rem' }}>
                <Charts.ResponsiveContainer>
                  {servicesRequested
                    .map((val) => val.noOfServicesRequested)
                    .reduce((total, value) => total + value, 0) > 0 ? (
                    <div className="pie-chart-div">
                      <Charts.PieChartAngle
                        data={servicesRequested}
                        lineColor={["#7700FF", "#FAB700", "#39B0F9", "#FF4A55"]}
                      // loading={this.state.appDownloadsLoading}
                      // margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                      />
                    </div>
                  ) : (
                    <h4
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      No Data Available for this month range!
                    </h4>
                  )}
                </Charts.ResponsiveContainer>
              </Row>
            </Col>
          </Row>
        </Col>

      </Row>

      <Row style={{ justifyContent: "space-between" }}
        justify="space-between"
        className="row-div mb-10 mt-20" >
        <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '15px' }}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Posts And Reports"}
          /></Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className='py-2 mb-8 px-2' >

          <Row className="flex-row-align-start-not-space mx-20" gutter={[20, 0]}>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }} >
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={PostsReports1} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={postAndReportsData?.posts ? postAndReportsData?.posts :  0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Posts</p></Col>
              </Row>
            </Col>
            <Col xl={1} lg={1}></Col>
            <Col xl={5} lg={5} md={6} sm={8} xs={24} style={{ background: '#f9fafc', border: '1px solid #fab700', borderRadius: 5, marginBottom: '4px' }}>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={8}></Col>
                    <Col xl={20} lg={20} md={20} sm={20} xs={16} style={{ paddingTop: '0.6rem' }}><img src={PostsReports2} height={'90%'} width={'60%'} /></Col>
                  </Row>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Texts.Basic className='px-2 tracking-wider' style={{ fontWeight: '600', fontSize: '1.6rem', padding: '2px' }} text={postAndReportsData?.reports ? postAndReportsData?.reports :  0} />
                  <p style={{ fontSize: '0.7rem', color: Colors.Black }}>Reports</p></Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        justify="space-between"
        className="mt-20 mx-0 mb-10"
        gutter={[30, 0]}
      >
        <Col xs={24} xxl={12} xl={12} lg={12} md={24} sm={24}>
          <Text.Heading
            fontSize={19}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Posts Created"}
          />
          <Row className="flex-column chart-div-grid">
            <Col className="flex-end">
              <div>
                <Picker.AntdDatePicker
                  value={[
                    moment(postCreatedFilter.startDate).startOf("month"),
                    moment(postCreatedFilter.endDate).endOf("month"),
                  ]}
                  format={"DD-MM-YYYY"}
                  disabledDate={(current) => {
                    var startDate = moment().subtract(12, "month");
                    var endDate = moment();
                    return current < startDate || current > endDate;
                  }}
                  month
                  allowClear={false}
                  handleChange={(date) => handleFilterChange(date, "post stats")}
                  containerStyle={{ margin: 5 }}
                />
              </div>
            </Col>
            <Col>
              <Charts.ResponsiveContainer
                style={{ margin: "27px 0px" }}
                height={350}
              >
                {postStats
                  .map((val) => val.post)
                  .reduce((total, value) => total + value, 0) > 0 ? (
                  <Charts.LineChart
                    data={postStats}
                    lineColor={{
                      post: "#5596F8",
                    }}
                    // loading={this.state.appDownloadsLoading}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                    legendsAlignment={"bottom"}
                    isLegend={false}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    No Data Available for this month range!
                  </h4>
                )}
              </Charts.ResponsiveContainer>
            </Col>
          </Row>
        </Col>

      </Row>

    </>
  );
};

export default Dashboard;
