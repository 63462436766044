import {
  GET_ADS_REQUEST,
  GET_ADS_SUCCESS,
  GET_ADS_FAILURE,
  UPDATE_AD_REQUEST,
  UPDATE_AD_SUCCESS,
  UPDATE_AD_FAILURE,
  DELETE_AD_REQUEST,
  DELETE_AD_SUCCESS,
  DELETE_AD_FAILURE,
  EDIT_AD_REQUEST,
  EDIT_AD_FAILURE,
  EDIT_AD_SUCCESS,
} from "../constants";

export default class AdManagementAction {
  static getAdsRequest(params) {
    return {
      type: GET_ADS_REQUEST,
      params,
    };
  }
  static getAdsSuccess(payload, count) {
    return {
      type: GET_ADS_SUCCESS,
      payload,
      count,
    };
  }
  static getAdsFailure(payload) {
    return {
      type: GET_ADS_FAILURE,
      payload,
    };
  }

  static updateAdRequest(params, cb) {
    return {
      type: UPDATE_AD_REQUEST,
      params,
      cb,
    };
  }
  static updateAdSuccess(payload) {
    return {
      type: UPDATE_AD_SUCCESS,
      payload,
    };
  }
  static updateAdFailure(payload) {
    return {
      type: UPDATE_AD_FAILURE,
      payload,
    };
  }

  static editAdRequest(params, cb) {
    return {
      type: EDIT_AD_REQUEST,
      params,
      cb,
    };
  }
  static editAdSuccess(payload) {
    return {
      type: EDIT_AD_SUCCESS,
      payload,
    };
  }
  static editAdFailure(payload) {
    return {
      type: EDIT_AD_FAILURE,
      payload,
    };
  }

  static deleteAdRequest(params, cb) {
    return {
      type: DELETE_AD_REQUEST,
      params,
      cb,
    };
  }
  static deleteAdSuccess(payload) {
    return {
      type: DELETE_AD_SUCCESS,
      payload,
    };
  }
  static deleteAdFailure(payload) {
    return {
      type: DELETE_AD_FAILURE,
      payload,
    };
  }
}
