import { Col, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import useMedia from "use-media";

import { Colors, Fonts, Images } from "../../config";
import Modal from "../Modal";
import Text from "../CommonComponents/Text";

const CommentCard = ({
  item,
  handleBanUser,
  handleIgnoreReport,
  handleDeleteComment,
  handleSuspendUser,
}) => {
  const isTablet = useMedia({ minWidth: "968px" });
  const isMobile = useMedia({ minWidth: "426px" });
  const [showModal, setShowModal] = useState(false);
  const [values, setValues] = useState({
    profileModal: false,
    isBan: false,
    isIgnore: false,
    isComment: false,
    isSuspend: false,
  });
  const { profileModal, isBan, isIgnore, isComment, isSuspend } = values;
  const [selectedComment, setSelectedComment] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  return (
    <Row justify="start" className="report-profile">
      <Col xl={24} xll={24} xs={24} md={24} sm={24} lg={24}>
        <Row>
          <Col xl={4} lg={4} md={4} sm={4} xs={3}>
            <img
              src={item?.reporter?.imageThumbUrl}
              alt=""
              width={isMobile ? 50 : 35}
              height={isMobile ? 50 : 35}
              className="profile-report-div-reporter-img"
            />
          </Col>
          <Col
            xs={15}
            lg={15}
            md={15}
            sm={15}
            className={isMobile ? "flex-column ml-10" : "flex-column ml-15"}
          >
            <Text.Heading
              fontSize={isMobile ? 16 : 14}
              fontFamily={Fonts["Poppins-Bold"]}
              color={Colors.TextColor2}
              fontWeight={600}
              text={item?.reporter?.fullName}
              textAlign="left"
            />
            <Text.Heading
              fontSize={isMobile ? 14 : 12}
              fontFamily={Fonts["Poppins-Medium"]}
              color={Colors.LightGrayText}
              fontWeight={500}
              text={item?.reporter?.email}
              textAlign="left"
            />
            <Text.Heading
              fontSize={isMobile ? 14 : 12}
              fontFamily={Fonts["Poppins-Medium"]}
              color={Colors.LightGrayText}
              fontWeight={500}
              text={moment(item?.createdAt).format("HH:MM A | DD-MM-YYYY")}
              textAlign="left"
            />
          </Col>
          <Col xs={4} lg={4} md={4} sm={4}>
            <Text.Heading
              fontSize={isMobile ? 14 : 12}
              fontFamily={Fonts["Poppins-Medium"]}
              color={Colors.Pink}
              fontWeight={400}
              text={"Reporter"}
            />
          </Col>
        </Row>
        <Col className="flex-column mt-20">
          <Text.Heading
            fontSize={isMobile ? 13 : 12}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={500}
            text={item?.type}
            textAlign="left"
          />
          {item?.reason?.length > 35 ? (
            <div className="flex-row">
              <Text.Heading
                fontSize={isMobile ? 13 : 12}
                fontFamily={Fonts["Poppins-Medium"]}
                color={Colors.LightGrayText}
                fontWeight={500}
                text={`${item?.reason.slice(0, 35)}.. `}
                textAlign="left"
                className="three-dot"
              />
              <Text.Heading
                fontSize={isMobile ? 13 : 12}
                fontFamily={Fonts["Poppins-Medium"]}
                color={Colors.Primary}
                fontWeight={500}
                text={" More"}
                textAlign="left"
                className="cursor"
                onClick={() => {
                  setSelectedReason(item?.reason);
                  setShowModal(true);
                }}
              />
            </div>
          ) : (
            <Text.Heading
              fontSize={isMobile ? 13 : 12}
              fontFamily={Fonts["Poppins-Medium"]}
              color={Colors.LightGrayText}
              fontWeight={500}
              text={item?.reason}
              textAlign="left"
              className="three-dot"
            />
          )}
        </Col>
        <Col className="dashed-line my-20"></Col>
        <Col>
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={3}>
              <img
                src={item?.comment?.user?.imageThumbUrl}
                alt=""
                width={isMobile ? 50 : 35}
                height={isMobile ? 50 : 35}
                className="profile-report-div-reporter-img"
              />
            </Col>
            <Col
              xs={15}
              lg={15}
              md={15}
              sm={15}
              className={isMobile ? "flex-column ml-10" : "flex-column ml-15"}
            >
              <Text.Heading
                fontSize={isMobile ? 16 : 14}
                fontFamily={Fonts["Poppins-Bold"]}
                color={Colors.TextColor2}
                fontWeight={600}
                text={item?.comment?.user?.fullName}
                textAlign="left"
              />
              <Text.Heading
                fontSize={isMobile ? 14 : 12}
                fontFamily={Fonts["Poppins-Medium"]}
                color={Colors.TextColor3}
                fontWeight={500}
                text={item?.comment?.user?.email}
                textAlign="left"
              />
            </Col>
            <Col xs={4} lg={4} md={4} sm={4} className="flex-row-end">
              <Text.Heading
                fontSize={isMobile ? 14 : 12}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Primary}
                fontWeight={600}
                text={item?.comment?.user?.type.split(" ")[1]}
              />
            </Col>
          </Row>
        </Col>
        <Col className="mt-20">
          {item?.comment?.comment?.length > 100 ? (
            <>
              <Text.Heading
                fontSize={isMobile ? 13 : 12}
                fontFamily={Fonts["Poppins-Medium"]}
                color={Colors.TextColor3}
                fontWeight={500}
                text={item?.comment?.comment.slice(0, 100)}
                textAlign="left"
                className="three-dot"
              />
              <Text.Heading
                fontSize={isMobile ? 13 : 12}
                fontFamily={Fonts["Poppins-Medium"]}
                color={Colors.Primary}
                fontWeight={500}
                text={" More"}
                textAlign="left"
                className="cursor"
                onClick={() => {
                  setSelectedComment(item?.comment?.comment);
                  setShowModal(true);
                }}
              />
            </>
          ) : (
            <Text.Heading
              fontSize={isMobile ? 13 : 12}
              fontFamily={Fonts["Poppins-Medium"]}
              color={Colors.TextColor3}
              fontWeight={500}
              text={item?.comment?.comment}
              textAlign="left"
              className="three-dot"
            />
          )}
        </Col>
        <Col className="dashed-line my-20"></Col>
        <Col>
          <Row justify="center">
            <Col
              span={isMobile ? 11 : 23}
              className={
                isMobile ? "ignore-btn icon-btn mr-15" : "ignore-btn icon-btn"
              }
              onClick={() =>
                setValues({
                  ...values,
                  profileModal: true,
                  isIgnore: true,
                  isBan: false,
                  isComment: false,
                  isSuspend: false,
                })
              }
            >
              {/* onClick={() => handleIgnoreReport(item?.id)} */}
              <div>
                <img src={Images.ReportBtnIcon} className="btn-icon-img" />{" "}
                <span style={{ fontSize: isMobile ? "14px" : "12px" }}>
                  Ignore Report
                </span>
              </div>
            </Col>
            <Col
              span={isMobile ? 11 : 23}
              className={
                isMobile ? "ban-btn icon-btn" : "ban-btn icon-btn mt-10"
              }
              onClick={() =>
                setValues({
                  ...values,
                  profileModal: true,
                  isIgnore: false,
                  isBan: true,
                  isComment: false,
                  isSuspend: false,
                })
              }
            >
              {/* onClick={() =>
                  handleBanUser(
                    item?.comment?.user?.id,
                    item?.comment?.user?.status === 1 ? 2 : 1
                  )
                } */}
              <div>
                <img src={Images.UserBtnIcon} className="btn-icon-img" />{" "}
                <span style={{ fontSize: isMobile ? "14px" : "12px" }}>{`${item?.comment?.user?.status === 2 ? "Unban" : "Ban"
                  } User`}</span>
              </div>
            </Col>
          </Row>
          <Row justify="center" className={isMobile ? "mt-15" : "mt-10"}>
            <Col
              span={isMobile ? 11 : 23}
              className={
                isMobile ? "suspend-btn icon-btn mr-15" : "suspend-btn icon-btn"
              }
              onClick={() =>
                setValues({
                  ...values,
                  profileModal: true,
                  isIgnore: false,
                  isBan: false,
                  isComment: true,
                  isSuspend: false,
                })
              }
            >
              {/* onClick={() => handleDeleteComment(item?.comment?.id)} */}
              <div>
                <img src={Images.DeleteBtnIcon} className="btn-icon-img" />{" "}
                <span style={{ fontSize: isMobile ? "14px" : "12px" }}>
                  Comment
                </span>
              </div>
            </Col>
            <Col
              span={isMobile ? 11 : 23}
              className={
                isMobile
                  ? item?.comment?.user?.status === 2
                    ? "comment-btn-disabled icon-btn"
                    : "comment-btn icon-btn"
                  : item?.comment?.user?.status !== 1
                    ? "comment-btn-disabled icon-btn mt-10"
                    : "comment-btn icon-btn mt-10"
              }
              onClick={() =>
                setValues({
                  ...values,
                  profileModal: true,
                  isIgnore: false,
                  isBan: false,
                  isComment: false,
                  isSuspend: true,
                })
              }
            >
              {/* () =>
                  item?.comment?.user?.status === 1
                    ? handleSuspendUser(item?.comment?.user?.id)
                    : null
                 */}
              <div>
                <img src={Images.SuspendBtnIcon} className="btn-icon-img" />{" "}
                <span style={{ fontSize: isMobile ? "14px" : "12px" }}>
                  {item?.comment?.user?.status === 2 ? "Suspended" : "Suspend"}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Col>
      {showModal && (
        <Modal.AntDesignModal
          title=""
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => {
            setShowModal(false);
            setSelectedComment(null);
            setSelectedReason(null);
          }}
        >
          <Row justify="center">
            {selectedComment ? (
              <Col span={12}>
                <Text.Heading
                  fontSize={18}
                  fontFamily={Fonts["Poppins-SemiBold"]}
                  color={Colors.Black}
                  fontWeight={600}
                  text={"Comment Details"}
                />
              </Col>
            ) : (
              <Col>
                <Text.Heading
                  fontSize={18}
                  fontFamily={Fonts["Poppins-SemiBold"]}
                  color={Colors.Black}
                  fontWeight={600}
                  text={"Reason Details"}
                />
              </Col>
            )}
          </Row>
          <Row justify="center" className="mt-20">
            <Col span={24}>
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Medium"]}
                color={Colors.TextColor3}
                fontWeight={500}
                text={selectedComment ? selectedComment : selectedReason}
                textAlign="left"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
      <Modal.ConfirmationModal
        title={""}
        isModalVisible={profileModal}
        heading={`Are you sure you want to ${isIgnore
          ? "ignore this post?"
          : isBan
            ? "ban this user ?"
            : isComment
              ? "delete this comment?"
              : "suspend this user?"}`
        }
        handleCancel={() => setValues({ ...values, profileModal: false })}
        handleDelete={
          isIgnore
            ? () => {
              handleIgnoreReport(item?.id)
              setValues({ ...values, profileModal: false })
            }
            : isBan
              ? () => {
                handleBanUser(
                  item?.comment?.user?.id,
                  item?.comment?.user?.status === 1 ? 2 : 1
                )
                setValues({ ...values, profileModal: false })
              }
              : isComment
                ? () => {
                  handleDeleteComment(item?.comment?.id)
                  setValues({ ...values, profileModal: false })
                }
                : () => {
                  item?.comment?.user?.status === 1 && handleSuspendUser(item?.comment?.user?.id)
                  setValues({ ...values, profileModal: false })
                }
        }
      ></Modal.ConfirmationModal>
    </Row>
  );
};

export default CommentCard;
