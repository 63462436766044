import { combineReducers } from "redux";

import AuthReducer from "./AuthReducer";
import ContactSupportReducer from "./ContactSupportReducer";
import DashboardReducer from "./DashboardReducer";
import ReportsReducer from "./ReportsReducer";
import ServiceProvidersReducer from "./ServiceProvidersReducer";
import ServiceReducer from "./ServiceReducer";
import ServiceUsersReducer from "./ServiceUserReducer";
import SubAdminReducer from "./SubAdminReducer";
import AdManagementReducer from "./AdManagementReducer";
import PromoManagementReducer from "./PromoManagementReducer";

const RootReducer = combineReducers({
  auth: AuthReducer,
  serviceProviders: ServiceProvidersReducer,
  service: ServiceReducer,
  serviceUsers: ServiceUsersReducer,
  subAdmins: SubAdminReducer,
  reports: ReportsReducer,
  dashboard: DashboardReducer,
  contactSupport: ContactSupportReducer,
  adManagementReducer: AdManagementReducer,
  promoManagementReducer: PromoManagementReducer,
});

export default RootReducer;
