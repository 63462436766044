import { AiOutlineUser } from "react-icons/ai";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useState } from "react";
import useMedia from "use-media"

import { Button, ContainerBox, Text, TextField } from "../../components";
import { Colors, Fonts, Images, Utils } from "../../config";
import AuthAction from "../../store/actions/AuthAction";

const SignIn = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    password: "",
    emailErrMsg: "",
    passErrMsg: "",
    iconClick: false,
    validEmail: true,
    validPass: true,
  });

  const isMobile = useMedia({ minWidth: "450px" });

  const signin = () => {
    const { email, password, validEmail, validPass } = state;
    if (!email || !validEmail || !password || !validPass) {
      if (!email) {
        setState({
          ...state,
          emailErrMsg: "Email can not be left empty.",
          validEmail: false,
        });
      } else if (!validEmail && email) {
        setState({
          ...state,
          emailErrMsg: "Please enter a valid email address.",
        });
      } else if (!password) {
        setState({
          ...state,
          passErrMsg: "Password can not be left empty.",
          validPass: false,
        });
      } else if (!validPass) {
        setState({
          ...state,
          passErrMsg:
            "Password must contain atleast 8 char with atleast 1 lower case, 1 upper case and 1 special char",
        });
      }
    } else {
      const body = {
        email: state.email,
        password: state.password,
        platform: "web",
      };
      dispatch(
        AuthAction.signIn(body, () => {
          navigate("/dashboard");
        })
      );
    }
  };

  const validateEmail = (email) => {
    let validEmail = Utils.emailRegex.test(String(email).toLowerCase());
    setState({ ...state, email, validEmail, emailErrMsg: "" });
  };

  const validatePass = (password) => {
    // let validPass = password.length > 5;
    let validPass = Utils.passwordRegex.test(String(password));
    setState({ ...state, password, validPass, passErrMsg: "" });
  };

  return (
    <ContainerBox>
      <Row justify="center" style={{ width: "100%" }}>
        <Col
          xl={8}
          lg={9}
          md={12}
          sm={14}
          xs={21}
          style={{
            backgroundColor: Colors.White,
            borderRadius: "10px",
          }}
        >
          <Row
            justify="center"
            gutter={isMobile ? [0, 24] : [0, 18]}
            style={{ width: "100%", marginBlock: "35px" }}
          >
            <Col>
              <img src={Images.Logo} alt="logo"></img>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Text.Heading
                fontSize={isMobile ? 30 : 24}
                fontFamily={Fonts["Poppins-Bold"]}
                color={Colors.Black}
                fontWeight={700}
                textALign={"center"}
                text={"Log in to Mobrage"}
              />
              <Text.Heading
                fontSize={isMobile ? 15 : 13}
                style={{
                  marginInline: isMobile ? "0px" : "10px"
                }}
                fontFamily={Fonts["Poppins-Regular"]}
                color={Colors.LightTextColor}
                fontWeight={400}
                textALign={"center"}
                text={"Welcome back! login with your Email Address"}
              />
            </Col>
            <Col
              className="passwordField"
              span={18}
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField.CustomInput
                label="Email Address"
                size="large"
                type={"email"}
                defaultValue={state.email}
                placeholder={"hello@mobrage.com"}
                suffix={<AiOutlineUser />}
                style={{
                  border: "1px solid #F5F5F5",
                  boxShadow: "0px 3px 6px #0000001A",
                  borderRadius: 10,
                }}
                onChange={(e) => validateEmail(e.target.value)}
              />
              <Text.ErrorText text={state.emailErrMsg} />
            </Col>
            <Col
              className="passwordField"
              span={18}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField.CustomInput
                password
                label="Password"
                size="large"
                type={"password"}
                defaultValue={state.password}
                placeholder={"**********"}
                style={{
                  border: "1px solid #F5F5F5",
                  boxShadow: "0px 3px 6px #0000001A",
                  borderRadius: 10,
                }}
                onChange={(e) => validatePass(e.target.value)}
              />
              <Text.ErrorText text={state.passErrMsg} />
            </Col>
            <Col span={18}>
              <Button.Basic
                variant="outlined"
                style={{
                  backgroundColor: Colors.Primary,
                  textTransform: "capitalize",
                  color: Colors.White,
                  width: "100%",
                  borderRadius: 8,
                  borderColor: Colors.Transparent,
                  fontFamily: `${Fonts["Poppins-Regular"]}`,
                }}
                text="Sign In"
                onClick={signin}
                containerStyle={{ margin: "10px 0" }}
                size="large"
              />
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "auto",
                }}
                onClick={() => navigate("/forgot-password")}
              >
                <Text.SubHeading
                  text={"Forgot Password?"}
                  style={{
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                />
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  {"  "}Reset
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerBox>
  );
};

export default SignIn;
