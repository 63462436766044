import { Select } from "antd";
import React from "react";
import { Colors, Fonts } from "../../../config";

const { Option } = Select;

const SelectField = ({
  variant,
  data,
  placeholder,
  size,
  id,
  type,
  disabled,
  required,
  value,
  label,
  defaultValue,
  error,
  helperText,
  onChange,
  onClick,
  startIcon,
  endIcon,
  containerStyle,
  selectStyle,
  sx,
  ...props
}) => {
  return (
    <div style={containerStyle}>
      <label
        style={{
          textAlign: "left",
          color: Colors.TextColor,
          fontFamily: Fonts["Poppins-Regular"],
          fontSize: "16px",
        }}
      >
        {label}
      </label>
      <Select
        placeholder={placeholder}
        id={id}
        size={size}
        style={selectStyle}
        disabled={disabled}
        required={required}
        value={value}
        // label={label}
        defaultValue={defaultValue}
        error={error}
        helperText={helperText}
        onChange={onChange}
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        {...props}
      >
        {data.map((item, index) => {
          return (
            <Option key={index} value={item.value}>
              {item.label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectField;
