import LineChart from "./LineChart"
import PieChart from "./PieChart"
import BarChart from "./BarChart"
import PieChartAngle from "./PieChartAngle"
import ResponsiveContainer from "./ResponsiveContainer"
import LegendBox from "./LegendsBox"

export default {
    LineChart,
    PieChart,
    BarChart,
    ResponsiveContainer,
    PieChartAngle,
    LegendBox
};