import React from "react";
import styled from "styled-components";

import { Colors } from "../../../config";

function HeadingText(props) {
  return <Heading {...props}>{props.text}</Heading>;
}

export default HeadingText;

const Heading = styled.span`
  font-size: ${(props) => props.fontSize || 24}px;
  color: ${(props) => props.color || Colors.TextColor};
  font-family: ${(props) => props.fontFamily || "Poppins-Regular"};
  font-weight: ${(props) => props.fontWeight || 400};
  text-align: ${(props) => props.textAlign || "center"};
`;
