import { Col, Pagination, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { Colors, Fonts, Images, Utils } from "../../config";
import { PromoManagementAction } from "../../store/actions";
import { DatePicker } from "antd";
import {
  Box,
  Button,
  Loader,
  Modal,
  Select,
  Switch,
  Table,
  Text,
  TextField,
} from "../../components";
import useMedia from "use-media";
import moment from "moment";
import "./index.css";

const { RangePicker } = DatePicker

function PromoManagement() {
  const promoCodes = useSelector((state) => state.promoManagementReducer);
  const isTablet = useMedia({ minWidth: "768px" });
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [editPromo, setEditPromo] = useState(false);
  const [page, setPage] = useState(1);

  const [promo, setPromo] = useState({
    id: "",
    status: "",
    name: "",
    email: [],
    promoCode: "",
    discount: "",
    validity: {
      startDate: "",
      endDate: ""
    },
    purpose: ""
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    promoCode: "",
    discount: "",
    validity: "",
    purpose: ""
  })

  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    search: "",
    status: "",
  });

  const selectionOptions = {
    discount: [
      {
        label: "5%",
        value: "5",
      },
      {
        label: "10%",
        value: "10",
      },
      {
        label: "15%",
        value: "15",
      },
      {
        label: "20%",
        value: "20",
      },
      {
        label: "25%",
        value: "25",
      },
      {
        label: "50%",
        value: "50",
      },
      {
        label: "99%",
        value: "99",
      },
    ],
    purpose: [
      {
        label: "Subscription",
        value: "Subscription",
      },
      {
        label: "Advertisement",
        value: "Advertisement",
      }
    ]
  }

  const handleRangeChange = (dates) => {
    setPromo({
      ...promo,
      validity: {
        startDate: dates[0],
        endDate: dates[1],
      },
    });
    setErrors({
      ...errors,
      validity: ""
    })
  };

  const handleEmailValidation = (e) => {
    let validEmail = true;
    if (e.length !== 0) {
      validEmail = Utils.emailRegex.test(String(e[e.length - 1]).toLowerCase())
    }
    if (!validEmail) {
      setErrors({ ...errors, email: "Email format is incorrect!" });
      e.pop()
    } else {
      setPromo({ ...promo, email: e })
      setErrors({ ...errors, email: "" });
    }
  }

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  const handleChangeStatus = (email, name, id, status) => {
    const payload = {
      name,
      email,
      status,
    };
    dispatch(
      PromoManagementAction.updatePromoStatus(id,
        payload,
        () => dispatch(PromoManagementAction.getPromos(state))
      )
    );
  };

  const handleDeletePromo = (id) => {
    dispatch(PromoManagementAction.deletePromo(id, () => {
      dispatch(PromoManagementAction.getPromos(state))
    }))
  }

  const handlePromoModal = () => {
    const {
      name,
      email,
      promoCode,
      discount,
      validity,
      purpose
    } = promo;
    let validEmails = true;

    if (email.length !== 0)
      validEmails = email.every(email => Utils.emailRegex.test(String(email).toLowerCase()));

    if (name.length === 0 || name.trim() === "") {
      setErrors({ ...errors, name: "This field cannot be left empty!" });

    } else if (name.length < 3 || name.length > 30) {
      setErrors({ ...errors, name: " 3-30 characters allowed" });

    } else if (!(Utils.nameRegex.test(name))) {
      setErrors({ ...errors, name: "Only alphabets are allowed!" })

    } else if (promoCode.length === 0 || promoCode.trim() === "") {
      setErrors({ ...errors, promoCode: "This field cannot be left empty!" });

    } else if (promoCode.length < 3 || promoCode.length > 30) {
      setErrors({ ...errors, promoCode: " 3-30 characters allowed" });

    } else if (!discount || discount === "") {
      setErrors({ ...errors, discount: "This field cannot be left empty!" });

    } else if (
      validity.startDate.length === 0
      || validity.endDate.length === 0
      || validity.startDate.trim() === ""
      || validity.endDate.trim() === ""
    ) {
      setErrors({ ...errors, validity: "This field cannot be left empty!" });

    } else if (!purpose || purpose === "") {
      setErrors({ ...errors, purpose: "This field cannot be left empty!" });

    } else {
      const {
        id,
        name,
        promoCode,
        purpose,
        email,
        discount,
        status,
        validity
      } = promo

      const { startDate, endDate } = validity

      if (editPromo) {
        dispatch(PromoManagementAction.editPromo({
          id,
          email,
          name,
          status
        },
          (success) => {
            if (success) {
              setEditPromo(false);
              setShowModal(false);
              setErrors({
                name: "",
                email: "",
                promoCode: "",
                discount: "",
                validity: "",
                purpose: ""
              })
              setPromo({
                id: "",
                status: "",
                name: "",
                email: [],
                promoCode: "",
                discount: "",
                validity: {
                  startDate: "",
                  endDate: ""
                },
                purpose: ""
              })
              dispatch(PromoManagementAction.getPromos(state))
            }
          }
        ))
      } else {
        dispatch(
          PromoManagementAction.addPromo(
            {
              name,
              code: promoCode,
              purpose,
              email,
              discount,
              startDate,
              endDate
            },
            (success) => {
              if (success) {
                setShowModal(false);
                setErrors({
                  name: "",
                  email: "",
                  promoCode: "",
                  discount: "",
                  validity: "",
                  purpose: ""
                })
                setPromo({
                  id: "",
                  status: "",
                  name: "",
                  email: [],
                  promoCode: "",
                  discount: "",
                  validity: {
                    startDate: "",
                    endDate: ""
                  },
                  purpose: ""
                })
                dispatch(PromoManagementAction.getPromos(state))
              }
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    dispatch(PromoManagementAction.getPromos(state));
  }, [state]);

  return (
    <>
      <Box>
        <Row justify="space-between">
          <Col className="flex-start">
            <Text.Heading
              fontSize={24}
              fontFamily={Fonts["Poppins-SemiBold"]}
              color={Colors.Black}
              fontWeight={600}
              text={"Promo Management"}
            />
          </Col>
          <Col
            md={6}
            sm={isTablet ? 19 : 24}
            className={isTablet
              ? "flex-end"
              : "flex-row-start mt-20"
            }
          >
            <Button.Basic
              onClick={() => {
                setShowModal(true)
              }}
              text={"Add Promo"}
              startIcon={<AiFillPlusCircle color="white" />}
              className="add-promo-btn"
            />
          </Col>
        </Row>
        <Row
          justify="space-between"
          className="mt-20"
        >
          <Col span={24}>
            {promoCodes.isLoading ? (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTp: "20px",
                }}
              >
                <Loader.Circular />
              </Col>
            ) : (
              <>
                <Table.AntDesignTable
                  className="user-table"
                  loading={promoCodes.isloading}
                  columns={[
                    {
                      title: "Name",
                      dataIndex: "name",
                      key: "name",
                      render: (text, record) => {
                        return (
                          <span>
                            {text.length > 20
                              ? `${text.slice(0, 20)}...`
                              : text}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Promo Code",
                      dataIndex: "code",
                      key: "code",
                      render: (text, record) => {
                        return (
                          <span style={{ marginLeft: "5px" }}>
                            {text.length > 20
                              ? `${text.slice(0, 20)}...`
                              : text}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Consumed",
                      dataIndex: "consumeCount",
                      key: "consumeCount",
                      render: (text, record) => {
                        return (
                          <span>
                            {text}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Purpose",
                      dataIndex: "purpose",
                      key: "purpose",
                      render: (text, record) => {
                        return (
                          <span>
                            {text}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Discount",
                      dataIndex: "discount",
                      key: "discount",
                      render: (text, record) => {
                        return (
                          <span>
                            {text}%
                          </span>
                        );
                      },
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      key: "status",
                      render: (text, record) => {
                        let status = moment().format("YYYY-MM-DD") > moment(record?.endDate).format("YYYY-MM-DD") && text
                          ? "Expired"
                          : text
                            ? "Active"
                            : "Inactive"
                        return (
                          <div
                            className="user-status"
                            style={{
                              backgroundColor:
                                status === "Active"
                                  ? Colors.Green
                                  : status === "Inactive"
                                    ? Colors.Black
                                    : Colors.gray,
                              color: Colors.White
                            }}
                          >
                            {status}
                          </div>
                        )
                      },
                    },
                    {
                      title: "",
                      dataIndex: "",
                      render: (text, record) => {
                        let status = moment().format("YYYY-MM-DD") > moment(record?.endDate).format("YYYY-MM-DD")
                          && record.status
                        return (
                          <Switch.Basic
                            disabled={status}
                            checked={record.status}
                            onChange={() =>
                              handleChangeStatus(
                                record.email,
                                record.name,
                                record.id,
                                !record.status ? true : false
                              )
                            }
                          />
                        )
                      },
                    },
                    {
                      title: "Action",
                      dataIndex: "",
                      render: (text, record) => (
                        <span>
                          <img
                            src={Images.EditIcon}
                            onClick={() => {
                              setShowModal(true)
                              setEditPromo(true)
                              setPromo({
                                ...promo,
                                id: record.id,
                                status: record.status,
                                name: record.name,
                                email: record.email,
                                promoCode: record.code,
                                purpose: record.purpose,
                                discount: record.discount,
                                validity: {
                                  startDate: record.startDate,
                                  endDate: record.endDate
                                }
                              })
                            }}
                          />{" "}
                          <img
                            onClick={() => {
                              setPromo({
                                ...promo,
                                id: record.id,
                                promoCode: record.code
                              })
                              setShowConfirmationModal(true)
                            }}
                            src={Images.DeleteIcon}
                          />
                        </span>
                      ),
                    },
                  ]}
                  data={promoCodes?.promoCodes}
                  pagination={true}
                  rowKey="key"
                />
                <Row justify="center" className="mt-20">
                  <Col>
                    <Pagination
                      responsive="true"
                      showLessItems="true"
                      current={page}
                      pageSize={10}
                      total={promoCodes.count}
                      onChange={handlePaginationChange}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Box>
      {showModal && (
        <Modal.AntDesignModal
          centered={true}
          className={"promo-modal"}
          title=""
          isModalVisible={showModal}
          handleCancel={() => {
            setPromo({
              id: "",
              status: "",
              name: "",
              email: [],
              promoCode: "",
              validity: {
                startDate: "",
                endDate: ""
              },
              purpose: ""
            });
            setErrors({
              name: "",
              email: "",
              promoCode: "",
              discount: "",
              validity: "",
              purpose: ""
            });
            setEditPromo(false);
            setShowModal(false);
          }}
        >
          <Row justify="center">
            <Col span={24}
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={editPromo ? "Edit Promo" : "Add Promo"}
              />
            </Col>
          </Row>
          <Row
            justify="start"
            className="mt-20"
          >
            <Col span={24}>
              <Row
                justify="center"
                gutter={[25, 25]}
              >
                <Col span={12}>
                  <TextField.CustomInput
                    label="Name: "
                    placeholder="Enter Promo Name"
                    value={promo.name}
                    size="large"
                    type="text"
                    className="user-subject-field"
                    onChange={(e) => {
                      setPromo({ ...promo, name: e.target.value })
                      setErrors({ ...errors, name: "" })
                    }
                    }
                  />
                  {errors.name !== "" &&
                    <Text.Heading
                      fontSize={14}
                      fontFamily={Fonts["Poppins-Regular"]}
                      color={"red"}
                      fontWeight={400}
                      text={errors.name}
                      textAlign="center"
                      className="mt-10"
                    />}

                </Col>
                <Col span={12}>
                  <Select.MultiSelect
                    label={"Email:"}
                    placeholder="Enter Email"
                    className="promo-code-select-field"
                    mode="tags"
                    selectStyle={{
                      width: '100%'
                    }}
                    onChange={handleEmailValidation}
                    tokenSeparators={[',']}
                    size="large"
                    value={promo.email ? promo.email : []}
                  />

                  {errors.email &&
                    <Text.Heading
                      fontSize={14}
                      fontFamily={Fonts["Poppins-Regular"]}
                      color={"red"}
                      fontWeight={400}
                      text={errors.email}
                      textAlign="center"
                      className="mt-10"
                    />
                  }
                </Col>
              </Row>
              <Row
                justify="center"
                gutter={[25, 25]}
                className="mt-20"
              >
                <Col span={12}>
                  <TextField.CustomInput
                    label="Promo Code: "
                    placeholder="Enter Promo Code"
                    disabled={editPromo}
                    value={promo.promoCode}
                    size="large"
                    type="text"
                    className="user-subject-field"
                    onChange={(e) => {
                      setPromo({ ...promo, promoCode: e.target.value })
                      setErrors({ ...errors, promoCode: "" })
                    }
                    }
                  />
                  {errors.promoCode !== "" &&
                    <Text.Heading
                      fontSize={14}
                      fontFamily={Fonts["Poppins-Regular"]}
                      color={"red"}
                      fontWeight={400}
                      text={errors.promoCode}
                      textAlign="center"
                      className="mt-10"
                    />
                  }
                </Col>
                <Col span={12}>
                  <TextField.SelectField
                    className="promo-code-select-field"
                    placeholder="Select Discount"
                    defaultValue={editPromo ? promo.discount : undefined}
                    selectStyle={{ width: "100%" }}
                    size="large"
                    id="provider-management-select"
                    type="text"
                    disabled={editPromo}
                    required={false}
                    label="Discount:"
                    error={false}
                    helperText=""
                    onChange={(e) => {
                      setPromo({ ...promo, discount: e })
                      setErrors({ ...errors, discount: "" })
                    }}
                    data={selectionOptions.discount}
                  />
                  {errors.discount &&
                    <Text.Heading
                      fontSize={14}
                      fontFamily={Fonts["Poppins-Regular"]}
                      color={"red"}
                      fontWeight={400}
                      text={errors.discount}
                      textAlign="center"
                      className="mt-10"
                    />
                  }

                </Col>
              </Row>
              <Row
                justify="center"
                gutter={[25, 25]}
                className="mt-20"
              >
                <Col span={12}>
                  <label
                    style={{
                      textAlign: "left",
                      color: Colors.TextColor,
                      fontFamily: Fonts["Poppins-Regular"],
                      fontSize: "16px",
                    }}
                  >
                    Code Duration:
                  </label>
                  <RangePicker
                    label={"Code Duration:"}
                    className={"promo-screen-range-picker"}
                    disabled={editPromo}
                    format={"YYYY-MM-DD"}
                    allowClear={false}
                    disabledDate={(current) => {
                      var startDate = moment().subtract(12, "month");
                      return current < startDate;
                    }}
                    defaultValue={editPromo ? [
                      moment(promo.validity.startDate),
                      moment(promo.validity.endDate)
                    ] : ""}
                    onChange={(date, string) => handleRangeChange(string)}
                  />

                  {errors.validity &&
                    <Text.Heading
                      fontSize={14}
                      fontFamily={Fonts["Poppins-Regular"]}
                      color={"red"}
                      fontWeight={400}
                      text={errors.validity}
                      textAlign="center"
                      className="mt-10"
                    />
                  }
                </Col>
                <Col span={12}>
                  <TextField.SelectField
                    className="promo-code-select-field"
                    placeholder="Select Purpose"
                    selectStyle={{ width: "100%" }}
                    size="large"
                    id="provider-management-select"
                    type="text"
                    disabled={editPromo}
                    required={false}
                    label="Purpose:"
                    error={false}
                    defaultValue={editPromo ? promo.purpose : undefined}
                    helperText=""
                    onChange={(e) => {
                      setPromo({ ...promo, purpose: e })
                      setErrors({ ...errors, purpose: "" })
                    }
                    }
                    data={selectionOptions.purpose}
                  />
                  {errors.purpose && (
                    <Text.Heading
                      fontSize={14}
                      fontFamily={Fonts["Poppins-Regular"]}
                      color={"red"}
                      fontWeight={400}
                      text={errors.purpose}
                      textAlign="center"
                      className="mt-10"
                    />
                  )}
                </Col>
              </Row>
              {promo.purpose === "Subscription" && (
                <Row justify="start" className="mt-20">
                  <Col span={12}>
                    <TextField.CustomInput
                      style={{ width: "96%" }}
                      label="Subcription Length:"
                      value={"1 Year"}
                      size="large"
                      type="text"
                      className="user-subject-field"
                      disabled={true}
                    />
                  </Col>
                </Row>
              )}

              <Row
                gutter={[20, 50]}
                justify="center"
                className="mt-40"
              >
                <Col span={12}>
                  <Button.Basic
                    loading={promoCodes.isLoading}
                    onClick={handlePromoModal}
                    text={editPromo ? "Edit Promo" : "Add Promo"}
                    className="add-promo-btn"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}

      {showConfirmationModal &&
        <Modal.ConfirmationModal
          centered={true}
          title={""}
          isModalVisible={showConfirmationModal}
          heading={`Are you sure you want to delete ${promo?.promoCode}?`
          }
          handleCancel={() => {
            setPromo({
              ...promo,
              id: "",
              promoCode: ""
            })
            setShowConfirmationModal(false)
          }}
          handleDelete={() => {
            setPromo({
              ...promo,
              id: "",
              promoCode: ""
            })
            setShowConfirmationModal(false)
            handleDeletePromo(promo?.id)
          }}
        ></Modal.ConfirmationModal>}
    </>
  );
}

export default PromoManagement;
