import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  REMOVE_AUTH_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SIGNIN,
  SIGNIN_FAILURE,
  SIGNIN_SUCCESS,
  VERIFY_CODE,
  VERIFY_CODE_FAILURE,
  VERIFY_CODE_SUCCESS,
} from "../constants";

export default class AuthAction {
  static signIn(payload, cb) {
    return {
      type: SIGNIN,
      payload,
      cb,
    };
  }
  static signInSuccess(payload) {
    return {
      type: SIGNIN_SUCCESS,
      payload,
    };
  }
  static signInFailure(payload) {
    return {
      type: SIGNIN_FAILURE,
      payload,
    };
  }

  static logout(payload, cb) {
    return {
      type: LOGOUT,
      payload,
      cb,
    };
  }
  static logoutSuccess() {
    return {
      type: LOGOUT_SUCCESS,
    };
  }
  static logoutFailure() {
    return {
      type: LOGOUT_FAILURE,
    };
  }
  static forgotPassword(payload, cb) {
    return {
      type: FORGET_PASSWORD,
      payload,
      cb,
    };
  }
  static forgotPasswordSuccess(payload) {
    return {
      type: FORGET_PASSWORD_SUCCESS,
      payload,
    };
  }
  static forgotPasswordFailure(payload) {
    return {
      type: FORGET_PASSWORD_FAILURE,
      payload,
    };
  }
  static verifyCode(payload, cb) {
    return {
      type: VERIFY_CODE,
      payload,
      cb,
    };
  }
  static verifyCodeSuccess(payload) {
    return {
      type: VERIFY_CODE_SUCCESS,
      payload,
    };
  }
  static verifyCodeFailure(payload) {
    return {
      type: VERIFY_CODE_FAILURE,
      payload,
    };
  }
  static resetPassword(payload, cb) {
    return {
      type: RESET_PASSWORD,
      payload,
      cb,
    };
  }
  static resetPasswordSuccess(payload) {
    return {
      type: RESET_PASSWORD_SUCCESS,
      payload,
    };
  }
  static resetPasswordFailure(payload) {
    return {
      type: RESET_PASSWORD_FAILURE,
      payload,
    };
  }
  static removeError() {
    return {
      type: REMOVE_AUTH_ERROR,
    };
  }
  static loaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }
  static loaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
}
