import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { DashboardAction } from "../actions";

export default class DashboardMiddleware {
  static *getUserStats({ params }) {
    const searchParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(`/admin/dashboard/signup?${searchParams}`);

      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        // if (cb) {
        //   cb(true);
        // }
        yield put(DashboardAction.getUserStatsSuccess(res.data.data.users));
      } else {
        yield put(
          DashboardAction.getUserStatsFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        // if (cb) {
        //   cb(false);
        // }
      }
    } catch (err) {
      yield put(DashboardAction.getUserStatsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *getPostCreated({ params }) {
    const searchParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(`/admin/dashboard/post?${searchParams}`);


      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        // if (cb) {
        //   cb(true);
        // }
        yield put(
          DashboardAction.getPostCreatedStatsSuccess(
            res.data.data.postsAndPolls
          )
        );
      } else {
        yield put(
          DashboardAction.getPostCreatedStatsFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        // if (cb) {
        //   cb(false);
        // }
      }
    } catch (err) {
      yield put(DashboardAction.getPostCreatedStatsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *getServiceUser({ params }) {
    const searchParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(`/admin/dashboard/user?${searchParams}`);


      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        // if (cb) {
        //   cb(true);
        // }
        yield put(
          DashboardAction.getServiceUserStatsSuccess(res.data.data.users)
        );
      } else {
        yield put(
          DashboardAction.getServiceUserStatsFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        // if (cb) {
        //   cb(false);
        // }
      }
    } catch (err) {
      yield put(DashboardAction.getServiceUserStatsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *getNoOfServices({ params }) {
    const searchParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(
        `/admin/dashboard/booking-stats?${searchParams}`
      );


      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        // if (cb) {
        //   cb(true);
        // }
        yield put(
          DashboardAction.getNoOfServiceSuccess(res.data.data.bookings)
        );
      } else {
        yield put(
          DashboardAction.getNoOfServiceFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        // if (cb) {
        //   cb(false);
        // }
      }
    } catch (err) {
      yield put(DashboardAction.getNoOfServiceFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *serviceRequested({ params }) {
    const searchParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(
        `/admin/dashboard/service-requested?${searchParams}`
      );


      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        // if (cb) {
        //   cb(true);
        // }
        yield put(
          DashboardAction.serviceRequestedSuccess(res.data.data.services)
        );
      } else {
        yield put(
          DashboardAction.serviceRequestedFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        // if (cb) {
        //   cb(false);
        // }
      }
    } catch (err) {
      yield put(DashboardAction.serviceRequestedFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *getSubscriptionStats(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Get(
        `/admin/dashboard/subscription?startDate=${payload.startDate}&endDate=${payload.endDate}`
      );


      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(
          DashboardAction.getSubscriptionSuccess(res.data.data.subscriptions)
        );
      } else {
        yield put(
          DashboardAction.getSubscriptionFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(DashboardAction.getSubscriptionFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *getCount() {
    try {
      let res = yield ApiCaller.Get(`/admin/service-provider/uploaded-documents`);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.message });

        yield put(DashboardAction.getCountSuccess(res?.data));
      } else {
        yield put(
          DashboardAction.getCountFailure(
            res?.data?.error?.message
          )
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });

      }
    } catch (err) {
      yield put(DashboardAction.getCountFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *getActionReq() {
    try {
      const res = yield ApiCaller.Get(`/admin/dashboard/action-counts`);
      console.log("thsi is the action counts", res)
      if (res.status === 200) {
        yield put(DashboardAction.getActionReqSuccess(res?.data))
        // Utils.showMessage('success', '');
      } else {
        yield put(DashboardAction.getActionReqFailure())
        // Utils.showMessage('error', res?.message)
      }
    }
    catch (err) {
      yield put(DashboardAction.getActionReqFailures())
      // Utils.showMessage('error', 'Request Failed')
    }
  }

  static *getSignups() {
    try {
      const res = yield ApiCaller.Get(`/admin/dashboard/users-count`);
      if (res.status === 200) {
        yield put(DashboardAction.getSignupsSuccess(res?.data))
        // Utils.showMessage('success', '');
      } else {
        yield put(DashboardAction.getSignupsFailure())
        // Utils.showMessage('error', res?.message)
      }
    }
    catch (err) {
      yield put(DashboardAction.getSignupsFailure())
      // Utils.showMessage('error', 'Request Failed')
    }
  }

  static *getDeletedAcc() {
    try {
      const res = yield ApiCaller.Get(`/admin/dashboard/deleted-users-count`);
      if (res.status === 200) {
        yield put(DashboardAction.getDeletedAccSuccess(res?.data))
        // Utils.showMessage('success', '');
      } else {
        yield put(DashboardAction.getDeletedAccFailure())
        // Utils.showMessage('error', res?.message)
      }
    }
    catch (err) {
      yield put(DashboardAction.getDeletedAccFailure())
      // Utils.showMessage('error', 'Request Failed')
    }
  }

  static *getServiceProviders() {
    try {
      const res = yield ApiCaller.Get(`/admin/dashboard/service-providers-count`);
      if (res.status === 200) {
        yield put(DashboardAction.getServiceProvidersSuccess(res?.data))
        // Utils.showMessage('success', '');
      } else {
        yield put(DashboardAction.getServiceProvidersFailure())
        // Utils.showMessage('error', res?.message)
      }
    }
    catch (err) {
      yield put(DashboardAction.getServiceProvidersFailure())
      // Utils.showMessage('error', 'Request Failed')
    }
  }

  static *getJobs() {
    try {
      const res = yield ApiCaller.Get(`/admin/dashboard/jobs-count`);
      if (res.status === 200) {
        yield put(DashboardAction.getJobsSuccess(res?.data))
        // Utils.showMessage('success', '');
      } else {
        yield put(DashboardAction.getJobsFailure())
        // Utils.showMessage('error', res?.message)
      }
    }
    catch (err) {
      yield put(DashboardAction.getJobsFailure())
      // Utils.showMessage('error', 'Request Failed')
    }
  }

  static *getAds() {
    try {
      const res = yield ApiCaller.Get(`/admin/dashboard/advertisement-count`);
      if (res.status === 200) {
        yield put(DashboardAction.getAdsSuccess(res?.data))
        // Utils.showMessage('success', '');
      } else {
        yield put(DashboardAction.getAdsFailure())
        // Utils.showMessage('error', res?.message)
      }
    }
    catch (err) {
      yield put(DashboardAction.getAdsFailure())
      // Utils.showMessage('error', 'Request Failed')
    }
  }

  static *getServices() {
    try {
      const res = yield ApiCaller.Get(`/admin/dashboard/services-count`);
      if (res.status === 200) {
        yield put(DashboardAction.getServicesSuccess(res?.data))
        // Utils.showMessage('success', '');
      } else {
        yield put(DashboardAction.getServicesFailure())
        // Utils.showMessage('error', res?.message)
      }
    }
    catch (err) {
      yield put(DashboardAction.getServicesFailure())
      // Utils.showMessage('error', 'Request Failed')
    }
  }

  static *getPostAndReports() {
    try {
      const res = yield ApiCaller.Get(`/admin/dashboard/total-count`);
      if (res.status === 200) {
        yield put(DashboardAction.getPostAndReportsSuccess(res?.data))
        // Utils.showMessage('success', '');
      } else {
        yield put(DashboardAction.getPostAndReportsFailure())
        // Utils.showMessage('error', res?.message)
      }
    }
    catch (err) {
      yield put(DashboardAction.getPostAndReportsFailure())
      // Utils.showMessage('error', 'Request Failed')
    }
  }
}
