import BackgroundImage from "../../assets/images/background.png";
import ContactSupportIcon from "../../assets/images/contactSupportIcon.svg";
import DashboardLogo from "../../assets/images/dashboardLogo.png";
import DefaultUser from "../../assets/images/default-user-avatar.svg";
import DeleteBtnIcon from "../../assets/images/DeleteBtnIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.png";
import DropdownIcon from "../../assets/images/dropdownIcon.png";
import EditIcon from "../../assets/images/editIcon.png";
import Emailcon from "../../assets/images/EmailIcon.svg";
import Warning from "../../assets/images/warning.png";

import DeleteSubServiceIcon from "../../assets/images/Icon ionic-md-remove-circle.svg";
import Logo from "../../assets/images/logo.png";
import LogoutIcon from "../../assets/images/logoutIcon.svg";
import PlaceHolderImage from "../../assets/images/placeholder.png";
import AddSquareIcon from "../../assets/images/plusSquareIcon.png";
import Profile from "../../assets/images/profile.png";
import ReportBtnIcon from "../../assets/images/ReportBtnIcon.svg";
import ReporteeDefaultImg from "../../assets/images/reporteeDefaultImg.png";
import ReporterDefaultImg from "../../assets/images/reporterDefaultImg.png";
import SuspendBtnIcon from "../../assets/images/SuspendBtnIcon.svg";
import UploadFileIcon from "../../assets/images/upload-cloud.png";
import UploadUser from "../../assets/images/uploadUser.svg";
import UserBtnIcon from "../../assets/images/UserBtnIcon.svg";
import UsersIcon from "../../assets/images/usersIcon.svg";
import ViewIcon from "../../assets/images/view.svg";

const Images = {
  Logo,
  BackgroundImage,
  DashboardLogo,
  DropdownIcon,
  LogoutIcon,
  Profile,
  Emailcon,
  PlaceHolderImage,
  EditIcon,
  DeleteIcon,
  AddSquareIcon,
  UploadFileIcon,
  DeleteSubServiceIcon,
  UsersIcon,
  ContactSupportIcon,
  DefaultUser,
  UploadUser,
  ReporteeDefaultImg,
  ReporterDefaultImg,
  ReportBtnIcon,
  SuspendBtnIcon,
  DeleteBtnIcon,
  UserBtnIcon,
  ViewIcon,
  Warning
};

export default Images;
