import { DatePicker } from "antd";
import React from "react";
import { Colors, Fonts } from "../../config";

const { RangePicker } = DatePicker;

function AntdDatePicker({
  className,
  value,
  handleChange,
  styleContainer,
  month,
  allowClear,
  ...props
}) {
  return (
    <>
      {props.label && (
        <label
          style={{
            textAlign: "left",
            color: Colors.TextColor,
            fontFamily: Fonts["Poppins-Regular"],
            fontSize: "16px",
          }}
        >
          {props.label}
        </label>
      )}
      <RangePicker
        className={className}
        value={value}
        onChange={handleChange}
        style={styleContainer}
        picker={month ? "month" : ""}
        allowClear={allowClear}
        {...props}
      />
    </>
  );
}

export default AntdDatePicker;
