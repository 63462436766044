import {
  ADD_SERVICE,
  ADD_SERVICE_FAILURE,
  ADD_SERVICE_SUCCESS,
  DELETE_SERVICE,
  DELETE_SERVICE_FAILURE,
  DELETE_SERVICE_SUCCESS,
  DELETE_SUB_SERVICE,
  DELETE_SUB_SERVICE_FAILURE,
  DELETE_SUB_SERVICE_SUCCESS,
  GET_ALL_SERVICES,
  GET_ALL_SERVICES_FAILURE,
  GET_ALL_SERVICES_SUCCESS,
  GET_ALL_SUB_SERVICES,
  GET_ALL_SUB_SERVICES_FAILURE,
  GET_ALL_SUB_SERVICES_SUCCESS,
  UPDATE_SERVICE,
  UPDATE_SERVICE_FAILURE,
  UPDATE_SERVICE_SUCCESS,
} from "../constants";

export default class ServiceAction {
  static getAllServices(payload, cb) {
    return {
      type: GET_ALL_SERVICES,
      payload,
      cb,
    };
  }
  static getAllServicesSuccess(payload) {
    return {
      type: GET_ALL_SERVICES_SUCCESS,
      payload,
    };
  }
  static getAllServicesFailure(payload) {
    return {
      type: GET_ALL_SERVICES_FAILURE,
      payload,
    };
  }
  static getAllSubServices(id, cb) {
    return {
      type: GET_ALL_SUB_SERVICES,
      id,
      cb,
    };
  }
  static getAllSubServicesSuccess(payload) {
    return {
      type: GET_ALL_SUB_SERVICES_SUCCESS,
      payload,
    };
  }
  static getAllSubServicesFailure(payload) {
    return {
      type: GET_ALL_SUB_SERVICES_FAILURE,
      payload,
    };
  }
  static addService(payload, cb) {
    return {
      type: ADD_SERVICE,
      payload,
      cb,
    };
  }
  static addServiceSuccess(payload) {
    return {
      type: ADD_SERVICE_SUCCESS,
      payload,
    };
  }
  static addServiceFailure(payload) {
    return {
      type: ADD_SERVICE_FAILURE,
      payload,
    };
  }
  static deleteService(id, cb) {
    return {
      type: DELETE_SERVICE,
      id,
      cb,
    };
  }
  static deleteServiceSuccess(payload) {
    return {
      type: DELETE_SERVICE_SUCCESS,
      payload,
    };
  }
  static deleteServiceFailure(payload) {
    return {
      type: DELETE_SERVICE_FAILURE,
      payload,
    };
  }
  static updateService(id, payload, cb) {
    return {
      type: UPDATE_SERVICE,
      id,
      payload,
      cb,
    };
  }
  static updateServiceSuccess(payload) {
    return {
      type: UPDATE_SERVICE_SUCCESS,
      payload,
    };
  }
  static updateServiceFailure(payload) {
    return {
      type: UPDATE_SERVICE_FAILURE,
      payload,
    };
  }
  static deleteSubService(id, cb) {
    return {
      type: DELETE_SUB_SERVICE,
      id,
      cb,
    };
  }
  static deleteSubServiceSuccess(payload) {
    return {
      type: DELETE_SUB_SERVICE_SUCCESS,
      payload,
    };
  }
  static deleteSubServiceFailure(payload) {
    return {
      type: DELETE_SUB_SERVICE_FAILURE,
      payload,
    };
  }
}
