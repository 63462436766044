import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

const Basic = ({ columns, rows, pageSize, rowsPerPageOptions, celltextRight, cellediting, celltextCenter, checkboxSelection, celltextLeft, autoHeight = true, hideFooterPagination, hideFooter, style, containerStyle, ...props }) => {
    return (
        <div style={containerStyle}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={rowsPerPageOptions}
                checkboxSelection={checkboxSelection}
                autoHeight={autoHeight}
                hideFooterPagination={hideFooterPagination}
                hideFooter={hideFooter}
                cell--textLeft={celltextLeft}
                cell--textCenter={celltextCenter}
                cell--textRight={celltextRight}
                cell--editing={cellediting}
                style={{ width: 500, ...style }}
            />
        </div>
    )
}

export default Basic;


    // const columns = [
    //     { field: "id", headerName: "ID", width: 100 },
    //     { field: "firstName", headerName: "First name", width: 180 },
    //     { field: "lastName", headerName: "Last name", width: 180 },
    //     {
    //         field: "age",
    //         headerName: "Age",
    //         type: "number",
    //         width: 130
    //     },
    //     {
    //         field: "fullName",
    //         headerName: "Full name",
    //         description: "This column has a value getter and is not sortable.",
    //         sortable: false,
    //         width: 160,
    //         valueGetter: (params) =>
    //             `${params.getValue(params.id, "firstName") || ""} ${params.getValue(params.id, "lastName") || ""
    //             }`
    //     }
    // ];

    // const rows = [
    //     { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    //     { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    //     { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    //     { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    //     { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    //     { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    //     { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    //     { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    //     { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 }
    // ];