import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from '../../../config'

function SubHeading(props) {
  return (
    <Heading {...props}>{props.text}</Heading>
  )
}

export default SubHeading

const Heading=styled.span`
    font-size:15px;
    color: ${Colors.LightTextColor};
    font-family: ${Fonts["Poppins-Regular"]};
    font-weight: 400;
    
`