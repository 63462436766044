import { Col, Modal, Row } from "antd";
import React from "react";

import { Button, Text } from "../index";
import { Colors, Fonts } from "../../config";


const ConfirmationModal = ({
  title,
  isModalVisible,
  style,
  heading,
  subHeading,
  handleCancel,
  handleDelete,
}) => {
  return (
    <Modal
      title={title}
      style={style}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={false}
      className="confirmation-modal"
    >
      {console.log("inside confirmation box=====")}
      <Row
        justify="center"
        style={{
          padding: subHeading ? "40px 40px 0 40px" : "40px 40px 20px 40px",
        }}
      >
        <Col
          style={{
            textAlign: "center",
          }}
        >
          <Text.Heading
            fontSize={24}
            fontFamily={Fonts["Poppins-Medium"]}
            color={Colors.Black}
            fontWeight={500}
            text={heading}
            textAlign="center"
          />
        </Col>
      </Row>
      {subHeading && (
        <Row
          justify="center"
          style={{
            padding: "20px 30px",
          }}
        >
          <Col
            style={{
              textAlign: "center",
            }}
          >
            <Text.Heading
              fontSize={18}
              fontFamily={Fonts["Poppins-Medium"]}
              color={Colors.Black}
              fontWeight={500}
              text={subHeading}
              textAlign="center"
            />
          </Col>
        </Row>
      )}
      <Row
        style={{
          marginTop: "20px",
          marginBottom: "0px",
        }}
      >
        <Col span={12}>
          <Button.Basic
            onClick={handleDelete}
            text="Yes"
            style={{
              backgroundColor: Colors.Primary,
              borderColor: Colors.Primary,
              color: Colors.Black,
              fontSize: 16,
              fontFamily: Fonts["Poppins-SemiBold"],
              fontWeight: 600,
              padding: "10px 20px",
              borderRadius: "0 0 0 20px",
              width: "100%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          />
        </Col>
        <Col span={12}>
          <Button.Basic
            onClick={handleCancel}
            text="No"
            style={{
              backgroundColor: Colors.Black,
              borderColor: Colors.Black,
              color: Colors.White,
              fontSize: 16,
              fontFamily: Fonts["Poppins-SemiBold"],
              fontWeight: 600,
              padding: "10px 20px",
              borderRadius: "0 0 20px 0",
              width: "100%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmationModal;
