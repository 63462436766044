import { Input } from "antd";
import React from "react";

const { Search } = Input;

const SearchTextField = ({
  placeholder,
  enterButton,
  size,
  id,
  type,
  disabled,
  required,
  value,
  label,
  defaultValue,
  error,
  helperText,
  onChange,
  onClick,
  startIcon,
  endIcon,
  containerStyle,
  selectStyle,
  sx,
  ...props
}) => {
  return (
    <div style={containerStyle}>
      <Search
        placeholder={placeholder}
        enterButton={enterButton}
        size={size}
        style={selectStyle}
        disabled={disabled}
        required={required}
        value={value}
        label={label}
        defaultValue={defaultValue}
        error={error}
        helperText={helperText}
        onChange={onChange}
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        {...props}
      />
    </div>
  );
};

export default SearchTextField;
