import { Col, Modal, Row } from "antd";
import React, { useState } from "react";
import TextField from "../CommonComponents/TextField";
import { AdManagementAction } from "../../store/actions";
import { Button, Text } from "../index";
import { Colors, Fonts } from "../../config";
import { useDispatch, useSelector } from "react-redux";

const AcceptModal = ({
  title,
  isModalVisible,
  style,
  heading,
  subHeading,
  handleCancel,
  id,
  limit,
  offset,
  handleDelete,
}) => {
  const [values, setValues] = useState({
    reasonOfRejection: "",
    modalVisible: isModalVisible,
  });

  const { reasonOfRejection, modalVisible } = values;

  const [errors, setErrors] = useState({
    reasonOfRejectionError: "",
  });

  const { reasonOfRejectionError } = errors;
  const dispatch = useDispatch();
  const updateAdLoader = useSelector(
    (state) => state?.adManagementReducer?.updateAdLoader
  );
  const handleReason = (val) => {
    setValues({ ...values, reasonOfRejection: val.target.value });
    setErrors("");
  };

  return (
    <Modal
      title={title}
      style={style}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={false}
      className="confirmation-modal"
    >
      <Row
        justify="center"
        style={{
          padding: "40px 0px 0px 0px",
        }}
        gutter={[0, 30]}
      >
        <Col
          style={{
            textAlign: "center",
          }}
          align="middle"
        >
          <Text.Heading
            fontSize={24}
            fontFamily={Fonts["Poppins-Medium"]}
            color={Colors.Black}
            fontWeight={500}
            text={heading}
            textAlign="center"
          />
        </Col>

        <Col span={20}>
          <TextField.Textarea
            size="large"
            placeholder={"Enter the reason"}
            maxLength={150}
            style={{
              border: "1px solid #F5F5F5",
              boxShadow: "0px 3px 6px #0000001A",
              borderRadius: 10,
            }}
            onChange={(e) => handleReason(e)}
          />
          <span style={{ color: "red" }}>{reasonOfRejectionError}</span>
        </Col>
        <Col span={24}>
          <Button.Basic
            loading={updateAdLoader}
            onClick={() => {
              if (!reasonOfRejection.length) {
                setErrors({
                  ...errors,
                  reasonOfRejectionError: "Please Enter the reason",
                });
                return;
              } else {
                dispatch(
                  AdManagementAction.updateAdRequest(
                    {
                      status: 3,
                      id,
                      reasonOfRejection,
                    },
                    () => {
                      handleCancel();
                      dispatch(
                        AdManagementAction.getAdsRequest({ limit, offset })
                      );
                    }
                  )
                );
              }
            }}
            text="Confirm and Send"
            style={{
              backgroundColor: Colors.Primary,
              borderColor: Colors.Primary,
              color: Colors.Black,
              fontSize: 16,
              fontFamily: Fonts["Poppins-SemiBold"],
              fontWeight: 600,
              padding: "10px 20px",
              borderRadius: "0 0 20px 20px",
              width: "100%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AcceptModal;
