import { Drawer, Dropdown, Tooltip } from "antd";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useMedia from "use-media";

import { AuthAction, DashboardAction } from "../../store/actions";
import { Button } from "../../components";
import { Colors, Fonts, Images } from "../../config";
import ProfileDropDown from "./dropdown.js";
import warning from '../../assets/images/warning.png'

import "./navHeader.css";

const NavHeader = () => {
  let adminRole;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const authUser = useSelector((state) => state.auth.user);
  const count = useSelector((state) => state?.dashboard?.count?.data?.data?.uploadedDocumentsCount);
  console.log('this is rhe count',count)

  if (authUser.user) {
    adminRole = authUser.user.roleId;
  } else {
    const authLS = JSON.parse(localStorage.getItem("authUser"));
    adminRole = authLS.user.roleId;
  }

  const isTablet = useMedia({ minWidth: "770px" });
  const isMobile = useMedia({ minWidth: "500px" });

  const showDrawer = () => {
    setVisible(true);
  };

  const showName = (name) => {
    return name.length > 10 ? `${name.slice(0, 10)}...` : name;
  };

  const onClose = () => {
    setVisible(false);
  };
  const user = useSelector((state) => state.auth.user);
  const userLS = JSON.parse(localStorage.getItem("authUser"));
  const title = ((location || {}).pathname || {}).split("/")[1];

  const handleLogout = () => {
    dispatch(AuthAction.logout());
  };

  useEffect(() => {
    dispatch(DashboardAction.getCount())
  }, []);

  const Hamburger = ({ placement }) => (
    <>
      <div className="nav-header-mobile" onClick={showDrawer}>
        <GiHamburgerMenu
          style={{
            color: Colors.White,
            fontSize: "30px",
          }}
          className="hamburger-menu"
        />
      </div>
      <Drawer
        closeIcon={false}
        title=""
        placement={placement}
        onClose={onClose}
        visible={visible}
        width={isMobile ? 378 : 320}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", //isMobile ? "flex-start" : "flex-end",
            alignItems: "center",
            paddingLeft: isMobile ? "15px" : "0px",
            paddingRight: isMobile ? "0px" : "15px",
            marginBottom: "20px",
          }}
        >
          {!isMobile ? (
            <>
              <GrClose fontSize={24} onClick={onClose} />
              <img src={Images.Logo} />
            </>
          ) : (
            <>
              <img src={Images.Logo} />
            </>
          )}
        </div>
        <div className="mobile-navbar">
          <ul>
            <li
              className={title === "dashboard" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("dashboard");
              }}
            >
              Dashboard
            </li>
            <li
              className={title === "user-management" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("user-management");
              }}
            >
              User Management
            </li>
            <li
              className={title === "provider-management" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("provider-management");
              }}
            >
              Provider Management
            </li>
            <li
              className={title === "service-management" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("service-management");
              }}
            >
              Service Management
            </li>
            <li
              className={title === "reports-management" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("reports-management");
              }}
            >
              Reports Management
            </li>
            <li
              className={title === "promo-management" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("promo-management");
              }}
            >
              Promo Management
            </li>
            
            {!isMobile && (
              <>
                <li
                  className={title === "contact-support" && "selected"}
                  onClick={() => {
                    setVisible(false);
                    navigate("contact-support");
                  }}
                >
                  Contact Support
                </li>
                {adminRole === 2 && (
                  <li
                    className={title === "sub-admin-management" && "selected"}
                    onClick={() => {
                      setVisible(false);
                      navigate("sub-admin-management");
                    }}
                  >
                    Sub-Admin
                  </li>
                )}
              </>
            )}
          </ul>
          {!isMobile && (
            <Button.Basic
              variant="outlined"
              style={{
                backgroundColor: Colors.Black,
                textTransform: "capitalize",
                color: Colors.White,
                width: "100%",
                borderRadius: 5,
                borderColor: Colors.Transparent,
                fontFamily: `${Fonts["Poppins-Regular"]}`,
              }}
              startIcon={<img src={Images.LogoutIcon} />}
              onClick={handleLogout}
              text="Logout"
              size="large"
            />
          )}
        </div>
      </Drawer>
    </>
  );

  return (
    <div className="nav-header-container">
      <div className="upper-navbar">
        <div>
          <img src={Images.DashboardLogo} />
        </div>
        <div className="site-layout-background-right">
          <div
            className="profile"
            style={{
              width:
                adminRole === 2
                  ? isTablet
                    ? "max-content"
                    : isMobile
                    ? "max-content"
                    : "50px"
                  : isTablet
                  ? "max-content"
                  : isMobile
                  ? "max-content"
                  : "50px",
            }}
          >
            {isMobile ? (
              <>
                {isTablet ? (
                  <span className="mr-10">{`Welcome Back, ${
                    adminRole === 2 ? "Admin!" : "Sub Admin!"
                  }`}</span>
                ) : null}
                <img
                  src={
                    user.user !== undefined
                      ? authUser?.user?.imageThumbUrl
                      : userLS?.user?.imageThumbUrl
                  }
                  alt=""
                />
                <span className="mx-10">
                  {user.user !== undefined
                    ? showName(user?.user?.fullName)
                    : showName(userLS?.user?.fullName)}
                </span>
                <Dropdown
                  overlay={<ProfileDropDown />}
                  style={{ top: "65px !important" }}
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img src={Images.DropdownIcon} height={5} alt="disappear" />
                  </a>
                </Dropdown>
              </>
            ) : (
              <Hamburger placement={"right"} />
            )}
          </div>
        </div>
      </div>
      <div className="lower-navbar">
        <ul>
          <li
            className={title === "dashboard" && "selected"}
            onClick={() => navigate("dashboard")}
          >
            Dashboard
          </li>
          <li
            className={title === "user-management" && "selected"}
            onClick={() => navigate("user-management")}
          >
            User Management
          </li>
          <li
            className={title === "provider-management" && "selected"}
            onClick={() => navigate("provider-management")}
          >
            
            Provider Management {count ?
            <Tooltip placement="top" title={'Action Required On Documents'}>
             <img src={Images.Warning} alt="Image" width={'10%'} height={'10%'} style={{marginRight: "-22px"}} /> 
             </Tooltip>
             : ''}
            
          </li>
          <li
            className={title === "service-management" && "selected"}
            onClick={() => navigate("service-management")}
          >
            Service Management
          </li>
          <li
            className={title === "reports-management" && "selected"}
            onClick={() => navigate("reports-management")}
          >
            Reports Management
          </li>
          <li
            className={title === "promo-management" && "selected"}
            onClick={() => navigate("promo-management")}
          >
            Promo Management
          </li>
        </ul>
        {isMobile && (
          <>
            <Hamburger placement={"left"} />
            <Button.Basic
              variant="outlined"
              style={{
                backgroundColor: Colors.Black,
                textTransform: "capitalize",
                color: Colors.White,
                width: "100%",
                borderRadius: 5,
                borderColor: Colors.Transparent,
                fontFamily: `${Fonts["Poppins-Regular"]}`,
              }}
              startIcon={<img src={Images.LogoutIcon} />}
              onClick={handleLogout}
              text="Logout"
              containerStyle={{ margin: "10px 0" }}
              size="large"
            />
          </>
        )}
      </div>
    </div>
  );
};
export default NavHeader;
