import { connect } from "react-redux";
import React, { Component } from "react";

import { Colors, Utils } from "./config";
import { Loader, Popup } from "./components";
import AuthRoutes from "./config/routes/AuthRoutes";

import "./App.css";

const { saveSnackbarRef } = Utils;

class App extends Component {
  componentDidMount() {
    // notification.resgisterNotifications()
  }

  render() {
    return (
      <div>
        <AuthRoutes />
        <Popup.Snackbar ref={(ref) => saveSnackbarRef(ref)} />

        {this.props.loader ? (
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Colors.BlackOpacity(0.7),
            }}
          >
            <Loader.Circular sx={{ color: Colors.WhiteOpacity(0.7) }} />
          </div>
        ) : null}

        {/* <ComponentsSample /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.auth.isLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
