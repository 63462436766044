import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  REMOVE_AUTH_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SIGNIN,
  SIGNIN_FAILURE,
  SIGNIN_SUCCESS,
  VERIFY_CODE,
  VERIFY_CODE_FAILURE,
  VERIFY_CODE_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  isLoggedIn: localStorage.getItem("authUser") ? true : false,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {},
  error: null,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNIN:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case SIGNIN_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        isLoading: false,
        isLoggedIn: true,
      };
      break;
    case SIGNIN_FAILURE:
      state = {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: action.payload,
      };
      break;

    case LOGOUT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case LOGOUT_SUCCESS:
      state = {
        user: {},
        isLoading: false,
        isLoggedIn: false,
      };
      break;
    case LOGOUT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: action.payload?.message,
      };
      break;
    case FORGET_PASSWORD:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case FORGET_PASSWORD_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };
      break;
    case VERIFY_CODE:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case VERIFY_CODE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case VERIFY_CODE_FAILURE:
      state = {
        ...state,
        isLoading: true,
        error: action.payload.message,
      };
      break;
    case RESET_PASSWORD:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case RESET_PASSWORD_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };
      break;
    case REMOVE_AUTH_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: null,
      };
    case LOADER_TRUE:
      state = {
        ...state,
        loader: true,
        error: null,
      };
      break;

    case LOADER_FALSE:
      state = {
        ...state,
        loader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
