import { Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { withTheme } from "styled-components";
import propTypes from "prop-types";
import React from "react";

class renderLineChart extends React.Component {
  state = {
    updateXAxis: false,
  };

  componentWillUpdate(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ updateXAxis: !this.state.updateXAxis });
    }
  }
  renderLegend = (props) => {
    const { payload } = props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {payload
          .filter((item) => item.dataKey !== "name")

          // item =>

          .map(
            (entry, index) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      borderRadius: 15,
                      width: 15,
                      height: 15,
                      backgroundColor: this.props.lineColor[entry?.dataKey],
                      margin: 20,
                    }}
                  ></div>
                  <span
                    style={{ color: "#2A2A2A", textTransform: "capitalize" }}
                  >
                    {entry.value.split("_").join(" ")}
                  </span>
                </div>
              );
            }
            // key={`item-${index}`}>{entry.value}
          )}
      </div>
    );
  };

  render() {
    var obj;
    if (this.props.data[0]) obj = Object.keys(this.props.data[0]);
    obj.shift();

    function renderTooltip({ payload }) {
      return (
        <>
          <div
            style={{
              width: 200,
              display: "flex",
              flexDirection: "column",
              border: "1px solid lightgray",
              padding: "10px 10px 0px 10px",
              backgroundColor: "white",
              textTransform: "capitalize",
              color: "#05031A",
            }}
          >
            {payload.map((entry, index) => (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <span style={{ color: this.props.lineColor[entry.dataKey] }}>
                    {entry.dataKey}: {entry.value}
                  </span>
                </div>
                {payload.length - 1 === index && (
                  <p>Date: {entry.payload.name}</p>
                )}
              </>
            ))}
          </div>
        </>
      );
    }

    return (
      <div>
        {/* <LineChart width={this.props.width} height={this.props.height} data={this.props.data.length !== 0 ? this.props.data : [{ empty: 0 }]} margin={this.props.margin}> */}
        <LineChart
          width={this.props.width}
          height={this.props.height}
          data={this.props.data.length !== 0 ? this.props.data : [{ empty: 0 }]}
          margin={this.props.margin}
        >
          <XAxis dataKey="name" />
          <YAxis />
          {obj && !this.props.loading
            ? obj.map((value, index) => {
                //

                return (
                  <Line
                    key={index.toString()}
                    type={this.props.lineType}
                    dataKey={obj[index]}
                    stroke={this.props.lineColor[value]}
                    strokeWidth={3}
                  />
                );
              })
            : null}
          {/* {this.props.isCartReq ? (
            <CartesianGrid
              dataKey={'basicUsers'}
              stroke={this.props.cartStroke}
              strokeDasharray={this.props.cartDash}
            />
          ) : null}
          

          {this.state.updateXAxis ? (
            <XAxis
              key={100}
              dataKey={obj && !this.props.loading ? obj[1] : null}
              allowDuplicatedCategory={false}
            />
          ) : (
            <XAxis
              key={200}
              dataKey={obj && !this.props.loading ? obj[1] : null}
              allowDuplicatedCategory={false}
            />
          )}
          <YAxis domain={[0, 50]} /> */}
          {this.props.isTooltip ? (
            <Tooltip content={renderTooltip.bind(this)} />
          ) : null}
          {this.props.isLegend && (
            <Legend
              verticalAlign={this.props.legendsAlignment}
              height={36}
              content={this.renderLegend}
            />
          )}
        </LineChart>
      </div>
    );
  }
}

renderLineChart.defaultProps = {
  width: "100%",
  height: 300,
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  lineType: "monotone",
  isCartReq: false,
  cartStroke: "#f0f0f0",
  cartDash: 5,
  isTooltip: true,
};

renderLineChart.propTypes = {
  data: propTypes.array.isRequired,
  lineColor: propTypes.array.isRequired,
};
export default withTheme(renderLineChart);
