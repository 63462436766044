import {
  GET_ALL_SERVICE_PROVIDERS,
  GET_ALL_SERVICE_PROVIDERS_FAILURE,
  GET_ALL_SERVICE_PROVIDERS_SUCCESS,
  GET_SERVICE_PROVIDER_DETAILS,
  GET_SERVICE_PROVIDER_DETAILS_FAILURE,
  GET_SERVICE_PROVIDER_DETAILS_SUCCESS,
  SEND_SERVICE_PROVIDER_EMAIL,
  SEND_SERVICE_PROVIDER_EMAIL_FAILURE,
  SEND_SERVICE_PROVIDER_EMAIL_SUCCESS,
  UPDATE_SERVICE_PROVIDER_STATUS,
  UPDATE_SERVICE_PROVIDER_STATUS_FAILURE,
  UPDATE_SERVICE_PROVIDER_STATUS_SUCCESS,
  SERVICE_PROVIDER_BUSINESS_STATUS,
  SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS,
  SERVICE_PROVIDER_BUSINESS_STATUS_FAILURE
  
} from "../constants";

const initialState = {
  count: 0,
  serviceProviders: [],
  serviceProvider: {},
  error: null,
  isLoading: false,
};

export default function ServiceProvidersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SERVICE_PROVIDERS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_SERVICE_PROVIDERS_SUCCESS:
      state = {
        ...state,
        serviceProviders: action.payload.data.serviceProviders,
        count: action.payload.data.count,
        isLoading: false,
      };
      break;
    case GET_ALL_SERVICE_PROVIDERS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case UPDATE_SERVICE_PROVIDER_STATUS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case UPDATE_SERVICE_PROVIDER_STATUS_SUCCESS:
      console.log("here is the state current====",state)
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case UPDATE_SERVICE_PROVIDER_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case SEND_SERVICE_PROVIDER_EMAIL:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case SEND_SERVICE_PROVIDER_EMAIL_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case SEND_SERVICE_PROVIDER_EMAIL_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case GET_SERVICE_PROVIDER_DETAILS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_SERVICE_PROVIDER_DETAILS_SUCCESS:
      state = {
        ...state,
        serviceProvider: action.payload,
        isLoading: false,
      };
      break;
    case GET_SERVICE_PROVIDER_DETAILS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };

      case SERVICE_PROVIDER_BUSINESS_STATUS:
        state = {
          ...state,
          isLoading: true,
        };
        break;
      case SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS:
        state = {
          ...state,
          isLoading: false,
        };
        break;
      case SERVICE_PROVIDER_BUSINESS_STATUS_FAILURE:
        state = {
          ...state,
          isLoading: false,
          error: action.payload
        };

      break;
    default:
      break;
  }
  return state;
}
