// dev:
// var baseUrl = "https://mobrage.5stardesigners.net:6020/dev/mobrage/api/v1";

//qa:
// var baseUrl = "https://mobrage.5stardesigners.net:6021/qa/mobrage/api/v1"

//uat:
// var baseUrl = "https://mobrage.5stardesigners.net:3015/uat/mobrage/api/v1";


//production:
var baseUrl = "https://api.mobrage.com:5001/prod/mobrage/api/v1"

const configVariables = {
  baseUrl,
};

const constantVariables = {
  TempUser: {
    name: "David Smith",
    email: "david@yopmail.com",
    pass: "asd123",
    userId: 12,
  },
};

export default {
  ...configVariables,
  ...constantVariables,
};
