import * as React from "react";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const IconField = ({
  htmlFor,
  inputLabel,
  id,
  type,
  value,
  label,
  variant,
  onChange,
  onMouseDown,
  startIcon,
  endIcon,
  disabled,
  error,
  helperText,
  onClick,
  containerStyle,
  endAdornment,
  fieldStyle,
  sx,
  ...props
}) => {
  return (
    <div style={containerStyle}>
      <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
        <InputLabel htmlFor={htmlFor}>{inputLabel}</InputLabel>
        <Input
          id={id} //standard-adornment-password
          type={type}
          label={label}
          variant={variant}
          value={value}
          onChange={onChange}
          disabled={disabled}
          startAdornment={startIcon}
          endAdornment={endIcon}
          style={fieldStyle}
          sx={sx}
          {...props}
        />
      </FormControl>
    </div>
  );
};

export default IconField;
