import { Col, Input, Pagination, Row, Image } from "antd";
import { HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import { BsThreeDotsVertical } from "react-icons/bs";

import "./style.css";
import tableAccepted from "../../assets/images/tableAccepted.png";
import tableRejected from "../../assets/images/tableRejected.png";
import tablePending from "../../assets/images/tablePending.png";
import tableMissed from "../../assets/images/tableMissed.png";
import tableExpired from "../../assets/images/tableExpired.png";
import useMedia from "use-media";
import AcceptModal from "../../components/Modal/AcceptModal";
import { Popover } from "antd";

import {
  Box,
  Button,
  Loader,
  Modal,
  Switch,
  Table,
  Text,
  TextField,
} from "../../components";
import { Colors, Fonts, Images } from "../../config";
import {
  AdManagementAction,
  ServiceProvidersAction,
} from "../../store/actions";
import "./style.css";
import EditAdModal from "../../components/Modal/EditAdModal";

const { TextArea } = Input;

function AdManagement() {
  const isTablet = useMedia({ minWidth: "768px" });
  const isMobile = useMedia({ minWidth: "550px" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");
  const [acceptModal, setAcceptModal] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [pending, setPending] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [actionPopOver, setActionPopOver] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState({});
  const ads = useSelector((state) => state?.adManagementReducer?.ads);
  const adsLoader = useSelector(
    (state) => state?.adManagementReducer?.adsLoader
  );

  console.log("SELECTED USER", data);

  const adsCount = useSelector((state) => state?.adManagementReducer?.adsCount);

  const updateAdLoader = useSelector(
    (state) => state?.adManagementReducer?.updateAdLoader
  );

  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    search: "",
    status: "",
    confirmationModal: false,
    messageModal: false,
    isDelete: false,
    isFreeze: false,
    editModal: false,
  });

  const {
    limit,
    offset,
    search,
    status,
    messageModal,
    confirmationModal,
    isDelete,
    isFreeze,
    editModal,
  } = state;

  const handleStatusFilter = (checked, id) => {
    setState({
      ...state,
      status: checked,
    });
  };

  const handleSearchUsers = (e) => {
    setState({
      ...state,
      search: e.target.value.trimStart(),
    });
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  useEffect(() => {
    dispatch(AdManagementAction.getAdsRequest(state));
  }, [search, status, offset]);

  const content = (
    <Row>
      {pending ? null : (
        <Col
          span={24}
          style={{
            cursor: "pointer",
            borderBottom: "solid black 1px",
            color: "white",
            height: "30px",
          }}
          onClick={() => {
            setState({
              ...state,
              confirmationModal: true,
              isFreeze: true,
              isDelete: false,
            });
            setData({ ...data, popOpen: false })
          }}
        >
          {data?.status == 6 ? "Unfreeze" : "Freeze"}
        </Col>
      )}

      <Col
        span={24}
        style={{
          cursor: "pointer",
          borderBottom: "solid black 1px",
          color: "white",
          height: "30px",
        }}
        onClick={() => {
          setState({
            ...state,
            editModal: true,
            confirmationModal: false,
            messageModal: false
          })
          setData({ ...data, popOpen: false })
        }
        }
      >
        Edit
      </Col>
      <Col
        span={24}
        style={{
          cursor: "pointer",
          borderBottom: "solid black 1px",
          color: "white",
          height: "30px",
        }}
        onClick={() => {
          setState({
            ...state,
            confirmationModal: true,
            editModal: false,
            messageModal: false,
            isDelete: true,
            isFreeze: false,
          })
          setData({ ...data, popOpen: false })
        }}
      >
        Delete
      </Col>
      <Col
        span={24}
        style={{
          cursor: "pointer",
          borderBottom: "solid black 1px",
          color: "white",
          height: "30px",
        }}
        onClick={() => {
          setState({
            ...state,
            messageModal: true,
            editModal: false,
            confirmationModal: false
          })
          setData({ ...data, popOpen: false })
        }
        }
      >
        Send Message
      </Col>
    </Row>
  );

  const handleSendEmail = () => {
    if (subject.length === 0 || subject.trim() === "") {
      setSubjectError("Please enter subject");
    } else if (message.length === 0 || message.trim() === "") {
      setSubjectError("");
      setMessageError("Please enter message");
    } else {
      setSubjectError("");
      setMessageError("");
      const payload = {
        subject: subject.trim(),
        message: message.trim(),
      };
      dispatch(
        ServiceProvidersAction.sendServiceProviderEmail(
          data?.user?.id,
          payload,
          () => {
            setState({ ...state, messageModal: false });
            setSubject("");
            setMessage("");
          }
        )
      );
    }
  };

  return (
    <>
      <Box>
        <Row justify="space-between">
          <Col className="flex-start">
            <Text.Heading
              fontSize={24}
              fontFamily={Fonts["Poppins-SemiBold"]}
              color={Colors.Black}
              fontWeight={600}
              text={"Ad Management"}
            />
          </Col>
          <Col
            md={15}
            sm={isTablet ? 19 : 24}
            className={isTablet ? "flex-end" : "flex-row-start mt-20"}
          >
            <Row className={isMobile ? "flex-center" : "flex-column"}>
              <Col span={10}>
                <TextField.SelectField
                  placeholder="Select"
                  size="large"
                  id="provider-management-select"
                  type="text"
                  disabled={false}
                  required={false}
                  value={state?.status ? state?.status : "All Status"}
                  label=""
                  defaultValue="All Status"
                  error={false}
                  helperText=""
                  onChange={handleStatusFilter}
                  data={[
                    {
                      label: "All Status",
                      value: "",
                    },
                    {
                      label: "Active",
                      value: "1",
                    },
                    {
                      label: "Pending",
                      value: "2",
                    },
                    {
                      label: "Rejected",
                      value: "3",
                    },
                    {
                      label: "Missed",
                      value: "4",
                    },
                    {
                      label: "Expired",
                      value: "5",
                    },
                  ]}
                />
              </Col>
              <Col span={isMobile ? 14 : 20} className={!isMobile && "mt-5"}>
                <TextField.CustomInput
                  placeholder="Search"
                  size="large"
                  id="provider-management-search"
                  type="text"
                  disabled={false}
                  required={false}
                  value={state?.search}
                  label=""
                  defaultValue=""
                  error={false}
                  helperText=""
                  onChange={handleSearchUsers}
                  prefix={<HiOutlineSearch color={Colors.dropdownTextColor} />}
                  bgColor={Colors.dropdownBgColor}
                  color={Colors.dropdownTextColor}
                  className="user-search-field"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" className="mt-20">
          <Col span={24}>
            {adsLoader ? (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTp: "20px",
                }}
              >
                <Loader.Circular />
              </Col>
            ) : (
              <>
                <Table.AntDesignTable
                  className="user-table"
                  columns={[
                    {
                      title: "Image",
                      dataIndex: "user",
                      key: "user",
                      render: (text) => (
                        <img
                          src={text?.imageThumbUrl}
                          alt="user"
                          className="service-user-img"
                        />
                      ),
                    },
                    {
                      title: "Full Name",
                      dataIndex: "user",
                      key: "user",
                      render: (text) => <span>{text?.fullName}</span>,
                    },
                    {
                      title: "Email",
                      dataIndex: "user",
                      key: "user",
                      render: (text) => <span>{text?.email}</span>,
                    },
                    {
                      title: "Date",
                      dataIndex: "date",
                      key: "date",
                      render: (text) => (
                        <span>{moment(text).format("YYYY-MM-DD")}</span>
                      ),
                    },
                    {
                      title: "Ad Image",
                      dataIndex: "imageThumbUrl",
                      key: "imageThumbUrl",
                      render: (text) => (
                        <Image
                          className="service-user-img"
                          src={text}
                          preview={{
                            src: text,
                          }}
                        />
                      ),
                    },
                    {
                      title: "Redirect Url",
                      dataIndex: "redirectionUrl",
                      key: "redirectionUrl",
                      render: (text) => (
                        <a href={`https://${text}`} target="_blank">
                          {text}
                        </a>
                      ),
                    },
                    {
                      title: "Status",
                      dataIndex: "",
                      key: "",
                      render: (text, record) =>
                        moment().format("YYYY-MM-DD") >
                          moment(record?.date).format("YYYY-MM-DD") &&
                          !record.isResponded ? (
                          <div
                            className="user-status"
                            style={{
                              backgroundColor: "red",
                            }}
                          >
                            Missed
                          </div>
                        ) : moment().format("YYYY-MM-DD") >
                          moment(record?.date).format("YYYY-MM-DD") &&
                          (record?.status === 1 || record?.status === 3 || record?.status === 6)
                          ?
                          <div
                            className="user-status"
                            style={{
                              backgroundColor: "grey",
                            }}
                          >
                            Expired
                          </div>
                          : record?.status === 1 &&
                            moment().format("YYYY-MM-DD") ===
                            moment(record?.date).format("YYYY-MM-DD")
                            ? (
                              <div
                                className="user-status"
                                style={{
                                  backgroundColor: "#FAB700",
                                }}
                              >
                                Live
                              </div>
                            ) : record?.status === 1 &&
                              moment().format("YYYY-MM-DD") <=
                              moment(record?.date).format("YYYY-MM-DD") ? (
                              <div
                                className="user-status"
                                style={{
                                  backgroundColor: "green",
                                }}
                              >
                                Approved
                              </div>
                            ) : record?.status === 2 &&
                              moment().format("YYYY-MM-DD") <=
                              moment(record?.date).format("YYYY-MM-DD") ? (
                              <div
                                className="user-status"
                                style={{
                                  backgroundColor: "blue",
                                }}
                              >
                                Pending
                              </div>
                            ) : record?.status === 3 &&
                              moment().format("YYYY-MM-DD") <=
                              moment(record?.date).format("YYYY-MM-DD") &&
                              record.isResponded ? (
                              <div
                                className="user-status"
                                style={{
                                  backgroundColor: "Orange",
                                }}
                              >
                                Rejected
                              </div>
                            ) : (
                              <div
                                className="user-status"
                                style={{
                                  backgroundColor: "#ADD8E6",
                                }}
                              >
                                Freeze
                              </div>
                            ),
                    },

                    {
                      title: "Action",
                      dataIndex: "status",
                      key: "status",
                      render: (text, record) =>
                        record?.status !== 2 ? (
                          <Row justify="center">
                            <Col>
                              <Popover
                                placement="bottomLeft"
                                content={content}
                                trigger="click"
                                visible={data.id === record.id && !!data.popOpen}
                              >
                                <BsThreeDotsVertical
                                  onClick={() => {
                                    setData({ ...record, popOpen: !!!data.popOpen });
                                    setPending(false)
                                  }}
                                />
                              </Popover>
                            </Col>
                          </Row>
                        ) : record?.isResponded ||
                          (moment().format("YYYY-MM-DD") >
                            moment(record?.date).format("YYYY-MM-DD") &&
                            !record.isResponded) ? null : (
                          <Row justify="center">
                            <Col span={24}>
                              <Row gutter={[10, 0]} align="middle">
                                <Col span={8}>
                                  <img
                                    width="30px"
                                    height="30px"
                                    src={tableRejected}
                                    onClick={() => {
                                      setAcceptModal(true);
                                      setId(record.id);
                                    }}
                                  />
                                </Col>
                                <Col span={8}>
                                  <img
                                    width="30px"
                                    height="30px"
                                    src={tableAccepted}
                                    onClick={() => {
                                      dispatch(
                                        AdManagementAction.updateAdRequest(
                                          {
                                            id: record?.id,
                                            status: 1,
                                          },
                                          () => {
                                            dispatch(
                                              AdManagementAction.getAdsRequest({
                                                limit,
                                                offset,
                                              })
                                            );
                                          }
                                        )
                                      );
                                    }}
                                  />
                                </Col>
                                <Col span={8}>
                                  <Popover
                                    placement="bottomLeft"
                                    content={content}
                                    trigger="click"
                                    visible={data.id === record.id && !!data.popOpen}
                                  >
                                    <BsThreeDotsVertical
                                      onClick={() => {
                                        setData({ ...record, popOpen: !!!data.popOpen });
                                        setPending(true)
                                      }}
                                    />
                                  </Popover>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ),
                    },
                  ]}
                  data={ads}
                  pagination={true}
                  rowKey="key"
                  loading={false}
                />
                <Row justify="center" className="mt-20">
                  <Col>
                    <Pagination
                      responsive="true"
                      showLessItems="true"
                      pageSize={10}
                      current={page}
                      total={adsCount}
                      onChange={handlePaginationChange}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Box>
      {showModal && (
        <Modal.AntDesignModal
          title=""
          centered={true}
          isModalVisible={showModal}
          handleCancel={() => {
            setShowModal(false);
            setSubjectError("");
            setMessageError("");
            setSubject("");
            setMessage("");
          }}
        >
          <Row justify="center">
            <Col>
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={"Send Message"}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24} className="flex-row mt-20">
              <Col>
                <img
                  src={data?.user?.imageThumbUrl}
                  alt=""
                  className="service-user-email-img"
                />
              </Col>
              <Col>
                <div className="flex-column ml-20">
                  <Text.Heading
                    fontSize={18}
                    fontFamily={Fonts["Poppins-Bold"]}
                    color={Colors.Black}
                    fontWeight={600}
                    text={
                      data?.user?.fullName?.length > 40
                        ? `${data?.user?.fullName?.slice(0, 40)}...`
                        : data?.user?.fullName
                    }
                    textAlign="left"
                  />
                  <Text.Heading
                    fontSize={16}
                    fontFamily={Fonts["Poppins-Medium"]}
                    color={Colors.Black}
                    fontWeight={500}
                    text={
                      data?.user?.email?.length > 40
                        ? `${data?.user?.email?.slice(0, 40)}...`
                        : data?.user?.email
                    }
                    textAlign="left"
                  />
                </div>
              </Col>
            </Col>
          </Row>
          <Row className="mt-20">
            <Col span={24}>
              <TextField.CustomInput
                label="Subject:"
                placeholder="Email Subject"
                size="large"
                type="text"
                className="user-subject-field"
                onChange={(e) => setSubject(e.target.value)}
              />
            </Col>
            {subjectError && (
              <Col>
                <Text.ErrorText
                  fontSize={14}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.Red}
                  fontWeight={500}
                  text={subjectError}
                  textAlign="left"
                />
              </Col>
            )}
          </Row>
          <Row className="my-20">
            <Col span={24}>
              <label className="text-label">Message:</label>
              <TextArea
                placeholder="Write your message here"
                rows={5}
                size="large"
                type="text"
                className="user-subject-field"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Col>
            {messageError && (
              <Col>
                <Text.ErrorText
                  fontSize={14}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.Red}
                  fontWeight={500}
                  text={messageError}
                  textAlign="left"
                />
              </Col>
            )}
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Button.Basic
                onClick={handleSendEmail}
                text={"Send"}
                className="user-msg-btn"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
      {acceptModal && (
        <Modal.AcceptModal
          heading="Reason for Rejection"
          centered={true}
          limit={limit}
          offset={offset}
          id={id}
          isModalVisible={acceptModal}
          handleCancel={() => {
            setAcceptModal(false);
            // setSubjectError("");
            // setMessageError("");
            // setSubject("");
            // setMessage("");
          }}
        ></Modal.AcceptModal>
      )}
      {messageModal && (
        <Modal.AntDesignModal
          title=""
          isModalVisible={messageModal}
          handleCancel={() => {
            setState({ ...state, messageModal: false });
            setSubjectError("");
            setMessageError("");
            setSubject("");
            setMessage("");
          }}
        >
          <Row justify="center">
            <Col>
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={"Send Message"}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="flex-row mt-20">
              <Col>
                <img
                  src={data?.user?.imageThumbUrl}
                  alt=""
                  className="service-user-email-img"
                />
              </Col>
              <Col>
                <div className="flex-column ml-20">
                  <Text.Heading
                    fontSize={18}
                    fontFamily={Fonts["Poppins-Bold"]}
                    color={Colors.Black}
                    fontWeight={600}
                    text={
                      data?.user?.fullName.length > 40
                        ? `${data?.user?.fullName.slice(0, 40)}...`
                        : data?.user?.fullName
                    }
                    textAlign="left"
                  />
                  <Text.Heading
                    fontSize={16}
                    fontFamily={Fonts["Poppins-Medium"]}
                    color={Colors.Black}
                    fontWeight={500}
                    text={data?.user?.email}
                    textAlign="left"
                  />
                </div>
              </Col>
            </Col>
          </Row>
          <Row className="mt-20">
            <Col span={24}>
              <TextField.CustomInput
                label="Subject:"
                color={Colors.Black}
                placeholder="Email Subject"
                value={subject}
                size="large"
                type="text"
                className="user-subject-field"
                onChange={(e) => setSubject(e.target.value)}
              />
            </Col>
            {subjectError && (
              <Col>
                <Text.ErrorText
                  fontSize={14}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.Red}
                  fontWeight={500}
                  text={subjectError}
                  textAlign="left"
                />
              </Col>
            )}
          </Row>
          <Row className="my-20">
            <Col span={24}>
              <label className="service-provider-msg-label">Message:</label>
              <TextArea
                placeholder="Write your message here"
                value={message}
                rows={5}
                size="large"
                type="text"
                className="service-provider-msg-input"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Col>
            {messageError && (
              <Col>
                <Text.ErrorText
                  fontSize={14}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.Red}
                  fontWeight={500}
                  text={messageError}
                  textAlign="left"
                />
              </Col>
            )}
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Button.Basic
                onClick={handleSendEmail}
                text={"Send"}
                className="service-provider-email-btn"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
      {confirmationModal && (
        <Modal.ConfirmationModal
          title={""}
          isModalVisible={confirmationModal}
          heading={`Are you sure you want to ${isFreeze ?
            data?.status === 6 ? "Unfreeze" : "Freeze"
            : "Delete"
            } the Ad? `}
          handleCancel={() => setState({ ...state, confirmationModal: false })}
          handleDelete={
            isDelete
              ? () =>
                dispatch(
                  AdManagementAction.deleteAdRequest({ id: data?.id }, () => {
                    setState({ ...state, confirmationModal: false });
                    dispatch(
                      AdManagementAction.getAdsRequest({
                        limit,
                        offset,
                      })
                    );
                  })
                )
              : () =>
                dispatch(
                  AdManagementAction.updateAdRequest(
                    {
                      id: data?.id,
                      status: data?.status === 6 ? 1 : 6,
                    },
                    () => {
                      setState({ ...state, confirmationModal: false });
                      dispatch(
                        AdManagementAction.getAdsRequest({
                          limit,
                          offset,
                        })
                      );
                    }
                  )
                )
          }
        ></Modal.ConfirmationModal>
      )}
      {editModal && (
        <EditAdModal
          title={"Edit Ad"}
          isUpdate
          data={data}
          isModalVisible={editModal}
          limit={limit}
          offset={offset}
          handleCancel={() => setState({ ...state, editModal: false })}
        ></EditAdModal>
      )}
    </>
  );
}

export default AdManagement;
