import { Col, Input, Pagination, Row } from "antd";
import { HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router";
import useMedia from "use-media";
import {
  Box,
  Button,
  Loader,
  Modal,
  Switch,
  Table,
  Text,
  TextField,
} from "../../components";
import { Colors, Fonts, Images } from "../../config";
import { ServiceProvidersAction } from "../../store/actions";
import "./index.css";

const { TextArea } = Input;

function ProviderManagement() {
  const isTablet = useMedia({ minWidth: "768px" });
  const isMobile = useMedia({ minWidth: "550px" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const serviceProviders = useSelector((state) => state.serviceProviders);
  
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    search: "",
    status: ""
  });

  console.log("location in main",location.state)

  const handleStatusFilter = (checked, id) => {
    setState({
      ...state,
      status: checked,
    });
  };

  const handleSearchUsers = (e) => {
    setState({
      ...state,
      search: e.target.value.trimStart(),
      offset: 0
    });
    // handlePaginationChange(1)
    setPage(1)

  };

  const handleChangeStatus = (id, status) => {
    console.log("handle change clicked")
    const payload = {
      status: status,
    };
    dispatch(
      ServiceProvidersAction.updateServiceProviderStatus(id, payload, () => {
        dispatch(ServiceProvidersAction.getAllServiceProviders(state));
      })
    );
  };

  const handleSendEmail = () => {
    if (subject.length === 0 || subject.trim() === "") {
      setSubjectError("Please enter subject");
    } else if (message.length === 0 || message.trim() === "") {
      setSubjectError("");
      setMessageError("Please enter message");
    } else {
      setSubjectError("");
      setMessageError("");
      const payload = {
        subject: subject?.trim(),
        message: message?.replace(/\n|\r/g, "<br/><br/>"),
      };
      dispatch(
        ServiceProvidersAction.sendServiceProviderEmail(
          selectedUser.id,
          payload,
          () => {
            setShowModal(false);
            setSubject("");
            setMessage("");
          }
        )
      );
    }
  };

  const handleNavigateUserDetails = (record) => {
    navigate("/provider-management/details", {
      // state: {
      //   id: record.id,
      // },
      state:{state,id:record.id,page}
    });
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  useEffect(() => {

    if(location.state !== null){
      // const {limit,offset} = location?.state?.locationstate;
      const pagenumber = location?.state?.locationpage;
      // setState({...state,limit:limit,offset:offset})
      // dispatch(ServiceProvidersAction.getAllServiceProviders({...state,limit:limit,offset:offset}))
      handlePaginationChange(pagenumber)
      navigate(location.pathname,{})
    }

    else{
      dispatch(ServiceProvidersAction.getAllServiceProviders(state));
    }

  }, [state]);

  return (
    <>
      <Box>
        <Row justify="space-between">
          <Col className="flex-start">
            <Text.Heading
              fontSize={24}
              fontFamily={Fonts["Poppins-SemiBold"]}
              color={Colors.Black}
              fontWeight={600}
              text={"Service Provider Management"}
            />
          </Col>
          <Col
            md={12}
            sm={isTablet ? 19 : 24}
            className={isTablet ? "flex-end" : "flex-row-start mt-20"}
          >
            <Row className={isMobile ? "flex-center" : "flex-column"}>
              <Col span={10}>
                <TextField.SelectField
                  placeholder="Select"
                  size="large"
                  id="provider-management-select"
                  type="text"
                  disabled={false}
                  required={false}
                  value={state.status ? state.status : "All Status"}
                  label=""
                  defaultValue="All Status"
                  error={false}
                  helperText=""
                  onChange={handleStatusFilter}
                  data={[
                    {
                      label: "All Status",
                      value: "",
                    },
                    {
                      label: "Active",
                      value: "1",
                    },
                    {
                      label: "Banned",
                      value: "2",
                    },
                    {
                      label: "Pending",
                      value: "3",
                    },
                  ]}
                />
              </Col>
              <Col span={isMobile ? 14 : 20} className={!isMobile && "mt-5"}>
                <TextField.CustomInput
                  placeholder="Search"
                  size="large"
                  id="provider-management-search"
                  className="user-search-field"
                  type="text"
                  disabled={false}
                  required={false}
                  value={state.search}
                  label=""
                  defaultValue=""
                  error={false}
                  helperText=""
                  onChange={handleSearchUsers}
                  prefix={<HiOutlineSearch color={Colors.dropdownTextColor} />}
                  bgColor={Colors.dropdownBgColor}
                  color={Colors.dropdownTextColor}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" className="mt-20" style={{fontSize:'13px'}}>
          <Col span={24}>
            {serviceProviders.isLoading ? (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTp: "20px",
                }}
              >
                <Loader.Circular />
              </Col>
            ) : (
              <>
                <Table.AntDesignTable
                  className="user-table"
                  columns={[
                    {
                      title: "Id",
                      dataIndex: "userDetail",
                      key: "userDetail",
                      render: (text, record) => {
                        return(
                          <span>{text.counter ? text.counter : '-'}</span>
                        )
                      },
                    },
                    {
                      title: "Image",
                      dataIndex: "imageThumbUrl",
                      key: "imageThumbUrl",
                      render: (text, record) => (
                        <img
                          onClick={() => {
                            handleNavigateUserDetails(record);
                          }}
                          className="service-user-img"
                          src={record.imageThumbUrl}
                          width={40}
                          alt="user"
                        />
                      ),
                    },
                    {
                      title: "Full Name",
                      dataIndex: "fullName",
                      key: "fullName",
                      render: (text, record) => {
                        return (
                          <span
                            onClick={() => {
                              handleNavigateUserDetails(record);
                            }}
                          >
                            {text.length > 20
                              ? `${text.slice(0, 20)}...`
                              : text}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Email",
                      dataIndex: "email",
                      key: "email",
                      render: (text, record) => {
                        return (
                          <span
                            onClick={() => {
                              handleNavigateUserDetails(record);
                            }}
                            className="service-email"
                          >
                            {text}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Phone Number",
                      dataIndex: "phoneNo",
                      key: "phoneNo",
                      render: (text, record) => {
                        return (
                          <span
                            onClick={() => {
                              handleNavigateUserDetails(record);
                            }}
                          >
                            {text}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Subscription",
                      dataIndex: "subscription",
                      key: "subscription",
                      render: (text, record) => {
                        return (
                          <span
                            onClick={() => {
                              handleNavigateUserDetails(record);
                            }}
                          >
                            {record.userDetail.isSubscribed
                              ? "Subscribed"
                              : "UnSubscribed"}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Document Status",
                      dataIndex: "userDetail",
                      key: "userDetail",
                      render: (text, record) => {
                        console.log("complete record for service provider === ",text)
                        return (
                          <div 
                          // className="user-status"
                          style={{
                            color: '#fff',
                            fontFamily: "Poppins",
                            fontWeight: '500',
                            borderRadius: '10px',
                            height: '40px',
                            display: 'flex',
                            fontSize: "12px",
                            width: "80px",
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor:
                              text.status === 1
                                ? Colors.Green
                                : text.status === 2
                                ? Colors.Pink
                                : Colors.Primary,
                              
                             
                  
                          }}
                          >
                          {
                            // UPLOADED: 0, 
                            // APPROVED: 1,
                            // REJECTED: 2,
                            // NOT_SUBMITTED: 3,
                            text.status === 0 ? 'Uploaded' : text.status === 1 ? 'Approved' : text.status === 2 ? 'Rejected' :  'Pending'
                          }
                          </div>
                         
                        );
                      },
                    },
                    {
                      title: "",
                      dataIndex: "",
                      render: (text, record) => (
                        <img
                          onClick={() => {
                            setSelectedUser(record);
                            setShowModal(true);
                          }}
                          src={Images.Emailcon}
                          alt=""
                        />
                      ),
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      key: "status",
                      render: (text, record) => (
                        <div
                          // className="user-status"
                          style={{
                            color: '#fff',
                            fontFamily: "Poppins",
                            fontWeight: '500',
                            borderRadius: '10px',
                            height: '40px',
                            display: 'flex',
                            fontSize: "12px",
                            width: "80px",
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor:
                              record.status === 1
                                ? Colors.Green
                                : record.status === 2
                                ? Colors.Pink
                                : Colors.Primary,
                              
                          }}
                        >
                          {record.status === 1
                            ? "Active"
                            : record.status === 2
                            ? "Banned"
                            : "Pending"}
                        </div>
                      ),
                    },
                    {
                      title: "",
                      dataIndex: "",
                      render: (text, record) =>
                        record.status !== 3 && (
                          <Switch.Basic
                            checked={record.status === 1 ? true : false}
                            onChange={() =>
                              handleChangeStatus(
                                record.id,
                                record.status === 1 ? 2 : 1
                              )
                            }
                          />
                        ),
                    },
                  ]}
                  data={serviceProviders.serviceProviders}
                  pagination={true}
                  rowKey="key"
                  loading={false}
                />
                <Row justify="center" className="mt-20">
                  <Col>
                    <Pagination
                      responsive="true"
                      showLessItems="true"
                      current={page}
                      pageSize={10}
                      total={serviceProviders.count}
                      onChange={handlePaginationChange}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Box>
      {showModal && (
        <Modal.AntDesignModal
          title=""
          isModalVisible={showModal}
          handleCancel={() => {
            setShowModal(false);
            setSubjectError("");
            setMessageError("");
            setSubject("");
            setMessage("");
          }}
        >
          <Row justify="center">
            <Col>
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={"Send Message"}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="flex-row mt-20">
              <Col>
                <img
                  src={selectedUser?.imageThumbUrl}
                  alt=""
                  className="service-user-email-img"
                />
              </Col>
              <Col>
                <div className="flex-column ml-20">
                  <Text.Heading
                    fontSize={18}
                    fontFamily={Fonts["Poppins-Bold"]}
                    color={Colors.Black}
                    fontWeight={600}
                    text={
                      selectedUser.fullName.length > 40
                        ? `${selectedUser.fullName.slice(0, 40)}...`
                        : selectedUser.fullName
                    }
                    textAlign="left"
                  />
                  <Text.Heading
                    fontSize={16}
                    fontFamily={Fonts["Poppins-Medium"]}
                    color={Colors.Black}
                    fontWeight={500}
                    text={selectedUser.email}
                    textAlign="left"
                  />
                </div>
              </Col>
            </Col>
          </Row>
          <Row className="mt-20">
            <Col span={24}>
              <TextField.CustomInput
                label="Subject:"
                color={Colors.Black}
                placeholder="Email Subject"
                value={subject}
                size="large"
                type="text"
                className="user-subject-field"
                onChange={(e) => setSubject(e.target.value)}
              />
            </Col>
            {subjectError && (
              <Col>
                <Text.ErrorText
                  fontSize={14}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.Red}
                  fontWeight={500}
                  text={subjectError}
                  textAlign="left"
                />
              </Col>
            )}
          </Row>
          <Row className="my-20">
            <Col span={24}>
              <label className="service-provider-msg-label">Message:</label>
              <TextArea
                placeholder="Write your message here"
                value={message}
                rows={5}
                size="large"
                type="text"
                className="service-provider-msg-input"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Col>
            {messageError && (
              <Col>
                <Text.ErrorText
                  fontSize={14}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.Red}
                  fontWeight={500}
                  text={messageError}
                  textAlign="left"
                />
              </Col>
            )}
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Button.Basic
                onClick={handleSendEmail}
                text={"Send"}
                className="service-provider-email-btn"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
    </>
  );
}

export default ProviderManagement;
