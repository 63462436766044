import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { ReportsAction } from "../actions";

export default class ReportsMiddleware {
  static *getAllProfiles(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Get(
        `/admin/report/profile?limit=${payload.limit}&offset=${payload.offset}`
      );

     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.getAllProfilesSuccess(res?.data));
      } else {
        yield put(
          ReportsAction.getAllProfilesFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.getAllProfilesFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *ignoreProfileReport(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/report/${id}/profile`);
     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status === 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.ignoreProfileReportSuccess(res?.data));
      } else {
        yield put(
          ReportsAction.ignoreProfileReportFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.ignoreProfileReportFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *getAllComments(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Get(
        `/admin/report/comment?limit=${payload.limit}&offset=${payload.offset}`
      );

     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.getAllCommentsSuccess(res?.data));
      } else {
        yield put(
          ReportsAction.getAllCommentsFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.getAllCommentsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *ignoreCommentReport(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/report/${id}/comment`);
     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status === 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.ignoreCommentReportSuccess(res?.data));
      } else {
        yield put(
          ReportsAction.ignoreCommentReportFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.ignoreCommentReportFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *deleteComment(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/comment/${id}`);
     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status === 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.deleteCommentSuccess(res?.data));
      } else {
        yield put(
          ReportsAction.deleteCommentFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.deleteCommentFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *getAllPosts(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Get(
        `/admin/report/social-media?limit=${payload.limit}&offset=${payload.offset}`
      );

     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.getAllPostsSuccess(res?.data));
      } else {
        yield put(ReportsAction.getAllPostsFailure(res?.data?.error?.message));
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.getAllPostsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *ignorePostReport(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/report/${id}/social-media`);
     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status === 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.ignorePostReportSuccess(res?.data));
      } else {
        yield put(
          ReportsAction.ignorePostReportFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.ignorePostReportFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *deletePost(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/post/${id}`);
     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status === 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.deletePostSuccess(res?.data));
      } else {
        yield put(ReportsAction.deletePostFailure(res?.data?.error?.message));
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.deletePostFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *deletePoll(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/poll/${id}`);
     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status === 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.deletePollSuccess(res?.data));
      } else {
        yield put(ReportsAction.deletePollFailure(res?.data?.error?.message));
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.deletePollFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *banUser(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Patch(`/admin/user/${id}/ban`);
     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status === 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.banUserSuccess(res?.data));
      } else {
        yield put(ReportsAction.banUserFailure(res?.data?.error?.message));
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.banUserFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *suspendUser(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Patch(`/admin/user/${id}/suspend`);
     if(res == undefined){
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error",
        });
      } else if (res?.status === 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ReportsAction.suspendUserSuccess(res?.data));
      } else {
        yield put(ReportsAction.suspendUserFailure(res?.data?.error?.message));
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ReportsAction.suspendUserFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
}
