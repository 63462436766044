import { delay, put } from "redux-saga/effects";
import Axios from "axios";
import Store from "..";

import { ApiCaller, Utils } from "../../config";
import { AuthAction } from "../actions";

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  ({ response }) => {
    if (response.status == 401) {
      try {
        localStorage.removeItem("authUser");
        Store.dispatch(AuthAction.signInSuccess());
        window.location.reload();
      } catch (err) {}
    }
    return response;
  }
);

Axios.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("authUser")) {
      config.headers = {
        ...config?.headers,
        "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
      };
      return config;
    } else {
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
const CancelToken = Axios.CancelToken;

export default class AuthMiddleware {
  static *SignIn(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post("/auth/login", payload);
      if (res.status == 200) {
        yield localStorage.setItem("authUser", JSON.stringify(res.data.data));
        Utils.showSnackBar({ message: res.data.message });
        if (cb) {
          cb(true);
        }
        yield put(AuthAction.signInSuccess(res.data.data));
      } else {
        yield put(AuthAction.signInFailure(res.data.error.message));
        Utils.showSnackBar({ message: res?.data?.error?.message, severity: "error" });
        // yield delay(3000);
        // yield put(AuthAction.removeError());
        // if (cb) {
        //   cb(false);
        // }
      }
    } catch (err) {
      yield put(AuthAction.signInFailure());
      Utils.showSnackBar({ message: "Login failed", severity:"error" });
      // yield delay(3000);
      // yield put(AuthAction.removeError());
    }
  }

  static *Logout({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/logout", payload);
      if (res.status == 200) {
        yield put(AuthAction.logoutSuccess());
        yield localStorage.removeItem("authUser");
        cb();
      } else {
        yield put(AuthAction.logoutFailure());
        yield delay(3000);
        yield put(AuthAction.removeError());
      }
    } catch (err) {
      yield put(AuthAction.logoutFailure());
      yield delay(3000);
      yield put(AuthAction.removeError());
    }
  }

  static *ForgotPassword({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/forget-password", payload);
      if (res.status == 200) {
        yield put(AuthAction.forgotPasswordSuccess(res.data.data));
        cb();
      } else {
        yield put(AuthAction.forgotPasswordFailure(res.data.error));
        Utils.showSnackBar({ message: res?.data?.error?.message, severity: "error" });
        // yield delay(3000);
        // yield put(AuthAction.removeError());
      }
    } catch (err) {
      yield put(AuthAction.forgotPasswordFailure(err));
      Utils.showSnackBar({ message: "Forgot password failed", severity: "error" });
      // yield delay(3000);
      // yield put(AuthAction.removeError());
    }
  }

  static *VerifyCode({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/verify-token", payload);
      if (res.status == 200) {
        yield put(AuthAction.verifyCodeSuccess(res.data.data));
        cb();
      } else {
        yield put(AuthAction.verifyCodeFailure(res.data.error));
        Utils.showSnackBar({ message: res?.data?.error?.message, severity: "error" });
        yield delay(3000);
        yield put(AuthAction.removeError());
      }
    } catch (err) {
      yield put(AuthAction.verifyCodeFailure(err));
      Utils.showSnackBar({ message: "OTP failed", severity: "error" });
      yield delay(3000);
      yield put(AuthAction.removeError());
    }
  }

  static *ResetPassword({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/auth/reset-password", payload);
      if (res.status == 200) {
        yield put(AuthAction.resetPasswordSuccess(res.data.data));
        cb();
      } else {
        yield put(AuthAction.resetPasswordFailure(res.data.error));
        Utils.showSnackBar({ message: res?.data?.error?.message, severity: "error" });
        // yield delay(3000);
        // yield put(AuthAction.removeError());
      }
    } catch (err) {
      yield put(AuthAction.resetPasswordFailure(err));
      Utils.showSnackBar({ message: "Reset password failed", severity: "error" });
      // yield delay(3000);
      // yield put(AuthAction.removeError());
    }
  }
}
