import {
  GET_NO_OF_SERVICE_STATS,
  GET_NO_OF_SERVICE_STATS_FAILURE,
  GET_NO_OF_SERVICE_STATS_SUCCESS,
  GET_POST_CREATED_STATS,
  GET_POST_CREATED_STATS_ERROR,
  GET_POST_CREATED_STATS_SUCCESS,
  GET_SERVICE_USER_STATS,
  GET_SERVICE_USER_STATS_FAILURE,
  GET_SERVICE_USER_STATS_SUCCESS,
  GET_SUBSCRIPTION_STATS,
  GET_SUBSCRIPTION_STATS_FAILURE,
  GET_SUBSCRIPTION_STATS_SUCCESS,
  GET_USER_STATS,
  GET_USER_STATS_FAILURE,
  GET_USER_STATS_SUCCESS,
  SERVICE_REQUESTED_STATS,
  SERVICE_REQUESTED_STATS_FAILURE,
  SERVICE_REQUESTED_STATS_SUCCESS,
  GET_COUNT, GET_COUNT_SUCCESS, GET_COUNT_FAILURE,
  ACTION_REQ, ACTION_REQ_FAILURE, ACTION_REQ_SUCCESS,
  SIGNUPS, SIGNUPS_SUCCESS, SIGNUPS_FAILURE,
  DELETED_ACC, DELETED_ACC_SUCCESS, DELETED_ACC_FAILURE,
  SERVICE_PROVIDER, SERVICE_PROVIDER_SUCCESS, SERVICE_PROVIDER_FAILURE,
  JOBS, JOBS_SUCCESS, JOBS_FAILURE,
  ADS, ADS_SUCCESS, ADS_FAILURE,
  SERVICES, SERVICES_SUCCESS, SERVICES_FAILURE,
  POST_AND_REPORTS, POST_AND_REPORTS_SUCCESS, POST_AND_REPORTS_FAILURE
} from "../constants";

export default class DashboardAction {
  static getUserStats(params) {
    return {
      type: GET_USER_STATS,
      params,
    };
  }
  static getUserStatsSuccess(payload) {
    return {
      type: GET_USER_STATS_SUCCESS,
      payload,
    };
  }
  static getUserStatsFailure(payload) {
    return {
      type: GET_USER_STATS_FAILURE,
      payload,
    };
  }

  static getPostCreatedStats(params) {
    return {
      type: GET_POST_CREATED_STATS,
      params,
    };
  }
  static getPostCreatedStatsSuccess(payload) {
    return {
      type: GET_POST_CREATED_STATS_SUCCESS,
      payload,
    };
  }
  static getPostCreatedStatsFailure(payload) {
    return {
      type: GET_POST_CREATED_STATS_ERROR,
      payload,
    };
  }

  static getServiceUserStats(params) {
    return {
      type: GET_SERVICE_USER_STATS,
      params,
    };
  }
  static getServiceUserStatsSuccess(payload) {
    return {
      type: GET_SERVICE_USER_STATS_SUCCESS,
      payload,
    };
  }
  static getServiceUserStatsFailure(payload) {
    return {
      type: GET_SERVICE_USER_STATS_FAILURE,
      payload,
    };
  }

  static getNoOfService(params) {
    return {
      type: GET_NO_OF_SERVICE_STATS,
      params,
    };
  }
  static getNoOfServiceSuccess(payload) {
    return {
      type: GET_NO_OF_SERVICE_STATS_SUCCESS,
      payload,
    };
  }
  static getNoOfServiceFailure(payload) {
    return {
      type: GET_NO_OF_SERVICE_STATS_FAILURE,
      payload,
    };
  }

  static serviceRequested(params) {
    return {
      type: SERVICE_REQUESTED_STATS,
      params,
    };
  }
  static serviceRequestedSuccess(payload) {
    return {
      type: SERVICE_REQUESTED_STATS_SUCCESS,
      payload,
    };
  }
  static serviceRequestedFailure(payload) {
    return {
      type: SERVICE_REQUESTED_STATS_FAILURE,
      payload,
    };
  }

  static getSubscription(payload, cb) {
    return {
      type: GET_SUBSCRIPTION_STATS,
      payload,
      cb,
    };
  }
  static getSubscriptionSuccess(payload) {
    return {
      type: GET_SUBSCRIPTION_STATS_SUCCESS,
      payload,
    };
  }
  static getSubscriptionFailure(payload) {
    return {
      type: GET_SUBSCRIPTION_STATS_FAILURE,
      payload,
    };
  }

  static getCount(payload) {
    return {
      type: GET_COUNT,
      payload,
    };
  }
  static getCountSuccess(payload) {
    return {
      type: GET_COUNT_SUCCESS,
      payload,
    };
  }
  static getCountFailure(payload) {
    return {
      type: GET_COUNT_FAILURE,
      payload,
    };
  }

  static getActionReq() {
    // console.log("this is the payload od th eaction",payload)
    return {
      type: ACTION_REQ,
      // payload,
      
    };
  }
  static getActionReqSuccess(payload) {
    return {
      type: ACTION_REQ_SUCCESS,
      payload,
    };
  }
  static getActionReqFailure(payload) {
    return {
      type: ACTION_REQ_FAILURE,
      payload,
    };
  }

  static getSignups(payload) {
    return {
      type: SIGNUPS,
      payload,
    };
  }
  static getSignupsSuccess(payload) {
    return {
      type: SIGNUPS_SUCCESS,
      payload,
    };
  }
  static getSignupsFailure(payload) {
    return {
      type: SIGNUPS_FAILURE,
      payload,
    };
  }

  static getDeletedAcc(payload) {
    return {
      type: DELETED_ACC,
      payload,
    };
  }
  static getDeletedAccSuccess(payload) {
    return {
      type: DELETED_ACC_SUCCESS,
      payload,
    };
  }
  static getDeletedAccFailure(payload) {
    return {
      type: DELETED_ACC_FAILURE,
      payload,
    };
  }

  static getServiceProviders(payload) {
    return {
      type: SERVICE_PROVIDER,
      payload,
    };
  }
  static getServiceProvidersSuccess(payload) {
    return {
      type: SERVICE_PROVIDER_SUCCESS,
      payload,
    };
  }
  static getServiceProvidersFailure(payload) {
    return {
      type: SERVICE_PROVIDER_FAILURE,
      payload,
    };
  }

  static getJobs(payload) {
    return {
      type: JOBS,
      payload,
    };
  }
  static getJobsSuccess(payload) {
    return {
      type: JOBS_SUCCESS,
      payload,
    };
  }
  static getJobsFailure(payload) {
    return {
      type: JOBS_FAILURE,
      payload,
    };
  }

  static getAds(payload) {
    return {
      type: ADS,
      payload,
    };
  }
  static getAdsSuccess(payload) {
    return {
      type: ADS_SUCCESS,
      payload,
    };
  }
  static getAdsFailure(payload) {
    return {
      type: ADS_FAILURE,
      payload,
    };
  }

  static getServices(payload) {
    return {
      type: SERVICES,
      payload,
    };
  }
  static getServicesSuccess(payload) {
    return {
      type: SERVICES_SUCCESS,
      payload,
    };
  }
  static getServicesFailure(payload) {
    return {
      type: SERVICES_FAILURE,
      payload,
    };
  }

  static getPostAndReports(payload) {
    return {
      type: POST_AND_REPORTS,
      payload,
    };
  }
  static getPostAndReportsSuccess(payload) {
    return {
      type: POST_AND_REPORTS_SUCCESS,
      payload,
    };
  }
  static getPostAndReportsFailure(payload) {
    return {
      type: POST_AND_REPORTS_FAILURE,
      payload,
    };
  }
}
