import { Col, Pagination, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useMedia from "use-media";

import { ReportsAction, ServiceUsersAction } from "../../store/actions";
import PostCard from "../../components/Card/Post";

import "./index.css";

function Posts() {
  const dispatch = useDispatch();
  const isTablet = useMedia({ minWidth: "968px" });
  const reports = useSelector((state) => state.reports);
  const [page, setPage] = useState(1);
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
  });

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  const handleIgnoreReport = (id) => {
    dispatch(
      ReportsAction.ignorePostReport(id, () => {
        dispatch(ReportsAction.getAllPosts(state));
      })
    );
  };


  const handleBanUser = (id, status) => {
    const payload = {
      status,
    };
    dispatch(
      ServiceUsersAction.updateServiceUserStatus(id, payload, () => {
        dispatch(ReportsAction.getAllPosts(state));
      })
    );
  };

  const handleDeletePost = (id) => {
    dispatch(
      ReportsAction.deletePost(id, () => {
        dispatch(ReportsAction.getAllPosts(state));
      })
    );
  };

  const handleDeletePoll = (id) => {
    dispatch(
      ReportsAction.deletePoll(id, () => {
        dispatch(ReportsAction.getAllPosts(state));
      })
    );
  };

  const handleSuspendUser = (id) => {
    dispatch(
      ReportsAction.suspendUser(id, () => {
        dispatch(ReportsAction.getAllPosts(state));
      })
    );
  };

  useEffect(() => {
    dispatch(ReportsAction.getAllPosts(state));
  }, [state]);

  return (
    <>
      <Row
        justify={isTablet ? "" : "center"}
        className="flex-row-align-start-not-space mx-20"
        gutter={[20, 0]}
      >
        {(reports?.posts || []).map((item) => {
          return (
            <Col xxl={6} xl={8} lg={10} md={16} sm={24} xs={24}>
              <PostCard
                item={item}
                handleBanUser={handleBanUser}
                handleIgnoreReport={handleIgnoreReport}
                handleSuspendUser={handleSuspendUser}
                handleDeletePoll={handleDeletePoll}
                handleDeletePost={handleDeletePost}
              />
            </Col>
          );
        })}
      </Row>
      <Row justify="center" className="mt-20">
        <Col>
          <Pagination
            responsive="true"
            showLessItems="true"
            pageSize={10}
            current={page}
            total={reports?.count}
            onChange={handlePaginationChange}
          />
        </Col>
      </Row>
    </>
  );
}

export default Posts;
