import {
  GET_ALL_SERVICE_USERS,
  GET_ALL_SERVICE_USERS_FAILURE,
  GET_ALL_SERVICE_USERS_SUCCESS,
  SEND_SERVICE_USER_EMAIL,
  SEND_SERVICE_USER_EMAIL_FAILURE,
  SEND_SERVICE_USER_EMAIL_SUCCESS,
  UPDATE_SERVICE_USER_STATUS,
  UPDATE_SERVICE_USER_STATUS_FAILURE,
  UPDATE_SERVICE_USER_STATUS_SUCCESS,
} from "../constants";

const initialState = {
  count: 0,
  serviceUsers: [],
  serviceUser: {},
  error: null,
  isLoading: false,
};

export default function ServiceUsersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SERVICE_USERS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_SERVICE_USERS_SUCCESS:
      state = {
        ...state,
        serviceUsers: action?.payload?.data?.serviceUsers,
        count: action?.payload?.data?.count,
        isLoading: false,
      };
      break;
    case GET_ALL_SERVICE_USERS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case UPDATE_SERVICE_USER_STATUS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case UPDATE_SERVICE_USER_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case UPDATE_SERVICE_USER_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case SEND_SERVICE_USER_EMAIL:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case SEND_SERVICE_USER_EMAIL_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case SEND_SERVICE_USER_EMAIL_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
