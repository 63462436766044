import { Tabs } from "antd";

import { Box, Text } from "../../components";
import { Colors, Fonts } from "../../config";
import Comments from "./Comments";
import Posts from "./Posts";
import Profiles from "./Profiles";

import "./index.css";

const { TabPane } = Tabs;

function ReportsManagement() {
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <Box>
      <Text.Heading
        fontSize={24}
        fontFamily={Fonts["Poppins-SemiBold"]}
        color={Colors.Black}
        fontWeight={600}
        text={"Reports Management"}
      />
      <Tabs defaultActiveKey="1" onChange={onChange} className="mt-20" animated={true} >
        <TabPane tab="Profile" key="1">
          <Profiles />
        </TabPane>
        <TabPane tab="Comments" key="2">
          <Comments />
        </TabPane>
        <TabPane tab="Post" key="3">
          <Posts />
        </TabPane>
      </Tabs>
    </Box>
  );
}

export default ReportsManagement;
