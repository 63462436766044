import { Col, Pagination, Row } from "antd";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { AiFillPlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useMedia from "use-media";

import {
  Box,
  Button,
  Loader,
  Modal,
  Table,
  Text,
  TextField,
} from "../../components";
import { Colors, Fonts, Images, Utils } from "../../config";
import { ServiceAction } from "../../store/actions";

function ServiceManagement() {
  const isTablet = useMedia({ minWidth: "768px" });
  const isMobile = useMedia({ minWidth: "450px" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.service);

  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [singleServiceModal, setSingleServiceModal] = useState(false);
  const [selectedSubServices, setSelectedSubServices] = useState([]);
  const [editService, setEditService] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedService, setSelectedService] = useState({});
  const [error, setError] = useState({
    id: "",
    name: "",
    subService: [""],
    image: "",
  });

  const [service, setService] = useState({
    serviceName: "",
    subServices: [""],
    serviceImage: null,
  });
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    search: "",
    status: "",
  });
  const [deleteSubServiceIndex, setDeleteService] = useState("");

  const handleAddMoreSubservices = () => {
    let err = [...error.subService];
    var bool = false;
    service.subServices.forEach((subService, index) => {
      if (subService?.id) {
        if (subService?.name?.trim() !== "") {
          err[index] = "";
        } else {
          bool = true;
          err[index] = "Please enter sub service name";
        }
      } else {
        if (subService?.trim() !== "") {
          err[index] = "";
        } else {
          bool = true;
          err[index] = "Please enter sub service name";
        }
      }
    });
    setError({ ...error, subService: err });
    if (bool == false) {
      setService({
        ...service,
        subServices: [...service.subServices, ""],
      });
    }
  };
  const handleDeleteSubservice = (id) => {
    if (service.subServices.length > 1) {
      dispatch(
        ServiceAction.deleteSubService(id, () => {
          setDeleteModal(false);
          setSelectedService({});
          dispatch(ServiceAction.getAllServices(state));

          setShowModal(false);
          setSingleServiceModal(false);
        })
      );
      return;
    }
    Utils.showSnackBar({
      message: "There must be at least one sub service for a service",
      severity: "error",
    });
  };

  const handleCreateService = () => {
    if (service.serviceName === "") {
      setError({ ...error, name: "Service name is required" });
    } else if (
      service.serviceName.trim() === "" ||
      !(
        Utils.alphaNumericRegex.test(service.serviceName) &&
        isNaN(service.serviceName)
      )
    ) {
      setError({ ...error, name: "Enter a valid service name" });
    } else if (service.serviceName.length > 30) {
      setError({
        ...error,
        name: "Service name cannot be greater than 30 characters",
      });
    } else {
      let err = [...error.subService];
      var bool = false;
      service.subServices.forEach((subService, index) => {
        if (subService.id) {
          if (subService?.name?.trim() !== "") {
            err[index] = "";
          } else {
            bool = true;
            err[index] = "Please enter sub service name";
          }
        } else {
          if (subService?.trim() !== "") {
            err[index] = "";
          } else {
            bool = true;
            err[index] = "Please enter sub service name";
          }
        }
      });
      if (bool == true) {
        setError({ ...error, name: "", subService: err });
        return;
      }
      if (service.serviceImage === null) {
        setError({
          ...error,
          name: "",
          subService: "",
          image: "Service image is required",
        });
        return;
      } else {
        const formData = new FormData();
        formData.append("name", service.serviceName.trim());
        service.subServices.forEach((subService, index) => {
          if (subService.id) {
            formData.append(`subServices[]`, subService.name);
          } else {
            formData.append(`subServices[]`, subService);
          }
        });
        formData.append("picture", service.serviceImage);
        editService
          ? dispatch(
              ServiceAction.updateService(service.id, formData, () => {
                setShowModal(false);
                setService({
                  serviceName: "",
                  subServices: [""],
                  serviceImage: null,
                });
                dispatch(ServiceAction.getAllServices(state));
              })
            )
          : dispatch(
              ServiceAction.addService(formData, () => {
                dispatch(ServiceAction.getAllServices(state));
                setShowModal(false);
                setService({
                  serviceName: "",
                  subServices: [""],
                  serviceImage: null,
                });
                setError({
                  name: "",
                  subService: "",
                  image: "",
                });
              })
            );
      }
    }
  };

  const handleDeleteService = (id) => {
    dispatch(
      ServiceAction.deleteService(id, () => {
        setDeleteModal(false);
        setSelectedService({});
        dispatch(ServiceAction.getAllServices(state));
      })
    );
  };

  const DragAreaContent = () => {
    return (
      <div className="drag-area-div">
        <img src={Images.UploadFileIcon} />
        <Text.Heading
          className="drag-area-text-heading"
          fontSize={14}
          fontFamily={Fonts["Poppins-Bold"]}
          color={Colors.BlueText}
          fontWeight={600}
          text={service.serviceImage === null ? "Upload a photo" : "Uploaded"}
          textAlign="center"
        />
        <Text.Heading
          fontSize={12}
          fontFamily={Fonts["Poppins-Medium"]}
          color={Colors.BlueText}
          fontWeight={500}
          text={
            "Supported formats PNG, JPEG or JPG. Photos are usually 100 x 100"
          }
          textAlign="center"
        />
      </div>
    );
  };

  const handleNavigateServiceDetails = (record) => {
    navigate("/service-management/details", {
      state: {
        record,
      },
    });
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  useEffect(() => {
    dispatch(ServiceAction.getAllServices(state));
  }, [state]);

  return (
    <>
      <Box>
        <Row justify="space-between">
          <Col className="flex-row-start">
            <Text.Heading
              fontSize={isMobile ? 24 : 20}
              fontFamily={Fonts["Poppins-SemiBold"]}
              color={Colors.Black}
              fontWeight={600}
              text={"Service Management"}
            />
          </Col>
          {services.services.length < 50 && (
            <Col
              md={8}
              sm={21}
              xs={24}
              className={
                isTablet
                  ? "flex-end"
                  : isMobile
                  ? "flex-row-start mt-20"
                  : "row-flex-center mt-20"
              }
            >
              <Button.Basic
                onClick={() => {
                  setEditService(false);
                  setShowModal(true);
                }}
                text={"Add Service"}
                startIcon={<AiFillPlusCircle color="white" />}
                className="add-admin-btn"
              />
            </Col>
          )}
        </Row>
        <Row justify="space-between" className="mt-20">
          <Col span={24}>
            {services.isLoading ? (
              <Col className="row-flex-center mt-20">
                <Loader.Circular />
              </Col>
            ) : (
              <>
                <Table.AntDesignTable
                  className="sub-admin-table"
                  columns={[
                    {
                      title: "Image",
                      dataIndex: "imageThumbUrl",
                      key: "imageThumbUrl",
                      render: (text, record) => (
                        <span
                          onClick={() => {
                            handleNavigateServiceDetails(record);
                          }}
                        >
                          <img className="sub-admin-avatar" src={text} />
                        </span>
                      ),
                    },
                    {
                      title: "Service Name",
                      dataIndex: "name",
                      key: "name",
                      render: (text, record) => {
                        return (
                          <span
                            onClick={() => {
                              handleNavigateServiceDetails(record);
                            }}
                          >
                            {text}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Sub Service",
                      dataIndex: "noOfSubServices",
                      key: "noOfSubServices",
                      render: (text, record) => {
                        return (
                          <span
                            onClick={() => {
                              handleNavigateServiceDetails(record);
                            }}
                          >
                            {text === null
                              ? "-"
                              : text < 10
                              ? `0${text}`
                              : text}
                          </span>
                        );
                      },
                    },
                    {
                      title: "Number Of Users",
                      dataIndex: "noOfUsers",
                      key: "noOfUsers",
                      render: (text, record) => {
                        return <span>{text === null ? "-" : text}</span>;
                      },
                    },
                    {
                      title: "Action",
                      dataIndex: "subscription",
                      key: "subscription",
                      render: (text, record) => {
                        return (
                          <span>
                            <img
                              src={Images.EditIcon}
                              onClick={() => {
                                setService({
                                  id: record.id,
                                  serviceName: record.name,
                                  subServices: record.subServices.map(
                                    (subService) => {
                                      return subService;
                                    }
                                  ),
                                  serviceImage: record.imageUrl,
                                });
                                setEditService(true);
                                setShowModal(true);
                              }}
                            />{" "}
                            <img
                              onClick={() => {
                                setSelectedService(record);
                                setDeleteModal(true);
                              }}
                              src={Images.DeleteIcon}
                            />
                          </span>
                        );
                      },
                    },
                  ]}
                  data={services.services}
                  pagination={true}
                  rowKey="key"
                  loading={false}
                />
                <Row justify="center" className="mt-20">
                  <Col>
                    <Pagination
                      responsive="true"
                      showLessItems="true"
                      pageSize={10}
                      current={page}
                      total={services.count}
                      onChange={handlePaginationChange}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Box>
      {deleteModal && (
        <Modal.ConfirmationModal
          centered={true}
          title={""}
          isModalVisible={deleteModal}
          heading={"Are you sure you want to delete this service?"}
          subHeading={
            "All the sub services related to this service will be deleted."
          }
          handleCancel={() => setDeleteModal(false)}
          handleDelete={() => handleDeleteService(selectedService.id)}
        ></Modal.ConfirmationModal>
      )}
      {singleServiceModal && (
        <Modal.ConfirmationModal
          title={""}
          isModalVisible={singleServiceModal}
          heading={"Are you sure you want to delete sub service?"}
          handleCancel={() => setSingleServiceModal(false)}
          handleDelete={() => {
            if (deleteSubServiceIndex) {
              const deleteSubService = { ...service };
              deleteSubService.subServices.splice(deleteSubServiceIndex, 1);
              setService(deleteSubService);
              setSingleServiceModal(false);
            } else {
              handleDeleteSubservice(selectedService.id);
            }

            // setSelectedSubServices((prev) => {
            //   console.log("PREV HMMM", prev);
            //   return prev.filter(
            //     (subServ) => subServ.id !== selectedService.id
            //   );
            // });
          }}
        ></Modal.ConfirmationModal>
      )}
      {showModal && (
        <Modal.AntDesignModal
          title=""
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => {
            setService({
              serviceName: "",
              subServices: [""],
              serviceImage: null,
            });
            setError({
              name: "",
              subService: "",
              image: "",
            });
            setShowModal(false);
          }}
        >
          <Row justify="center">
            <Col>
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={editService ? "Edit Service" : "Add Service"}
              />
            </Col>
          </Row>
          <Row justify="center" className="mt-20">
            <Col span={24}>
              <TextField.CustomInput
                label="Service Name: "
                placeholder="Enter Service Name"
                value={service.serviceName}
                size="large"
                type="text"
                className="user-subject-field"
                onChange={(e) =>
                  setService({ ...service, serviceName: e.target.value })
                }
              />
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={error.name}
                textAlign="center"
                className="mt-10"
              />
            </Col>
          </Row>
          {service.subServices.map((subService, index) => {
            return (
              <Row justify="center" className="my-10-20">
                <Col span={24}>
                  <TextField.CustomInput
                    label={
                      index === 0
                        ? `Sub Service Name`
                        : `Sub Service Name ${index + 1}`
                    }
                    placeholder="Enter Sub Service Name"
                    suffix={
                      <img
                        className="cursor"
                        onClick={() => {
                          if (!subService.id) {
                            setDeleteService(index);
                            setSelectedService({});
                          } else {
                            setDeleteService("");
                            setSelectedService(subService);
                          }
                          setSingleServiceModal(true);

                          // if (subService.new) {
                          //   setSelectedSubServices((prev) =>
                          //     prev.filter(
                          //       (subServ) => subServ.id !== subService.id
                          //     )
                          //   );
                          // } else {
                          //   const deletedSubservice = selectedSubServices.find(
                          //     (sub) => sub.id === subService.id
                          //   );
                          //   setSelectedService(deletedSubservice);
                          //   setDeleteModal(true);
                          // }
                        }}
                        src={Images.DeleteSubServiceIcon}
                        width={20}
                      />
                    }
                    value={subService.name}
                    size="large"
                    type="text"
                    className="user-subject-field"
                    onChange={(e) =>
                      setService({
                        ...service,
                        subServices: [
                          ...service.subServices.slice(0, index),
                          e.target.value,
                          ...service.subServices.slice(index + 1),
                        ],
                      })
                    }
                  />
                  <Text.Heading
                    fontSize={14}
                    fontFamily={Fonts["Poppins-Regular"]}
                    color={"red"}
                    fontWeight={400}
                    text={error.subService[index]}
                    textAlign="center"
                    className="mb-10"
                  />
                </Col>
              </Row>
            );
          })}
          {service.subServices.length < 20 && (
            <Col className="row-flex-end">
              <Button.Basic
                startIcon={<img src={Images.AddSquareIcon} alt="" />}
                text={"Add More"}
                 className="add-more-btn"
                onClick={handleAddMoreSubservices}
              />
            </Col>
          )}
          <Row justify="center" className="drag-area-row">
            <Col>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  let dot = acceptedFiles[0].path.split(".");
                  if (
                    acceptedFiles[0].path.split(".")[dot.length - 1] ===
                      "png" ||
                    acceptedFiles[0].path.split(".")[dot.length - 1] ===
                      "jpeg" ||
                    acceptedFiles[0].path.split(".")[dot.length - 1] === "jpg"
                    // acceptedFiles[0].type === "image/jpeg" ||
                    // acceptedFiles[0].type === "image/png"
                  ) {
                    Utils.showSnackBar({
                      message: "Image uploaded successfully",
                    });
                    setService({ ...service, serviceImage: acceptedFiles[0] });
                    setError({
                      ...error,
                      image: "",
                    });
                  } else {
                    Utils.showSnackBar({
                      message: "Image format not supported",
                      severity: "error",
                    });
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <DragAreaContent />
                    </div>
                  </section>
                )}
              </Dropzone>
              {error && (
                <Text.ErrorText
                  fontSize={14}
                  fontFamily={Fonts["Poppins-Regular"]}
                  color={"red"}
                  fontWeight={400}
                  text={error.image}
                />
              )}
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Button.Basic
                onClick={handleCreateService}
                text={"Submit"}
                className="service-provider-email-btn"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
    </>
  );
}

export default ServiceManagement;
