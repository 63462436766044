import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import useMedia from "use-media";

import { AuthAction } from "../../store/actions";
import { Button, ContainerBox, Text, TextField } from "../../components";
import { Colors, Fonts, Images, Utils } from "../../config";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    password: "",
    passErrMsg: "",
    confirmPassword: "",
    confirmPassErrMsg: "",
  });

  const isMobile = useMedia({ minWidth: "450px" });

  const resetPassword = () => {
    const { password, confirmPassword } = state;
    if (!password) {
      setState({
        ...state,
        passErrMsg: "Password can not be left empty.",
      });
    } else if (!confirmPassword) {
      setState({
        ...state,
        passErrMsg: "",
        confirmPassErrMsg: "Confirm Password can not be left empty.",
      });
    } else if (password !== confirmPassword) {
      setState({
        ...state,
        passErrMsg: "",
        confirmPassErrMsg: "Password does not match.",
      });
    } else {
      setState({
        ...state,
        passErrMsg: "",
        confirmPassErrMsg: "",
        validPass: true,
        validConfirmPass: true,
      });
      const body = {
        email: location.state.email,
        password: state.password,
      };
      dispatch(
        AuthAction.resetPassword(body, () => {
          navigate("/login");
        })
      );
    }
  };

  const validatePass = (password) => {
    let validPass = Utils.passwordRegex.test(String(password));
    setState({ ...state, password, validPass, passErrMsg: "" });
  };

  const validateConfirmPass = (confirmPassword) => {
    let validConfirmPass = Utils.passwordRegex.test(String(confirmPassword));
    setState({
      ...state,
      confirmPassword,
      validConfirmPass,
      confirmPassErrMsg: "",
    });
  };

  return (
    <ContainerBox>
      <Row justify="center" style={{ width: "100%" }}>
        <Col
          xl={8}
          lg={9}
          md={12}
          sm={14}
          xs={21}
          style={{
            backgroundColor: Colors.White,
            borderRadius: "10px",
          }}
        >
          <Row
            justify="center"
            gutter={isMobile ? [0, 24] : [0, 18]}
            style={{ width: "100%", marginBlock: "35px" }}
          >
            <Col>
              <img src={Images.Logo} alt="logo"></img>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Text.Heading
                fontSize={isMobile ? 30 : 24}
                fontFamily={Fonts["Poppins-Bold"]}
                color={Colors.Black}
                fontWeight={700}
                text={"Reset Password"}
              />
              <Text.Heading
                fontSize={isMobile ? 15 : 12}
                style={{
                  marginInline: isMobile ? "0px" : "10px"
                }}
                fontFamily={Fonts["Poppins-Regular"]}
                color={Colors.LightTextColor}
                fontWeight={400}
                textALign={"center"}
                text={"Please enter a strong and safe password"}
              />
            </Col>
            <Col
              className="passwordField"
              span={18}
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField.CustomInput
                password
                label="New Password"
                size="large"
                type={"password"}
                defaultValue={state.password}
                placeholder={"**********"}
                style={{
                  border: "1px solid #F5F5F5",
                  boxShadow: "0px 3px 6px #0000001A",
                  borderRadius: 10,
                }}
                onChange={(e) => validatePass(e.target.value)}
              />
              <Text.ErrorText text={state.passErrMsg} />
            </Col>
            <Col
              className="passwordField"
              span={18}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField.CustomInput
                password
                label="Confirm New Password"
                size="large"
                type={"password"}
                defaultValue={state.password}
                placeholder={"**********"}
                style={{
                  border: "1px solid #F5F5F5",
                  boxShadow: "0px 3px 6px #0000001A",
                  borderRadius: 10,
                }}
                onChange={(e) => validateConfirmPass(e.target.value)}
              />
              <Text.ErrorText text={state.confirmPassErrMsg} />
            </Col>
            <Col span={18}>
              <Button.Basic
                variant="outlined"
                style={{
                  backgroundColor: Colors.Primary,
                  textTransform: "capitalize",
                  color: Colors.White,
                  width: "100%",
                  borderRadius: 8,
                  borderColor: Colors.Transparent,
                  fontFamily: `${Fonts["Poppins-Regular"]}`,
                }}
                text="Update"
                onClick={resetPassword}
                containerStyle={{ margin: "10px 0" }}
                size="large"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerBox>
  );
};

export default ResetPassword;
