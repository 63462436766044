import {
  ADD_SUB_ADMIN,
  ADD_SUB_ADMIN_FAILURE,
  ADD_SUB_ADMIN_SUCCESS,
  DELETE_SUB_ADMIN,
  DELETE_SUB_ADMIN_FAILURE,
  DELETE_SUB_ADMIN_SUCCESS,
  GET_ALL_SUB_ADMINS,
  GET_ALL_SUB_ADMINS_FAILURE,
  GET_ALL_SUB_ADMINS_SUCCESS,
  UPDATE_SUB_ADMIN,
  UPDATE_SUB_ADMIN_FAILURE,
  UPDATE_SUB_ADMIN_SUCCESS,
} from "../constants";

export default class SubAdminAction {
  static getAllSubAdmins(payload, cb) {
    return {
      type: GET_ALL_SUB_ADMINS,
      payload,
      cb,
    };
  }
  static getAllSubAdminsSuccess(payload) {
    return {
      type: GET_ALL_SUB_ADMINS_SUCCESS,
      payload,
    };
  }
  static getAllSubAdminsFailure(payload) {
    return {
      type: GET_ALL_SUB_ADMINS_FAILURE,
      payload,
    };
  }
  static addSubAdmin(payload, cb) {
    return {
      type: ADD_SUB_ADMIN,
      payload,
      cb,
    };
  }
  static addSubAdminSuccess(payload) {
    return {
      type: ADD_SUB_ADMIN_SUCCESS,
      payload,
    };
  }
  static addSubAdminFailure(payload) {
    return {
      type: ADD_SUB_ADMIN_FAILURE,
      payload,
    };
  }
  static deleteSubAdmin(id, cb) {
    return {
      type: DELETE_SUB_ADMIN,
      id,
      cb,
    };
  }
  static deleteSubAdminSuccess(payload) {
    return {
      type: DELETE_SUB_ADMIN_SUCCESS,
      payload,
    };
  }
  static deleteSubAdminFailure(payload) {
    return {
      type: DELETE_SUB_ADMIN_FAILURE,
      payload,
    };
  }
  static updateSubAdmin(id, payload, cb) {
    return {
      type: UPDATE_SUB_ADMIN,
      id,
      payload,
      cb,
    };
  }
  static updateSubAdminSuccess(payload) {
    return {
      type: UPDATE_SUB_ADMIN_SUCCESS,
      payload,
    };
  }
  static updateSubAdminFailure(payload) {
    return {
      type: UPDATE_SUB_ADMIN_FAILURE,
      payload,
    };
  }
}
