import { Select } from 'antd'
import React from 'react'
import { Colors, Fonts } from '../../../config'

const MultiSelect = ({
    label,
    placeholder,
    className,
    selectStyle,
    onChange,
    size,
    value,
    ...props
}) => {
    return (
        <>
            <label
                style={{
                    textAlign: "left",
                    color: Colors.TextColor,
                    fontFamily: Fonts["Poppins-Regular"],
                    fontSize: "16px",
                }}
            >
                {label}
            </label>
            <Select
                placeholder={placeholder}
                className={className}
                mode="tags"
                style={selectStyle}
                onChange={onChange}
                tokenSeparators={[',']}
                size={"large"}
                value={value}
                {...props}
            />
        </>
    )
}

export default MultiSelect