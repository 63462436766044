import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { ServiceUsersAction } from "../actions";

export default class ServiceUsersMiddleware {
  static *getServiceUsers(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Get(
        `/admin/service-user?limit=${payload.limit}&offset=${payload.offset}&search=${payload.search}&status=${payload.status}`
      );

      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ServiceUsersAction.getAllServiceUsersSuccess(res?.data));
      } else {
        yield put(
          ServiceUsersAction.getAllServiceUsersFailure(
            res?.data?.error?.message
          )
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceUsersAction.getAllServiceUsersFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *updateServiceUserStatus(params) {
    const { id, payload, cb } = params;
    try {
      let res = yield ApiCaller.Patch(`/admin/user/${id}`, payload);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ServiceUsersAction.updateServiceUserStatusSuccess(res?.data));
      } else {
        yield put(
          ServiceUsersAction.updateServiceUserStatusFailure(
            res?.data?.error?.message
          )
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceUsersAction.updateServiceUserStatusFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *sendServiceUserEmail(params) {
    const { id, payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(`/admin/user/${id}/send-mail`, payload);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ServiceUsersAction.sendServiceUserEmailSuccess(res?.data));
      } else {
        yield put(
          ServiceUsersAction.sendServiceUserEmailFailure(
            res?.data?.error?.message
          )
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceUsersAction.sendServiceUserEmailFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
}
