import {
  ADD_SERVICE,
  ADD_SERVICE_FAILURE,
  ADD_SERVICE_SUCCESS,
  DELETE_SERVICE,
  DELETE_SERVICE_FAILURE,
  DELETE_SERVICE_SUCCESS,
  DELETE_SUB_SERVICE,
  DELETE_SUB_SERVICE_FAILURE,
  DELETE_SUB_SERVICE_SUCCESS,
  GET_ALL_SERVICES,
  GET_ALL_SERVICES_FAILURE,
  GET_ALL_SERVICES_SUCCESS,
  GET_ALL_SUB_SERVICES,
  GET_ALL_SUB_SERVICES_FAILURE,
  GET_ALL_SUB_SERVICES_SUCCESS,
  UPDATE_SERVICE,
  UPDATE_SERVICE_FAILURE,
  UPDATE_SERVICE_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  error: null,
  services: [],
  subServices: [],
  count: 0,
};

export default function ServiceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SERVICES:
      state = {
        ...state,
        isLoading: true,
        error: null,
        subServices: [],
      };
      break;
    case GET_ALL_SERVICES_SUCCESS:
      state = {
        ...state,
        services: action.payload.data.services,
        count: action.payload.data.count,
        isLoading: false,
      };
      break;
    case GET_ALL_SERVICES_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case GET_ALL_SUB_SERVICES:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_ALL_SUB_SERVICES_SUCCESS:
      state = {
        ...state,
        subServices: action.payload.data.subServices,
        isLoading: false,
      };
      break;
    case GET_ALL_SUB_SERVICES_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case ADD_SERVICE:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case ADD_SERVICE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case ADD_SERVICE_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case DELETE_SERVICE:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case DELETE_SERVICE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case DELETE_SERVICE_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case UPDATE_SERVICE:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case UPDATE_SERVICE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case UPDATE_SERVICE_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case DELETE_SUB_SERVICE:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case DELETE_SUB_SERVICE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case DELETE_SUB_SERVICE_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
}
