const Fonts = {
    "Montserrat": "Montserrat",
    "Montserrat-Black": "Montserrat, Black",
    "Montserrat-BlackItalic": "Montserrat, BlackItalic",
    "Montserrat-Bold": "Montserrat, Bold",
    "Montserrat-BoldItalic": "Montserrat, BoldItalic",
    "Montserrat-ExtraBold": "Montserrat, ExtraBold",
    "Montserrat-ExtraBoldItalic": "Montserrat, ExtraBoldItalic",
    "Montserrat-ExtraLight": "Montserrat, ExtraLight",
    "Montserrat-ExtraLightItalic": "Montserrat, ExtraLightItalic",
    "Montserrat-Italic": "Montserrat, Italic",
    "Montserrat-Light": "Montserrat, Light",
    "Montserrat-LightItalic": "Montserrat, LightItalic",
    "Montserrat-Medium": "Montserrat, Medium",
    "Montserrat-MediumItalic": "Montserrat, MediumItalic",
    "Montserrat-Regular": "Montserrat, Regular",
    "Montserrat-SemiBold": "Montserrat, SemiBold",
    "Montserrat-SemiBoldItalic": "Montserrat, SemiBoldItalic",
    "Montserrat-Thin": "Montserrat, Thin",
    "Montserrat-ThinItalic": "Montserrat, ThinItalic",
    "Poppins": "Poppins",
    "Poppins-Black": "Poppins, Black",
    "Poppins-BlackItalic": "Poppins, BlackItalic",
    "Poppins-Bold": "Poppins, Bold",
    "Poppins-BoldItalic": "Poppins, BoldItalic",
    "Poppins-ExtraBold": "Poppins, ExtraBold",
    "Poppins-ExtraBoldItalic": "Poppins, ExtraBoldItalic",
    "Poppins-ExtraLight": "Poppins, ExtraLight",
    "Poppins-ExtraLightItalic": "Poppins, ExtraLightItalic",
    "Poppins-Italic": "Poppins, Italic",
    "Poppins-Light": "Poppins, Light",
    "Poppins-LightItalic": "Poppins, LightItalic",
    "Poppins-Medium": "Poppins, Medium",
    "Poppins-MediumItalic": "Poppins, MediumItalic",
    "Poppins-Regular": "Poppins, Regular",
    "Poppins-SemiBold": "Poppins, SemiBold",
    "Poppins-SemiBoldItalic": "Poppins, SemiBoldItalic",
    "Poppins-Thin": "Poppins, Thin",
    "Poppins-ThinItalic": "Poppins, ThinItalic"
}

export default Fonts;