import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { ContactSupportAction } from "../actions";

export default class ContactSupportMiddleware {
  static *getAllSupportMessages(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Get(
        `/admin/support/message?limit=${payload.limit}&offset=${payload.offset}&status=${payload.status}&startDate=${payload.startDate}&endDate=${payload.endDate}`
      );
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ContactSupportAction.getAllSupportMessagesSuccess(res?.data));
      } else {
        yield put(
          ContactSupportAction.getAllSupportMessagesFailure(
            res?.data?.error?.message
          )
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ContactSupportAction.getAllSupportMessagesFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *deleteSupportMessage(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/support/message/${id}`);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ContactSupportAction.deleteSupportMessageSuccess(res?.data));
      } else {
        yield put(
          ContactSupportAction.deleteSupportMessageFailure(
            res?.data?.error?.message
          )
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ContactSupportAction.deleteSupportMessageFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *sendSupportMessageReply(params) {
    const { id, payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(
        `/admin/support/message/${id}/reply`,
        payload
      );
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(
          ContactSupportAction.sendSupportMessageReplySuccess(res?.data)
        );
      } else {
        yield put(
          ContactSupportAction.sendSupportMessageReplyFailure(
            res?.data?.error?.message
          )
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ContactSupportAction.sendSupportMessageReplyFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *sendCustomEmail(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(`/admin/support/send-mail`, payload);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ContactSupportAction.sendCustomEmailSuccess(res?.data));
      } else {
        yield put(
          ContactSupportAction.sendCustomEmailFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ContactSupportAction.sendCustomEmailFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *getEmails(params) {
    const { search, cb } = params;
    try {
      let res = yield ApiCaller.Get(
        `/admin/support/active-user?search=${search}`
      );
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(ContactSupportAction.getUserEmailsSuccess(res?.data));
      } else {
        yield put(
          ContactSupportAction.getUserEmailsFailure(res?.data?.error?.message)
        );
        // Utils.showSnackBar({
        //   message: res?.data?.error?.message,
        //   severity: "error",
        // });
        // if (cb) {
        //   cb(false);
        // }
      }
    } catch (err) {
      yield put(ContactSupportAction.getUserEmailsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
}
