import * as React from 'react';
import Avatar from '@mui/material/Avatar';

const Basic = ({ alt, src, sx, Icon, containerStyle, ...props }) => {
    return (
        <div style={containerStyle}>
            <Avatar alt={alt} src={src} sx={sx}>
                <Icon />
                {/* icon props */}
            </Avatar>
        </div>
    )
}
export default Basic;
