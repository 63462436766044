import Basic from "./Basic";
import AntDesignModal from "./AntdModal";
import ConfirmationModal from "./ConfirmationModal";
import AcceptModal from "./AcceptModal";
import EditAdModal from "./EditAdModal";
import InputModal from "./InputModal";

export default {
  Basic,
  AntDesignModal,
  ConfirmationModal,
  AcceptModal,
  EditAdModal,
  InputModal
};
