import {
  DELETE_SUPPORT_MESSAGE,
  DELETE_SUPPORT_MESSAGE_FAILURE,
  DELETE_SUPPORT_MESSAGE_SUCCESS,
  GET_ALL_SUPPORT_MESSAGES,
  GET_ALL_SUPPORT_MESSAGES_FAILURE,
  GET_ALL_SUPPORT_MESSAGES_SUCCESS,
  GET_USER_EMAILS,
  GET_USER_EMAILS_FAILURE,
  GET_USER_EMAILS_SUCCESS,
  SEND_CUSTOM_EMAIL,
  SEND_CUSTOM_EMAIL_FAILURE,
  SEND_CUSTOM_EMAIL_SUCCESS,
  SEND_SUPPORT_MESSAGE_REPLY,
  SEND_SUPPORT_MESSAGE_REPLY_FAILURE,
  SEND_SUPPORT_MESSAGE_REPLY_SUCCESS,
} from "../constants";

export default class ContactSupportAction {
  static getAllSupportMessages(payload, cb) {
    return {
      type: GET_ALL_SUPPORT_MESSAGES,
      payload,
      cb,
    };
  }
  static getAllSupportMessagesSuccess(payload) {
    return {
      type: GET_ALL_SUPPORT_MESSAGES_SUCCESS,
      payload,
    };
  }
  static getAllSupportMessagesFailure(payload) {
    return {
      type: GET_ALL_SUPPORT_MESSAGES_FAILURE,
      payload,
    };
  }
  static deleteSupportMessage(id, cb) {
    return {
      type: DELETE_SUPPORT_MESSAGE,
      id,
      cb,
    };
  }
  static deleteSupportMessageSuccess(payload) {
    return {
      type: DELETE_SUPPORT_MESSAGE_SUCCESS,
      payload,
    };
  }
  static deleteSupportMessageFailure(payload) {
    return {
      type: DELETE_SUPPORT_MESSAGE_FAILURE,
      payload,
    };
  }
  static sendSupportMessageReply(id, payload, cb) {
    return {
      type: SEND_SUPPORT_MESSAGE_REPLY,
      id,
      payload,
      cb,
    };
  }
  static sendSupportMessageReplySuccess(payload) {
    return {
      type: SEND_SUPPORT_MESSAGE_REPLY_SUCCESS,
      payload,
    };
  }
  static sendSupportMessageReplyFailure(payload) {
    return {
      type: SEND_SUPPORT_MESSAGE_REPLY_FAILURE,
      payload,
    };
  }
  static getUserEmails(search, cb) {
    return {
      type: GET_USER_EMAILS,
      search,
      cb,
    };
  }
  static getUserEmailsSuccess(payload) {
    return {
      type: GET_USER_EMAILS_SUCCESS,
      payload,
    };
  }
  static getUserEmailsFailure(payload) {
    return {
      type: GET_USER_EMAILS_FAILURE,
      payload,
    };
  }
  static sendCustomEmail(payload, cb) {
    return {
      type: SEND_CUSTOM_EMAIL,
      payload,
      cb,
    };
  }
  static sendCustomEmailSuccess(payload) {
    return {
      type: SEND_CUSTOM_EMAIL_SUCCESS,
      payload,
    };
  }
  static sendCustomEmailFailure(payload) {
    return {
      type: SEND_CUSTOM_EMAIL_FAILURE,
      payload,
    };
  }
}
