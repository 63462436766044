import React from 'react'
import styled from 'styled-components'
import { Colors, Fonts } from '../../../config'

function ErrorText(props) {
  return (
    <Heading {...props} >{props.text}</Heading>
  )
}

export default ErrorText

const Heading=styled.span`
    font-size: 14px;
    color: red;
    text-align: ${(props) => props.textAlign || "left"};
    font-family: ${Fonts["Poppins-Regular"]};
    font-weight: 400;
    margin-top: ${(props) => props.marginTop || 0}px;
`