import React from "react";
import { Switch } from 'antd';

const Basic = ({ color, size, onChange, thumb, checked, disabled, containerStyle, ...props }) => {
    return (
        <div style={containerStyle} >
            <Switch
                color={color}
                size={size}
                onChange={onChange}
                thumb={thumb}
                checked={checked}
                disabled={disabled}
                {...props}
            />

        </div>
    )
}

export default Basic;
