import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { ServiceProvidersAction } from "../actions";

export default class ServiceProvidersMiddleware {
  static *getServiceProviders(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Get(
        `/admin/service-provider?limit=${payload.limit}&offset=${payload.offset}&search=${payload.search}&status=${payload.status}`
      );

      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data.message });
        if (cb) {
          cb(true);
        }
        yield put(
          ServiceProvidersAction.getAllServiceProvidersSuccess(res?.data)
        );
      } else {
        yield put(
          ServiceProvidersAction.getAllServiceProvidersFailure(
            res?.data.error.message
          )
        );
        Utils.showSnackBar({ message: res?.data.error.message, severity: "error" });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceProvidersAction.getAllServiceProvidersFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }
  static *updateServiceProviderStatus(params) {
    const { id, payload, cb } = params;
    try {
      let res = yield ApiCaller.Patch(`/admin/user/${id}`, payload);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data.message });
        if (cb) {
          cb(true);
        }
        yield put(
          ServiceProvidersAction.updateServiceProviderStatusSuccess(res?.data)
        );
      } else {
        yield put(
          ServiceProvidersAction.updateServiceProviderStatusFailure(
            res?.data.error.message
          )
        );
        Utils.showSnackBar({ message: res?.data.error.message, severity: "error" });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceProvidersAction.updateServiceProviderStatusFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }
  static *sendServiceProviderEmail(params) {
    const { id, payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(`/admin/user/${id}/send-mail`, payload);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data.data.message });
        if (cb) {
          cb(true);
        }
        yield put(
          ServiceProvidersAction.sendServiceProviderEmailSuccess(res?.data)
        );
      } else {
        yield put(
          ServiceProvidersAction.sendServiceProviderEmailFailure(
            res?.data.error.message
          )
        );
        Utils.showSnackBar({ message: res?.data.error.message, severity: "error" });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceProvidersAction.sendServiceProviderEmailFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }
  static *getServiceProviderDetails(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Get(`/admin/service-provider/${id}`);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data.message });
        if (cb) {
          cb(true);
        }
        yield put(
          ServiceProvidersAction.getServiceProviderDetailsSuccess(res?.data.data.serviceProvider)
        );
      } else {
        yield put(
          ServiceProvidersAction.getServiceProviderDetailsFailure(
            res?.data.error.message
          )
        );
        Utils.showSnackBar({ message: res?.data.error.message, severity: "error" });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceProvidersAction.getServiceProviderDetailsFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }

  static *updateServiceProviderBusinessStatus(params) {

    const { id, payload, cb } = params;
      console.log("payload here : ", payload)
    try {
      let res = yield ApiCaller.Patch(`/admin/user/service-provider/${id}`, { status: payload?.status, reason: payload?.reason });
      console.log(res, "getting request")
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      }
      else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data.message });
        if (cb) {
          cb(true);
        }
        yield put(
          console.log("response data here ===== ", res?.data),
          ServiceProvidersAction.updateServiceProviderBusinessDocumentStatusSuccess(res?.data.data.serviceProvider)
        );
      }
      else {
        yield put(
          ServiceProvidersAction.updateServiceProviderBusinessDocumentStatusFailure(
            res?.error.message
          )
        );
        Utils.showSnackBar({ message: "Length should be greater than 10 characters", severity: "error" });
      }
    }

    catch(err) {
      yield put(
        ServiceProvidersAction.updateServiceProviderBusinessDocumentStatusFailure());
        Utils.showSnackBar({ message: "No internet connection", severity: "error" });
    }

  }
}
