import { BsArrowLeftShort } from "react-icons/bs";
import { Col, Row } from "antd";
import { Image } from 'antd'
import { useCallback, useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import ImageViewer from "react-simple-image-viewer";
import ReactStars from "react-stars";

import { Box, Button, Text } from "../../components";
import { Colors, Fonts } from "../../config";
import { ServiceProvidersAction } from "../../store/actions";
import { Modal } from '../../components'
import "./index.css";


function ServiceProviderDetails() {
  const navigate = useNavigate();
  const location = useLocation();

  const locationstate = location?.state?.state;
  const locationpage = location?.state?.page;

  console.log('location', location)
  const dispatch = useDispatch();
  const serviceProvider = useSelector(
    (state) => state.serviceProviders.serviceProvider
  );
  console.log("service provider==", serviceProvider)
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);


  // for acceptance
  const [ismodal, setismodal] = useState(false);
  // for rejection
  const [ismodalReject, setismodalReject] = useState(false);
  // for final confirmation rejection
  const [isrejectconfirm, setrejectconfirm] = useState(false);


  const [isloading, setisloading] = useState(true);

  // const imagesName = [
  //   // {
  //   //   name: "Drivers Front",
  //   //   thumbnail: serviceProvider?.userDetail?.drivingLicenseFrontThumb,
  //   //   src: serviceProvider?.userDetail?.drivingLicenseFront,
  //   // },
  //   // {
  //   //   name: "Drivers Back",
  //   //   thumbnail: serviceProvider?.userDetail?.drivingLicenseBackThumb,
  //   //   src: serviceProvider?.userDetail?.drivingLicenseBack,
  //   // },
  //   // {
  //   //   name: "Your Photo",
  //   //   thumbnail: serviceProvider?.userDetail?.photographThumb,
  //   //   src: serviceProvider?.userDetail?.photograph,
  //   // },
  //   {
  //     name: "Proof of business",
  //     thumbnail: serviceProvider?.userDetail?.proofOfBusinessThumb,
  //     src: serviceProvider?.userDetail?.proofOfBusiness,
  //   },
  // ];

  // const images = imagesName.map((img) => img.src);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const handleAddressFormat = () => (
    serviceProvider?.addressDetail?.addressLine2
      ?
      `${serviceProvider?.addressDetail?.addressLine1}, 
      ${serviceProvider?.addressDetail?.addressLine2},
       ${serviceProvider?.addressDetail?.town},
        ${serviceProvider?.addressDetail?.postalCode}`
      :
      `${serviceProvider?.addressDetail?.addressLine1},
       ${serviceProvider?.addressDetail?.town},
        ${serviceProvider?.addressDetail?.postalCode}`
  )


  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleApproveUser = (id) => {
    const payload = {
      status: "1",
    };
    dispatch(
      ServiceProvidersAction.updateServiceProviderStatus(id, payload, () => {
        dispatch(
          ServiceProvidersAction.getServiceProviderDetails(location.state.id)
        );
      })
    );
  };

  // on click on approve for business proff
  const handleProofOfBusiness = () => {
    // console.log(id,status)
    setismodal(!ismodal);
  }


  const handlerejectionapprove = () => {
    setismodalReject(false);
    setrejectconfirm(true)

  }

  const handlerejectioncancel = () => {
    setismodalReject(false);
  }

  const handlerejectionconfirmation = () => {
    setrejectconfirm(false)
    setismodalReject(false)
  }


  const handlerejectionconfirmationapproved = (reason) => {
    console.log("from handle button getting this ", reason)
    const payload = {
      "status": '2',
      "reason": reason
    }
    dispatch(ServiceProvidersAction.updateServiceProviderBusinessDocumentStatus(serviceProvider?.id, payload, () => {
      dispatch(ServiceProvidersAction.getServiceProviderDetails(location.state.id))
    }))
    setrejectconfirm(false)
  }

  // on click on reject for business proff
  const RejectApproveOfUser = () => {
    setismodalReject(true)
    // setrejectconfirm(true);
  }

  // on click of accept from an opened modal 
  const setisbusinessdoc = (id, status) => {
    const payload = {
      "status": '1'
    }
    setisloading(false)
    setismodal(false)
    dispatch(ServiceProvidersAction.updateServiceProviderBusinessDocumentStatus(id, payload, () => {
      dispatch(ServiceProvidersAction.getServiceProviderDetails(location.state.id))
    }))

  }

  // const handleisbusinessreject = () => {
  //   setismodalReject(false);
  //   setrejectconfirm(true)
  // }




  useEffect(() => {
    dispatch(
      ServiceProvidersAction.getServiceProviderDetails(location.state.id)
    );
  }, []);

  return (
    <Box>
      <Row justify="space-between">
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <BsArrowLeftShort
            onClick={() => navigate("/provider-management", { state: { locationstate, locationpage } })}
            style={{
              color: Colors.Black,
              fontSize: "34px",
              cursor: "pointer",
            }}
          />
          <Text.Heading
            fontSize={24}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={"Provider Details"}
          />
        </Col>
      </Row>
      <Row
        justify="center"
        style={{
          marginTop: "20px",
        }}
      >
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={serviceProvider?.imageUrl}
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              margin: "5px auto",
            }}
            alt="logo"
          ></img>
          <Text.Heading
            fontSize={24}
            fontFamily={Fonts["Poppins-SemiBold"]}
            color={Colors.Black}
            fontWeight={600}
            text={serviceProvider?.fullName}
          />
          <Text.Heading
            fontSize={16}
            fontFamily={Fonts["Poppins-Regular"]}
            color={Colors.Black}
            fontWeight={400}
            text={serviceProvider?.email}
          />
          <Text.Heading
            fontSize={16}
            fontFamily={Fonts["Poppins-Regular"]}
            color={Colors.Black}
            fontWeight={400}
            text={serviceProvider?.phoneNo}
          />
          {serviceProvider?.addressDetail && (
            serviceProvider?.addressDetail.addressLine1
            || serviceProvider?.addressDetail.town
            || serviceProvider?.addressDetail?.postalCode
          ) ?
            <Text.Heading
              fontSize={16}
              fontFamily={Fonts["Poppins-Regular"]}
              color={Colors.Black}
              fontWeight={400}
              text={handleAddressFormat()}
            />
            : <Text.Heading
              fontSize={16}
              fontFamily={Fonts["Poppins-Regular"]}
              color={Colors.Black}
              fontWeight={400}
              text={serviceProvider?.address}
            />
          }
          {serviceProvider?.status === 3 ? (
            <Button.Basic
              containerStyle={{
                display: "flex",
                justifyContent: "center",
                marginBlock: "20px",
              }}
              style={{
                padding: "5px 15px",
                borderRadius: "10px",
                backgroundColor: Colors.Green,
                color: Colors.White,
                fontSize: "16px",
                fontFamily: Fonts["Poppins-Medium"],
                fontWeight: 500,
                cursor: "pointer",
                textTransform: "capitalize",
              }}
              onClick={() => handleApproveUser(serviceProvider?.id)}
              text={"Approve"}
            />
          ) : (
            <div
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "2px solid #AEAEAE8A",
                borderRadius: "10px",
                opacity: "1",
                display: "flex",
                justifyContent: "space-between",
                padding: "20px 10px",
                marginBlock: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  margin: "0px 15px",
                }}
              >
                <Text.Heading
                  fontSize={16}
                  fontFamily={Fonts["Poppins-Bold"]}
                  color={Colors.TextColor}
                  fontWeight={600}
                  text={serviceProvider?.noOfCompletedBookings}
                />
                <Text.Heading
                  fontSize={16}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.LightTextColor}
                  fontWeight={500}
                  text={"Number of service provided"}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  width: "2px",
                  background: Colors.gray,
                  margin: "0px 15px",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0px 60px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReactStars
                    edit={false}
                    count={5}
                    size={24}
                    color2={Colors.Primary}
                    value={serviceProvider?.averageRating}
                    className="stars"
                  />
                  <Text.Heading
                    fontSize={16}
                    fontFamily={Fonts["Poppins-Medium"]}
                    color={Colors.TextColor2}
                    fontWeight={500}
                    text={`${(serviceProvider?.averageRating)?.toFixed(2)} (${serviceProvider?.ratingCount})`}
                  />
                </div>
                <Text.Heading
                  fontSize={16}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.LightTextColor}
                  fontWeight={500}
                  text={"Average Rating"}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row justify="space-evenly">
        {/* {imagesName.map((img, index) => (
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              marginInline: "9px",
            }}
          >
            <Text.Heading
              fontSize={18}
              fontFamily={Fonts["Poppins-Medium"]}
              color={Colors.Black}
              fontWeight={500}
              text={img.name}
              textAlign="left"
              style={{
                marginBottom: "10px",
              }}
            />
            <img
              src={img.thumbnail}
              style={{
                width: "280px",
                height: "150px",
                borderRadius: "5px",
              }}
              alt="logo"
              onClick={() => openImageViewer(index)}
            ></img>
          </Col>
        ))} */}

        {/* Business proff images ====================================== */}
        {
          serviceProvider?.userDetail?.status !== 2 && serviceProvider?.userDetail?.proofOfBusinessThumb ?
            <Col lg={5} md={6} sm={8} xs={24} className="mr-10 mb-10 service-user-business-prof">
              <div className="flex-column">
                <Text.Heading
                  fontSize={16}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.Black}
                  fontWeight={500}
                  text={"proof of business"}
                  textAlign={'left'}
                />
                <div>
                  {/* <img className="services-img" src={serviceProvider?.userDetail?.proofOfBusinessThumb} alt="image heree..."></img>
                     */}
                  <Image
                    // width={200}
                    className="services-img"
                    style={{ borderRadius: '37px',cursor:'pointer' }}
                    preview={{
                      imageRender: () => (
                        <img
                          src={serviceProvider?.userDetail?.proofOfBusinessThumb}
                          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                      ),
                      toolbarRender: () => null,
                    }}
                    src={serviceProvider?.userDetail?.proofOfBusinessThumb}
                    fallback=''
                  />
                </div>

                {
                  serviceProvider?.userDetail?.status === 0 ?
                    <Row justify="space-between">
                      <Col>
                        <Button.Basic
                          containerStyle={{
                            display: "flex",
                            justifyContent: "center",
                            marginBlock: "20px",

                          }}
                          style={{
                            padding: "5px 22px",
                            borderRadius: "10px",
                            backgroundColor: Colors.Green,
                            color: Colors.White,
                            fontSize: "13px",
                            fontFamily: Fonts["Poppins-Medium"],
                            fontWeight: 400,
                            cursor: "pointer",
                            textTransform: "capitalize",
                          }}
                          onClick={() => handleProofOfBusiness(serviceProvider?.id)}
                          text={"Approve"}
                        />
                      </Col>

                      <Col>
                        <Button.Basic
                          containerStyle={{
                            display: "flex",
                            justifyContent: "center",
                            marginBlock: "20px",
                          }}
                          style={{
                            padding: "5px 30px",
                            borderRadius: "10px",
                            backgroundColor: Colors.Pink,
                            color: Colors.White,
                            fontSize: "13px",
                            fontFamily: Fonts["Poppins-Medium"],
                            fontWeight: 400,
                            cursor: "pointer",
                            textTransform: "capitalize",
                          }}
                          onClick={() => RejectApproveOfUser(serviceProvider?.id)}
                          text={"Reject"}
                        />
                      </Col>
                    </Row>
                    :
                    serviceProvider?.userDetail?.status === 1 ?
                      ""
                      :

                      ""
                }


              </div>
            </Col>

            : ""
        }

        {/* {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )} */}

        <Modal.ConfirmationModal
          style={{ height: "50px", width: "50px" }}
          centered={true}
          title={""}
          isModalVisible={ismodal}
          heading={"Are you sure you want to approve document?"}
          handleCancel={() => setismodal(false)}
          handleDelete={() => setisbusinessdoc(serviceProvider?.id, serviceProvider?.userDetail?.status)}
        />

        <Modal.ConfirmationModal
          style={{ height: "50px", width: "50px" }}
          centered={true}
          title={""}
          isModalVisible={ismodalReject}
          heading={"Are you sure you want to reject document?"}
          handleCancel={() => handlerejectioncancel()}
          handleDelete={() => handlerejectionapprove()}
        />

        <Modal.InputModal
          centered={true}
          heading={"Reason of Rejection"}
          subHeading={"Reason"}
          isModalVisible={isrejectconfirm}
          handleCancel={() => handlerejectionconfirmation()}
          handleDelete={handlerejectionconfirmationapproved}
        />

      </Row>
    </Box>
  );
}

export default ServiceProviderDetails;
