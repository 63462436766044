import { AutoComplete } from "antd";
import React from "react";

import { Colors, Fonts } from "../../../config";

function Autocomplete({
  options,
  handleSelect,
  placeholder,
  handleSearch,
  handleChange,
  value,
  style,
  label,
}) {
  return (
    <>
      {label && (
        <label
          style={{
            textAlign: "left",
            color: Colors.TextColor,
            fontFamily: Fonts["Poppins-Regular"],
            fontSize: "16px",
          }}
        >
          {label}
        </label>
      )}
      <AutoComplete
        options={options}
        style={style}
        onSelect={handleSelect}
        onSearch={handleSearch}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </>
  );
}

export default Autocomplete;
