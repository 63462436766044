import { Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { useMedia } from "use-media";
import { Button, Text } from "../index";
import { Colors, Fonts } from "../../config";
import { Image, Info } from "../../components";
import { useDispatch } from "react-redux";
import { TextField } from "../../components";
import { AdManagementAction } from "../../store/actions";
import moment from "moment";
import { DatePicker } from "antd";

const EditAdModal = ({
  title,
  isModalVisible,
  style,
  heading,
  limit,
  offset,
  subHeading,
  handleCancel,
  isUpdate,
  handleDelete,
  data,
}) => {
  const [values, setValues] = useState({
    ...data,
    img: data.imageUrl,
    image: data.imageUrl,
    date: data.date,
  });
  const { date, image, redirectionUrl } = values;
  const [errors, setErrors] = useState({
    imageError: "",
  });
  const { imageError } = errors;
  var refImage;
  const { isUpdateImg, img } = values;
  const isXxl = useMedia({ minWidth: "1600px" });
  const dispatch = useDispatch();
  const handleDate = (val) => {
    console.log("valuee==>", val);
    setValues({
      ...values,
      date: moment(val).format("YYYY-MM-DD"),
    });
  };

  const handleText = (e) => {
    setValues({ ...values, redirectionUrl: e.target.value });
  };
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setValues({
        ...values,
        isUpdateImg: true,
        img: reader.result,
        image: file,
        isUpdate: true,
      });
    };
    reader.onerror = function (error) {};
  }

  function handleUpload(val) {
    const fileSize = val.target.files[0].size / 1024 / 1024;
    const fileType = val.target.files[0].type;
    if (fileSize > 15) {
      Info("error", "Image should be less than 15mb!");
      return;
    }

    if (fileType == "image/gif") {
      Info("error", "Please upload image only!");
      return;
    }

    if (
      fileType == "video/mp4" ||
      fileType == "video/quicktime" ||
      fileType == "video/x-matroska"
    ) {
      Info("error", "Please upload image only");
    } else {
      getBase64(val.target.files[0]);
      setValues({ ...values, image: val.target.files[0] });
      // setErrors({ ...errors, primaryFileError: ""});
    }
  }
  console.log("valuess==>", values);
  return (
    <Modal
      title={title}
      style={style}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      cancelButtonProps={{ style: { display: "none" } }}
      className="confirmation-modal"
    >
      <Row
        justify="center"
        style={{
          padding: subHeading ? "40px 40px 0 40px" : "40px 40px 20px 40px",
        }}
        gutter={[0, 20]}
      >
        <Col
          style={{
            textAlign: "center",
          }}
          align="middle"
        >
          <Text.Heading
            fontSize={24}
            fontFamily={Fonts["Poppins-Medium"]}
            color={Colors.Black}
            fontWeight={500}
            text={heading}
            textAlign="center"
          />
        </Col>
        <Col span={24} align="middle">
          <DatePicker
            value={moment(new Date(values.date))}
            onChange={(e) => handleDate(e)}
          />
        </Col>

        <Col
          span={16}
          style={{
            cursor: "pointer",
          }}
          onClick={() => refImage.click()}
        >
          <Row justify="center" align="middle">
            <Col>
              <Image
                src={img}
                width="200px"
                height="200px"
                // style={{
                //   width: isUpdateImg && "100%",
                //   height: "200px",
                // }}
              />
            </Col>
          </Row>
          <div
            style={{
              color: "black",
              alignItems: "center",
            }}
          >
            {imageError}
          </div>
        </Col>

        <input
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          onChange={(e) => handleUpload(e)}
          onClick={(event) => {
            event.target.value = null;
          }}
          style={{ display: "none" }}
          ref={(ref) => (refImage = ref)}
        />

        <Col span={16} align="middle">
          <TextField.CustomInput
            placeholder="Enter Url"
            style={{
              height: "35px",
            }}
            value={values.redirectionUrl}
            // size="large"
            // id="provider-management-search"
            type="text"
            disabled={false}
            onChange={(e) => handleText(e)}
            // required={false}
            // value={state?.search}
            // label=""
            // defaultValue=""
            // error={false}
            // helperText=""
            // onChange={handleSearchUsers}
            // prefix={<HiOutlineSearch color={Colors.dropdownTextColor} />}
            // bgColor={Colors.dropdownBgColor}
            // color={Colors.dropdownTextColor}
            className="user-search-field"
          />
        </Col>
        <Col span={18}>
          <Button.Basic
            onClick={() => {
              dispatch(
                AdManagementAction.editAdRequest(
                  { id: data?.id, date, image, redirectionUrl },
                  () => {
                    handleCancel();
                    dispatch(
                      AdManagementAction.getAdsRequest({ limit, offset })
                    );
                  }
                )
              );
            }}
            text="Update"
            style={{
              backgroundColor: Colors.Primary,
              borderColor: Colors.Primary,
              color: Colors.Black,
              fontSize: 16,
              fontFamily: Fonts["Poppins-SemiBold"],
              fontWeight: 600,
              padding: "10px 20px",
              borderRadius: "20px",
              width: "100%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default EditAdModal;
