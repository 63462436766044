import { Col, Input, Pagination, Row } from "antd";
import { HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useMedia from "use-media";

import {
  Box,
  Button,
  Loader,
  Modal,
  Switch,
  Table,
  Text,
  TextField,
} from "../../components";
import { Colors, Fonts, Images } from "../../config";
import { ServiceUsersAction } from "../../store/actions";
import "./index.css";

const { TextArea } = Input;

function UserManagement() {
  const isTablet = useMedia({ minWidth: "768px" });
  const isMobile = useMedia({ minWidth: "550px" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    search: "",
    status: "",
  });

  const handleStatusFilter = (checked, id) => {
    setState({
      ...state,
      status: checked,
    });
  };

  const handleSearchUsers = (e) => {
    setState({
      ...state,
      search: e.target.value.trimStart(),
    });
  };

  const handleChangeStatus = (id, status) => {
    const payload = {
      status,
    };
    dispatch(
      ServiceUsersAction.updateServiceUserStatus(id, payload, () => {
        dispatch(ServiceUsersAction.getAllServiceUsers(state));
      })
    );
  };

  const handleSendEmail = () => {
    if (!subject?.trim()) {
      setSubjectError("Please enter subject");
    } else if (!message?.trim()) {
      setSubjectError("");
      setMessageError("Please enter message");
    } else {
      setSubjectError("");
      setMessageError("");
      const payload = {
        subject: subject?.trim(),
        message: message?.replace(/\n|\r/g, "<br/><br/>"),
      };
      dispatch(
        ServiceUsersAction.sendServiceUserEmail(
          selectedUser.id,
          payload,
          () => {
            setShowModal(false);
            setSubject("");
            setMessage("");
          }
        )
      );
    }
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  useEffect(() => {
    dispatch(ServiceUsersAction.getAllServiceUsers(state));
  }, [state]);

  console.log("STATE: ", serviceUsers?.serviceUsers)

  return (
    <>
      <Box>
        <Row justify="space-between">
          <Col className="flex-start">
            <Text.Heading
              fontSize={24}
              fontFamily={Fonts["Poppins-SemiBold"]}
              color={Colors.Black}
              fontWeight={600}
              text={"User Management"}
            />
          </Col>
          <Col
            md={15}
            sm={isTablet ? 19 : 24}
            className={isTablet ? "flex-end" : "flex-row-start mt-20"}
          >
            <Row className={isMobile ? "flex-center" : "flex-column"}>
              <Col span={10}>
                <TextField.SelectField
                  placeholder="Select"
                  size="large"
                  id="provider-management-select"
                  type="text"
                  disabled={false}
                  required={false}
                  value={state?.status ? state?.status : "All Status"}
                  label=""
                  defaultValue="All Status"
                  error={false}
                  helperText=""
                  onChange={handleStatusFilter}
                  data={[
                    {
                      label: "All Status",
                      value: "",
                    },
                    {
                      label: "Active",
                      value: "1",
                    },
                    {
                      label: "Banned",
                      value: "2",
                    },
                  ]}
                />
              </Col>
              <Col span={isMobile ? 14 : 20} className={!isMobile && "mt-5"}>
                <TextField.CustomInput
                  placeholder="Search"
                  size="large"
                  id="provider-management-search"
                  type="text"
                  disabled={false}
                  required={false}
                  value={state?.search}
                  label=""
                  defaultValue=""
                  error={false}
                  helperText=""
                  onChange={handleSearchUsers}
                  prefix={<HiOutlineSearch color={Colors.dropdownTextColor} />}
                  bgColor={Colors.dropdownBgColor}
                  color={Colors.dropdownTextColor}
                  className="user-search-field"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" className="mt-20">
          <Col span={24}>
            {serviceUsers?.isLoading ? (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTp: "20px",
                }}
              >
                <Loader.Circular />
              </Col>
            ) : (
              <>
                <Table.AntDesignTable
                  className="user-table"
                  columns={[
                    {
                      title: "Image",
                      dataIndex: "imageThumbUrl",
                      key: "imageThumbUrl",
                      render: (text) => (
                        <img
                          src={text}
                          alt="user"
                          className="service-user-img"
                        />
                      ),
                    },
                    {
                      title: "Full Name",
                      dataIndex: "fullName",
                      key: "fullName",
                      render: (text) => (
                        <span>
                          {text?.length > 20
                            ? `${text?.slice(0, 20)}...`
                            : text}
                        </span>
                      ),
                    },
                    {
                      title: "Email",
                      dataIndex: "email",
                      key: "email",
                      render: (text) => (
                        <span>
                          {text?.length > 20
                            ? `${text?.slice(0, 20)}...`
                            : text}
                        </span>
                      ),
                    },
                    {
                      title: "Location",
                      dataIndex: "address",
                      key: "address",
                      render: (text, record) => (
                        <span title={record?.addressDetail && (
                          record?.addressDetail.addressLine1
                          || record?.addressDetail.town
                          || record?.addressDetail?.postalCode
                        )
                          ? `${record.addressDetail?.addressLine1}, ${record.addressDetail?.town}, ${record.addressDetail?.postalCode}`
                          : text
                        }>
                          {record?.addressDetail && (
                            record?.addressDetail.addressLine1
                            || record?.addressDetail.town
                            || record?.addressDetail?.postalCode
                          )
                            ? (`${record.addressDetail?.addressLine1}, ${record.addressDetail?.town}, ${record.addressDetail?.postalCode}`).length > 20
                              ? `${(`${record.addressDetail?.addressLine1}, ${record.addressDetail?.town}, ${record.addressDetail?.postalCode}`)
                                .slice(0, 20)
                              }...`
                              : `${record.addressDetail?.addressLine1}, ${record.addressDetail?.town}, ${record.addressDetail?.postalCode}`
                            : text?.length > 20
                              ? `${text?.slice(0, 20)}...`
                              : text
                          }
                        </span>
                      ),
                    },
                    {
                      title: "Phone Number",
                      dataIndex: "phoneNo",
                      key: "phoneNo",
                      render: (text) => (
                        <span>
                          {text?.length > 11
                            ? `${text?.slice(0, 11)}...`
                            : text}
                        </span>
                      ),
                    },
                    {
                      title: "",
                      dataIndex: "",
                      render: (text, record) => (
                        <img
                          onClick={() => {
                            setSelectedUser(record);
                            setShowModal(true);
                          }}
                          src={Images.Emailcon}
                          alt=""
                        />
                      ),
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      key: "status",
                      render: (text, record) => (
                        <div
                          className="user-status"
                          style={{
                            backgroundColor:
                              text === 1
                                ? Colors.Green
                                : text === 2
                                  ? Colors.Pink
                                  : Colors.Primary,
                          }}
                        >
                          {record?.status === 1
                            ? "Active"
                            : record?.status === 2
                              ? "Banned"
                              : "Pending"}
                        </div>
                      ),
                    },
                    {
                      title: "",
                      dataIndex: "",
                      render: (text, record) =>
                        record?.status !== 3 && (
                          <Switch.Basic
                            checked={record?.status === 1 ? true : false}
                            onChange={() =>
                              handleChangeStatus(
                                record?.id,
                                record?.status === 1 ? 2 : 1
                              )
                            }
                          />
                        ),
                    },
                  ]}
                  data={serviceUsers?.serviceUsers}
                  pagination={true}
                  rowKey="key"
                  loading={false}
                />
                <Row justify="center" className="mt-20">
                  <Col>
                    <Pagination
                      responsive="true"
                      showLessItems="true"
                      pageSize={10}
                      current={page}
                      total={serviceUsers?.count}
                      onChange={handlePaginationChange}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Box>
      {showModal && (
        <Modal.AntDesignModal
          title=""
          centered={true}
          isModalVisible={showModal}
          handleCancel={() => {
            setShowModal(false);
            setSubjectError("");
            setMessageError("");
            setSubject("");
            setMessage("");
          }}
        >
          <Row justify="center">
            <Col>
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={"Send Message"}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24} className="flex-row mt-20">
              <Col>
                <img
                  src={selectedUser?.imageThumbUrl}
                  alt=""
                  className="service-user-email-img"
                />
              </Col>
              <Col>
                <div className="flex-column ml-20">
                  <Text.Heading
                    fontSize={18}
                    fontFamily={Fonts["Poppins-Bold"]}
                    color={Colors.Black}
                    fontWeight={600}
                    text={
                      selectedUser?.fullName?.length > 40
                        ? `${selectedUser?.fullName?.slice(0, 40)}...`
                        : selectedUser?.fullName
                    }
                    textAlign="left"
                  />
                  <Text.Heading
                    fontSize={16}
                    fontFamily={Fonts["Poppins-Medium"]}
                    color={Colors.Black}
                    fontWeight={500}
                    text={
                      selectedUser?.email?.length > 40
                        ? `${selectedUser?.email?.slice(0, 40)}...`
                        : selectedUser?.email
                    }
                    textAlign="left"
                  />
                </div>
              </Col>
            </Col>
          </Row>
          <Row className="mt-20">
            <Col span={24}>
              <TextField.CustomInput
                label="Subject:"
                placeholder="Email Subject"
                size="large"
                type="text"
                className="user-subject-field"
                onChange={(e) => setSubject(e.target.value)}
              />
            </Col>
            {subjectError && (
              <Col>
                <Text.ErrorText
                  fontSize={14}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.Red}
                  fontWeight={500}
                  text={subjectError}
                  textAlign="left"
                />
              </Col>
            )}
          </Row>
          <Row className="my-20">
            <Col span={24}>
              <label className="text-label">Message:</label>
              <TextArea
                placeholder="Write your message here"
                rows={5}
                size="large"
                type="text"
                className="user-subject-field"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Col>
            {messageError && (
              <Col>
                <Text.ErrorText
                  fontSize={14}
                  fontFamily={Fonts["Poppins-Medium"]}
                  color={Colors.Red}
                  fontWeight={500}
                  text={messageError}
                  textAlign="left"
                />
              </Col>
            )}
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Button.Basic
                onClick={handleSendEmail}
                text={"Send"}
                className="user-msg-btn"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
    </>
  );
}

export default UserManagement;
