import { put } from "redux-saga/effects";
import { ApiCaller, Utils } from "../../config";
import { PromoManagementAction } from "../actions";

export default class PromoManagementMiddleware {

    static *getPromos(params) {
        const { payload } = params
        try {
            let res = yield ApiCaller.Get(
                `/admin/promo-code?limit=${payload.limit}&offset=${payload.offset}`
            );

            if (res == undefined) {
                Utils.showSnackBar({
                    message: "No Internet Connection",
                    severity: "error"
                })
            } else if (res?.status == 200) {
                // Utils.showSnackBar({ message: res.data.message });
                yield put(
                    PromoManagementAction.getPromosSuccess(res?.data?.data?.promoCodes)
                );
            } else {
                yield put(
                    PromoManagementAction.getPromosFailure(res.data.error.message)
                );
                Utils.showSnackBar({
                    message: res.data.error.message,
                    severity: "error",
                });
                // if (cb) {
                //     cb(false);
                // }
            }
        } catch (err) {
            yield put(PromoManagementAction.getPromosFailure(err.message));
            Utils.showSnackBar({ message: err.message, severity: "error" });
        }
    }

    static *addPromo(params) {
        const { payload, cb } = params;

        try {
            let res = yield ApiCaller.Post(`/admin/promo-code`, payload)

            if (res == undefined) {
                Utils.showSnackBar({
                    message: "No Internet Connection",
                    severity: "error"
                })
            } else if (res?.status == 200) {
                Utils.showSnackBar({ message: res?.data.message });
                if (cb) {
                    cb(true);
                }
                yield put(
                    PromoManagementAction.addPromoSuccess(res?.data)
                );
            } else {
                yield put(PromoManagementAction.addPromoFailure(res?.data.error.message));
                Utils.showSnackBar({
                    message: res?.data.error.message,
                    severity: "error"
                });
                if (cb) {
                    cb(false);
                }
            }

        } catch (err) {
            yield put(PromoManagementAction.updatePromoStatusFailure());
            Utils.showSnackBar({
                message: err.message,
                severity: "error"
            });
        }
    }

    static *updatePromoStatus(params) {
        const { id, payload, cb } = params;
        try {
            let res = yield ApiCaller.Patch(`/admin/promo-code/${id}`, payload);
            if (res == undefined) {
                Utils.showSnackBar({
                    message: "No Internet Connection",
                    severity: "error"
                })
            } else if (res?.status == 200) {
                Utils.showSnackBar({ message: res?.data.message });
                if (cb) {
                    cb(true);
                }
                yield put(
                    PromoManagementAction.updatePromoStatusSuccess(res?.data)
                );
            } else {
                yield put(
                    PromoManagementAction.updatePromoStatusFailure(res?.data.error.message)
                );
                Utils.showSnackBar({
                    message: res?.data.error.message,
                    severity: "error"
                });
                if (cb) {
                    cb(false);
                }
            }
        } catch (err) {
            yield put(PromoManagementAction.updatePromoStatusFailure());
            Utils.showSnackBar({
                message: err.message,
                severity: "error"
            });
        }
    }

    static * editPromo({ cb, payload }) {
        const {
            id,
            name,
            email,
            status
        } = payload

        let body = { name, email, status }
        try {
            let res = yield ApiCaller.Patch(`/admin/promo-code/${id}`, body);

            if (res == undefined) {
                Utils.showSnackBar({
                    message: "No Internet Connection",
                    severity: "error"
                })
            } else if (res?.status == 200) {
                Utils.showSnackBar({ message: res.data.message });
                if (cb) {
                    cb(true);
                }
                yield put(PromoManagementAction.editPromoSuccess(res.data));
            } else {
                yield put(PromoManagementAction.editPromoFailure(res.data.error.message));
                Utils.showSnackBar({
                    message: res.data.error.message,
                    severity: "error",
                });
                if (cb) {
                    cb(false);
                }
            }
        } catch (err) {
            yield put(PromoManagementAction.editPromoFailure());
            Utils.showSnackBar({ message: err.message, severity: "error" });
        }
    }

    static * deletePromo(params) {
        const { cb, id } = params;
        try {
            let res = yield ApiCaller.Delete(`/admin/promo-code/${id}`);

            if (res == undefined) {
                Utils.showSnackBar({
                    message: "No Internet Connection",
                    severity: "error"
                })
            } else if (res?.status == 200) {
                // Utils.showSnackBar({ message: res.data.message });
                if (cb) {
                    cb(true);
                }
                yield put(PromoManagementAction.deletePromoSuccess(res.data));
            } else {
                yield put(PromoManagementAction.deletePromoFailure(res.data.error.message));
                Utils.showSnackBar({
                    message: res.data.error.message,
                    severity: "error",
                });
                if (cb) {
                    cb(false);
                }
            }
        } catch (err) {
            yield put(PromoManagementAction.deletePromoFailure());
            Utils.showSnackBar({ message: err.message, severity: "error" });
        }
    }
}