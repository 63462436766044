import {
  GET_ALL_SERVICE_USERS,
  GET_ALL_SERVICE_USERS_FAILURE,
  GET_ALL_SERVICE_USERS_SUCCESS,
  SEND_SERVICE_PROVIDER_EMAIL_FAILURE,
  SEND_SERVICE_USER_EMAIL,
  SEND_SERVICE_USER_EMAIL_SUCCESS,
  UPDATE_SERVICE_USER_STATUS,
  UPDATE_SERVICE_USER_STATUS_FAILURE,
  UPDATE_SERVICE_USER_STATUS_SUCCESS,
} from "../constants";

export default class ServiceUsersAction {
  static getAllServiceUsers(payload, cb) {
    return {
      type: GET_ALL_SERVICE_USERS,
      payload,
      cb,
    };
  }
  static getAllServiceUsersSuccess(payload) {
    return {
      type: GET_ALL_SERVICE_USERS_SUCCESS,
      payload,
    };
  }
  static getAllServiceUsersFailure(payload) {
    return {
      type: GET_ALL_SERVICE_USERS_FAILURE,
      payload,
    };
  }
  static updateServiceUserStatus(id, payload, cb) {
    return {
      type: UPDATE_SERVICE_USER_STATUS,
      id,
      payload,
      cb,
    };
  }
  static updateServiceUserStatusSuccess(payload) {
    return {
      type: UPDATE_SERVICE_USER_STATUS_SUCCESS,
      payload,
    };
  }
  static updateServiceUserStatusFailure(payload) {
    return {
      type: UPDATE_SERVICE_USER_STATUS_FAILURE,
      payload,
    };
  }
  static sendServiceUserEmail(id, payload, cb) {
    return {
      type: SEND_SERVICE_USER_EMAIL,
      id,
      payload,
      cb,
    };
  }
  static sendServiceUserEmailSuccess(payload) {
    return {
      type: SEND_SERVICE_USER_EMAIL_SUCCESS,
      payload,
    };
  }
  static sendServiceUserEmailFailure(payload) {
    return {
      type: SEND_SERVICE_PROVIDER_EMAIL_FAILURE,
      payload,
    };
  }
}
