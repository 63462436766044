import { Modal } from "antd";
import React from "react";

const AntDesignModal = ({
  title,
  isModalVisible,
  handleCancel,
  style,
  children,
  closable,
  centered,
  className,
}) => {
  return (
    <Modal
      className={className ? className : "ant-modal"}
      title={title}
      style={style}
      centered={centered}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default AntDesignModal;
