import { Menu } from "antd";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { Colors, Fonts, Images } from "../../config";
import Text from "../CommonComponents/Text";
import adManagement from "../../assets/images/adManagement.png";

// ProfileDropDown
const ProfileDropDown = (props) => {
  let adminRole;
  const authUser = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  if (authUser.user) {
    adminRole = authUser.user.roleId;
  } else {
    const authLS = JSON.parse(localStorage.getItem("authUser"));
    adminRole = authLS.user.roleId;
  }

  return (
    <div className="dropDownContainer profileDropDownContainer">
      <Menu className="dropDownList">
        <Menu.Item className="dropDownItem">
          <a
            onClick={() => {
              navigate("ad-management");
            }}
          >
            <img
              width="20px"
              height="20px"
              src={adManagement}
              alt="disappear"
            />
            <Text.Heading
              style={{ marginLeft: "10px" }}
              text="Ad Management"
              textAlign="left"
              marginTop={0}
              fontSize={16}
              fontFamily={Fonts["Poppins-Medium"]}
              fontWeight={500}
              color={Colors.Black}
            />
          </a>
        </Menu.Item>
        <Menu.Item className="dropDownItem">
          <a
            onClick={() => {
              navigate("contact-support");
            }}
          >
            <img src={Images.ContactSupportIcon} alt="disappear" />
            <Text.Heading
              style={{ marginLeft: "10px" }}
              text="Contact Support"
              textAlign="left"
              marginTop={0}
              fontSize={16}
              fontFamily={Fonts["Poppins-Medium"]}
              fontWeight={500}
              color={Colors.Black}
            />
          </a>
        </Menu.Item>
        {adminRole === 2 && (
          <Menu.Item className="dropDownItem">
            <a
              onClick={() => {
                navigate("sub-admin-management");
              }}
            >
              <img src={Images.UsersIcon} alt="disappear" />
              <Text.Heading
                style={{ marginLeft: "10px" }}
                text="Sub-Admin"
                textAlign="left"
                marginTop={0}
                fontSize={16}
                fontFamily={Fonts["Poppins-Medium"]}
                fontWeight={500}
                color={Colors.Black}
              />
            </a>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default ProfileDropDown;
