import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { AdManagementAction } from "../actions";

export default class AdManagementMiddleware {
  static *getAdsRequest({ params }) {
    const searchParams = new URLSearchParams(params);
    try {
      let res = yield ApiCaller.Get(`/admin/advertisement?${searchParams}`);

      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        // if (cb) {
        //   cb(true);
        // }
        yield put(
          AdManagementAction.getAdsSuccess(
            res?.data?.data?.advertisements,
            res?.data?.data?.count
          )
        );
      } else {
        yield put(AdManagementAction.getAdsFailure(res?.data?.error?.message));
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AdManagementAction.getAdsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *updateAdRequest(action) {
    const { params, cb } = action;
    const { id } = params;

    try {
      let res = yield ApiCaller.Patch(`/admin/advertisement/${id}`, {
        ...params,
      });

      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(
          AdManagementAction.updateAdSuccess(res?.data?.data?.advertisements)
        );
      } else {
        yield put(
          AdManagementAction.updateAdSuccess(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AdManagementAction.updateAdsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *editAdRequest(action) {
    const { params, cb } = action;
    const { id } = params;

    let formData = new FormData();

    for (const key in params) {
      formData.append(key, params[key]);
    }

    try {
      let res = yield ApiCaller.Put(`/admin/advertisement/${id}`, formData);

      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(
          AdManagementAction.updateAdSuccess(res?.data?.data?.advertisements)
        );
      } else {
        yield put(
          AdManagementAction.updateAdSuccess(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AdManagementAction.updateAdsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }

  static *deleteAdRequest(action) {
    const { params, cb } = action;
    const { id } = params;

    try {
      let res = yield ApiCaller.Delete(`/admin/advertisement/${id}`);

      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(
          AdManagementAction.deleteAdSuccess(res?.data?.data?.advertisements)
        );
      } else {
        yield put(
          AdManagementAction.deleteAdSuccess(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AdManagementAction.deleteAdsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
}
