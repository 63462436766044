import React, { PureComponent } from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { Fonts } from "../../config";
import "./styles.css";

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group D', value: 200 },
// ];
const COLORS = [
  "#7700FF",
  "#FAB700",
  "#39B0F9",
  "#FF4A55",
  "#786fa6",
  "#f19066",
  "#c44569",
  "#30336b",
  "#22a6b3",
  "#16a085",
  "#9b59b6",
  "#487eb0",
  "#f368e0",
  "#B53471",
  "#ff9f1a",
];

export default class PieChartAngle extends PureComponent {
  render() {
    function renderLegend() {
      return (
        <ul
          style={{
            width: "250px",
            fontFamily: Fonts["Poppins-SemiBold"],
            overflowY: "scroll",
            height: "230px",
          }}
        >
          <h3>Services</h3>
          {this.props.data.map((entry, index) => (
            <li
              key={`item-${index}`}
              style={{
                color: COLORS[index % COLORS.length],
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    backgroundColor: COLORS[index % COLORS.length],
                    width: "10px",
                    height: "10px",
                    borderRadius: "50px",
                    marginRight: "10px",
                  }}
                ></span>
                {entry.name}
              </span>
              <span style={{ marginLeft: "7px" }}>{entry.noOfServicesRequested}</span>
            </li>
          ))}
        </ul>
      );
    }

    return (
      <PieChart width={500} height={400} onMouseEnter={this.onPieEnter}>
        <Pie
          data={this.props.data}
          cx={120}
          cy={200}
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="noOfServicesRequested"
        >
          {this.props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend content={renderLegend.bind(this)} />
      </PieChart>
    );
  }
}
