import {
    ADD_PROMO,
    ADD_PROMO_FAILURE,
    ADD_PROMO_SUCCESS,

    DELETE_PROMO,
    DELETE_PROMO_FAILURE,
    DELETE_PROMO_SUCCESS,

    EDIT_PROMO,
    EDIT_PROMO_FAILURE,
    EDIT_PROMO_SUCCESS,

    GET_ALL_PROMOS,
    GET_ALL_PROMOS_FAILURE,
    GET_ALL_PROMOS_SUCCESS,

    UPDATE_PROMO_STATUS,
    UPDATE_PROMO_STATUS_FAILURE,
    UPDATE_PROMO_STATUS_SUCCESS,

} from "../constants";

const initialState = {
    isLoading: false,
    promoCodes: [],
    count: 0,
};

export default function PromoManagementReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PROMOS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case GET_ALL_PROMOS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                promoCodes: action?.payload?.rows,
                count: action?.payload?.count
            };
            break;
        case GET_ALL_PROMOS_FAILURE:
            state = {
                ...state,
                isLoading: false,
            };
            break;

        case ADD_PROMO:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case ADD_PROMO_SUCCESS:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case ADD_PROMO_FAILURE:
            state = {
                ...state,
                isLoading: false,
            };
            break;

        case UPDATE_PROMO_STATUS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case UPDATE_PROMO_STATUS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                promoCodes: action.payload
            };
            break;
        case UPDATE_PROMO_STATUS_FAILURE:
            state = {
                ...state,
                isLoading: false,
            };
            break;

        case EDIT_PROMO:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case EDIT_PROMO_SUCCESS:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case EDIT_PROMO_FAILURE:
            state = {
                ...state,
                isLoading: false,
            };
            break;

        case DELETE_PROMO:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case DELETE_PROMO_SUCCESS:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case DELETE_PROMO_FAILURE:
            state = {
                ...state,
                isLoading: false,
            };
            break;

        //   case GET_POST_CREATED_STATS:
        //     state = {
        //       ...state,
        //       isLoading: true,
        //       postStats: [],
        //     };
        //     break;
        //   case GET_POST_CREATED_STATS_SUCCESS:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //       postStats: action.payload,
        //     };
        //     break;
        //   case GET_POST_CREATED_STATS_ERROR:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //     };
        //     break;

        //   case GET_SERVICE_USER_STATS:
        //     state = {
        //       ...state,
        //       isLoading: true,
        //       serviceUserStats: [],
        //     };
        //     break;
        //   case GET_SERVICE_USER_STATS_SUCCESS:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //       serviceUserStats: action.payload,
        //     };
        //     break;
        //   case GET_SERVICE_USER_STATS_FAILURE:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //     };
        //     break;

        //   case GET_NO_OF_SERVICE_STATS:
        //     state = {
        //       ...state,
        //       isLoading: true,
        //       noOfServices: [],
        //     };
        //     break;
        //   case GET_NO_OF_SERVICE_STATS_SUCCESS:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //       noOfServices: action.payload,
        //     };
        //     break;
        //   case GET_NO_OF_SERVICE_STATS_FAILURE:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //     };
        //     break;

        //   case SERVICE_REQUESTED_STATS:
        //     state = {
        //       ...state,
        //       isLoading: true,
        //       servicesRequested: [],
        //     };
        //     break;
        //   case SERVICE_REQUESTED_STATS_SUCCESS:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //       servicesRequested: action.payload,
        //     };
        //     break;
        //   case SERVICE_REQUESTED_STATS_FAILURE:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //     };
        //     break;

        //   case GET_SUBSCRIPTION_STATS:
        //     state = {
        //       ...state,
        //       isLoading: true,
        //       subscriptionStats: [],
        //     };
        //     break;
        //   case GET_SUBSCRIPTION_STATS_SUCCESS:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //       subscriptionStats: action.payload,
        //     };
        //     break;
        //   case GET_SUBSCRIPTION_STATS_FAILURE:
        //     state = {
        //       ...state,
        //       isLoading: false,
        //     };
        //     break;
        default:
            break;
    }
    return state;
}
