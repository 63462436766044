import React from 'react';
import {Colors,Images} from "../../config"
import styled from "styled-components";

const Box = styled.div`
  // background-color: ${Colors.Primary};
  // background-image: linear-gradient(180deg, ${Colors.backgroundColor1}, ${Colors.backgroundColor2}, ${Colors.backgroundColor3});
  color: ${Colors.Black};
  // border-radius: 10px;
  background-image:url(${Images.BackgroundImage});
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  width:100%;
  height:100%;
  // border-color: ${Colors.Primary};
  display: flex; 
  min-height: 100vh;
  justify-content: center;
  align-items: center;;
  `;
  
const ContainerBox = (props) => {
  return (
    <>
  <Box  {...props}>
  </Box>
  </>);
}

export default ContainerBox