import { Col, Pagination, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-icons/all";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useMedia from "use-media";

import {
  Box,
  Button,
  Modal,
  Picker,
  Table,
  Text,
  TextField,
} from "../../components";
import { Colors, Fonts, Images } from "../../config";
import { ContactSupportAction } from "../../store/actions";
import "./style.css";

function ContactSupport() {
  const dispatch = useDispatch();
  const isTablet = useMedia({ minWidth: "768px" });
  const isMobile = useMedia({ minWidth: "450px" });
  const [showModal, setShowModal] = useState(false);
  const supportMessages = useSelector((state) => state.contactSupport);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [page, setPage] = useState(1);
  const [reply, setReply] = useState("");
  const [replyError, setReplyError] = useState("");
  const [message, setMessage] = useState({
    userType: "all",
    user: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState({
    userType: "",
    user: "",
    subject: "",
    message: "",
  });
  const [messageDetails, setMessageDetails] = useState({});
  const [users, setUsers] = useState([]);
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    status: "",
    startDate: moment().subtract(11, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // const emails = (
    //  ;
    // setOptions(emails);
  }, [supportMessages, users]);

  const handleSearch = (searchText) => {
    setMessage({
      ...message,
      user: searchText,
    });
    dispatch(ContactSupportAction.getUserEmails(searchText));
  };

  const handleSelect = (data) => {
    if (users.length === 50) {
      setError({
        ...error,
        user: "Maximum 50 user emails can be added",
      });
    } else {
      setError({
        ...error,
        user: "",
      });
      setMessage({
        ...message,
        user: "",
      });
      setUsers([...users, data]);
    }
  };

  console.log("Message: ", message.message)

  const handleSendMessage = () => {
    if (!message.userType) {
      setError({
        ...error,
        userType: "User type is required",
      });
    } else if (message.userType === "manually" && users.length === 0) {
      setError({
        ...error,
        user: "Select users to send message",
      });
    } else if (!message.subject) {
      setError({
        ...error,
        userType: "",
        subject: "Subject is required",
      });
    } else if (message.subject.length < 10 || message.subject.length > 250) {
      setError({
        ...error,
        userType: "",
        subject: "Subject length can be from 10 - 250 characters",
      });
    } else if (!message.message) {
      setError({
        ...error,
        subject: "",
        message: "Message is required",
      });
    } else if (message.message.length < 10 || message.message.length > 1000) {
      setError({
        ...error,
        subject: "",
        message: "Message length can be from 10 - 10000 characters",
      });
    } else {
      setError({
        ...error,
        subject: "",
        message: "",
      });
      const body = {
        userGroup: message.userType,
        email: message.userType !== "manually" ? [] : users,
        subject: message.subject,
        body: message.message.replace(/\n|\r/g, "<br/><br/>"),
      };
      dispatch(
        ContactSupportAction.sendCustomEmail(body, () => {
          setShowModal(false);
          setOptions([]);
          setUsers([]);
          setMessage({
            userType: "all",
            subject: "",
            message: "",
            user: "",
          });
          setError({
            userType: "",
            subject: "",
            message: "",
          });
        })
      );
    }
  };

  const handleSendReply = () => {
    if (!reply) {
      setReplyError("Reply text is required");
    } else {
      setReplyError("");
      const body = {
        reply: reply.replace(/\n|\r/g, "<br/><br/>"),
        email: messageDetails?.user?.email,
      };
      const id = messageDetails.id;
      dispatch(
        ContactSupportAction.sendSupportMessageReply(id, body, () => {
          setReply("");
          setShowMessageModal(false);
          dispatch(ContactSupportAction.getAllSupportMessages(state));
        })
      );
    }
  };

  const handleRemoveUser = (index) => {
    const newEmails = [...users];
    newEmails.splice(index, 1);
    setUsers(newEmails);
  };

  const handleDeleteMessage = (id) => {
    dispatch(
      ContactSupportAction.deleteSupportMessage(id, () => {
        setShowMessageModal(false);
        dispatch(ContactSupportAction.getAllSupportMessages(state));
      })
    );
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  useEffect(() => {
    dispatch(ContactSupportAction.getAllSupportMessages(state));
  }, [state]);

  return (
    <>
      <Box>
        <Row justify="space-between">
          <Col className="flex-row-start">
            <Text.Heading
              fontSize={isMobile ? 24 : 20}
              fontFamily={Fonts["Poppins-SemiBold"]}
              color={Colors.Black}
              fontWeight={600}
              text={"Contact Support"}
            />
          </Col>
          <Col
            lg={15}
            md={15}
            sm={21}
            xs={24}
            className={
              isTablet
                ? "flex-end"
                : isMobile
                ? "flex-row-start mt-20"
                : "row-flex-center mt-20"
            }
          >
            <Row justify="space-between">
              <Col>
                <Picker.AntdDatePicker
                  className="mr-10"
                  value={[
                    moment(state.startDate).startOf("month"),
                    moment(state.endDate).endOf("month"),
                  ]}
                  disabledDate={(current) => {
                    var startDate = moment().subtract(12, "month");
                    var endDate = moment();
                    return current < startDate || current > endDate;
                  }}
                  month
                  format={"DD-MM-YYYY"}
                  allowClear={false}
                  handleChange={(date) => {
                    setState({
                      ...state,
                      startDate: moment(date[0])
                        .startOf("month")
                        ?.format("YYYY-MM-DD"),
                      endDate: moment(date[1])
                        .endOf("month")
                        ?.format("YYYY-MM-DD"),
                    });
                  }}
                  containerStyle={{ margin: 5 }}
                />
              </Col>
              <Col>
                <TextField.SelectField
                  className="mr-10"
                  placeholder="Select User Type"
                  size="large"
                  id="provider-management-select"
                  type="text"
                  disabled={false}
                  required={false}
                  //   value={serviceFilter}
                  label=""
                  defaultValue="All"
                  error={false}
                  helperText=""
                  onChange={(value) => {
                    setState({
                      ...state,
                      status: value,
                    });
                  }}
                  data={[
                    {
                      label: "All",
                      value: "",
                    },
                    {
                      label: "Replied",
                      value: "1",
                    },
                    {
                      label: "Pending",
                      value: "0",
                    },
                  ]}
                />
              </Col>
              <Col>
                <Button.Basic
                  onClick={() => setShowModal(true)}
                  text={"Create Message"}
                  startIcon={<Icon.IoIosCreate color="white" />}
                  className="message-btn mr-10"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between" className="mt-20">
          <Col span={24}>
            <Table.AntDesignTable
              className="contacts-table"
              columns={[
                {
                  title: "Image",
                  dataIndex: "imageThumbUrl",
                  key: "imageThumbUrl",
                  render: (text, record) => (
                    <span>
                      <img
                        className="contact-avatar"
                        src={record?.user?.imageThumbUrl}
                      />
                    </span>
                  ),
                },
                {
                  title: "Sender Name",
                  dataIndex: "name",
                  key: "name",
                  render: (text, record) => {
                    return (
                      <span>
                        {record?.user?.fullName?.length > 20
                          ? `${record?.user?.fullName?.slice(0, 20)}...`
                          : record?.user?.fullName}
                      </span>
                    );
                  },
                },
                {
                  title: "Role",
                  dataIndex: "role",
                  key: "role",
                  render: (text, record) => {
                    return <span>{record?.user?.type?.split(" ")[1]}</span>;
                  },
                },
                {
                  title: "Contact Number",
                  dataIndex: "contactNo",
                  key: "contactNo",
                  render: (text, record) => {
                    return <span>{text ? text : "-"}</span>;
                  },
                },
                {
                  title: "Date/Time",
                  dataIndex: "createdAt",
                  key: "createdAt",
                  render: (text) => {
                    return (
                      <span>
                        {moment(text).calendar(null, {
                          sameDay: "[Today] [at] hh:mm a",
                          nextDay: "[Tomorrow] [at] hh:mm a",
                          lastDay: "[Yesterday] [at] hh:mm a",
                          sameElse: "DD MMMM [at] hh:mm a",
                        })}
                      </span>
                    );
                  },
                },
                {
                  title: "Message",
                  dataIndex: "message",
                  key: "message",
                  render: (text) => {
                    return <span>{text}</span>;
                  },
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  render: (text, record) => {
                    return (
                      <div
                        className="contact-status"
                        style={{
                          backgroundColor: text ? Colors.Green : Colors.Primary,
                        }}
                      >
                        {!text ? "Pending" : "Replied"}
                      </div>
                    );
                  },
                },
                {
                  tite: "",
                  dataIndex: "",
                  key: "",
                  render: (text, record) => {
                    return (
                      <img
                        src={Images.ViewIcon}
                        onClick={() => {
                          setShowMessageModal(true);
                          setMessageDetails(record);
                        }}
                        width="40"
                      />
                    );
                  },
                },
              ]}
              data={supportMessages?.supportMessages}
              pagination={true}
              rowKey="key"
              loading={supportMessages.isLoading}
            />
            <Row justify="center" className="mt-20">
              <Col>
                <Pagination
                  responsive="true"
                  showLessItems="true"
                  pageSize={10}
                  current={page}
                  onChange={handlePaginationChange}
                  total={supportMessages?.count}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Box>
      {showModal && (
        <Modal.AntDesignModal
          className="ant-modal"
          centered={true}
          title=""
          isModalVisible={showModal}
          handleCancel={() => {
            setShowModal(false);
            setOptions([]);
            setUsers([]);
            setMessage({
              userType: "all",
              subject: "",
              message: "",
              user: "",
            });
            setError({
              userType: "",
              subject: "",
              message: "",
            });
          }}
        >
          <Row justify="center">
            <Col>
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={"Create Message"}
              />
            </Col>
          </Row>
          <Row justify="center" className="mt-10">
            <Col span={24} className="contact-type-select flex-col">
              <Text.Heading
                fontSize={16}
                fontFamily={Fonts["Poppins-Regular"]}
                color={Colors.TextColor}
                fontWeight={400}
                text={"Select User Type:"}
                textAlign="left"
              />
              <TextField.SelectField
                placeholder="Select Users"
                label="Select User"
                size="large"
                id="provider-management-select"
                type="text"
                disabled={false}
                required={false}
                value={message.userType}
                error={false}
                helperText=""
                onChange={(value) => {
                  setMessage({
                    ...message,
                    userType: value,
                  });
                }}
                data={[
                  {
                    label: "All",
                    value: "all",
                  },
                  {
                    label: "Service users",
                    value: "service-users",
                  },
                  {
                    label: "Service providers",
                    value: "service-providers",
                  },
                  {
                    label: "Manually",
                    value: "manually",
                  },
                ]}
              />
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={error?.userType}
                textAlign="left"
              />
            </Col>
          </Row>
          {message.userType === "manually" && (
            <>
              <Row justify="center" className="mt-20">
                <Col span={24} className="autocomplete-field">
                  <TextField.Autocomplete
                    placeholder={"User"}
                    label="Select User: "
                    options={
                      users.length
                        ? supportMessages?.userEmails
                            .filter((item) =>
                              users.every((v) => v !== item.email)
                            )
                            .map((item) => ({
                              value: item.email,
                            }))
                        : supportMessages?.userEmails.map((item) => ({
                            value: item.email,
                          }))
                    }
                    style={{
                      width: "100%",
                    }}
                    value={message.user}
                    handleSearch={handleSearch}
                    handleSelect={handleSelect}
                  />
                  <Text.Heading
                    fontSize={14}
                    fontFamily={Fonts["Poppins-Regular"]}
                    color={"red"}
                    fontWeight={400}
                    text={error?.user}
                    textAlign="center"
                  />
                </Col>
              </Row>
              {users.length > 0 && (
                <Row className="mt-20 email-div">
                  {users.map((item, index) => (
                    <Col className="email-col">
                      <Text.Heading
                        fontSize={12}
                        fontFamily={Fonts["Poppins-Medium"]}
                        color={Colors.DarkGrayText}
                        fontWeight={500}
                        text={item}
                        textAlign="center"
                      />
                      <Icon.MdClose
                        className="ml-5"
                        cursor={"pointer"}
                        onClick={() => handleRemoveUser(index)}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}
          <Row justify="center" className="mt-20">
            <Col span={24}>
              <TextField.CustomInput
                label="Subject: "
                placeholder="Notification"
                value={message.subject}
                size="large"
                type="text"
                className="sub-admin-input"
                onChange={(e) => {
                  setMessage({
                    ...message,
                    subject: e.target.value,
                  });
                }}
              />
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={error?.subject}
                textAlign="center"
              />
            </Col>
          </Row>
          <Row justify="center" className="mt-20 flex-col">
            <Col span={24}>
              <Text.Heading
                fontSize={16}
                fontFamily={Fonts["Poppins-Regular"]}
                color={Colors.TextColor}
                fontWeight={400}
                text={"Message:"}
                textAlign="left"
              />
              <TextField.Textarea
                value={message.message}
                placeholder={"Your message"}
                rows={6}
                handleChange={(e) => {
                  setMessage({
                    ...message,
                    message: e.target.value,
                  });
                }}
              />
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={error?.message}
                textAlign="center"
                className="mb-10"
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Button.Basic
                text={"Submit"}
                onClick={handleSendMessage}
                className="sub-admin-add-btn mt-20"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
      {showMessageModal && (
        <Modal.AntDesignModal
          className="modal-lg"
          centered={true}
          title=""
          isModalVisible={showMessageModal}
          handleCancel={() => {
            setShowMessageModal(false);
            setMessageDetails({});
          }}
        >
          <Row className="flex-space-center">
            <Col>
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={"Message Details"}
              />
            </Col>
            <Col>
              <Icon.BiTrash
                color="#FF0023"
                fontSize={"26px"}
                cursor={"pointer"}
                onClick={() => handleDeleteMessage(messageDetails.id)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text.Heading
                fontSize={16}
                fontFamily={Fonts["Poppins-Medium"]}
                color={Colors.grayText}
                fontWeight={500}
                text={moment(messageDetails.createdAt).calendar(null, {
                  sameDay: "[Today] [at] hh:mm a",
                  nextDay: "[Tomorrow] [at] hh:mm a",
                  lastDay: "[Yesterday] [at] hh:mm a",
                  sameElse: "DD MMMM [at] hh:mm a",
                })}
                textAlign="left"
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24} className="flex-row mt-20">
              <Col>
                <img
                  src={messageDetails?.user?.imageThumbUrl}
                  alt=""
                  className="service-user-email-img"
                />
              </Col>
              <Col>
                <div className="flex-column ml-20">
                  <Text.Heading
                    fontSize={18}
                    fontFamily={Fonts["Poppins-Bold"]}
                    color={Colors.Black}
                    fontWeight={600}
                    text={
                      messageDetails?.user?.fullName?.length > 25
                        ? `${messageDetails?.user?.fullName?.slice(0, 25)}...`
                        : messageDetails?.user?.fullName
                    }
                    textAlign="left"
                  />
                  <Text.Heading
                    fontSize={16}
                    fontFamily={Fonts["Poppins-Medium"]}
                    color={Colors.Black}
                    fontWeight={500}
                    text={messageDetails?.user?.email}
                    textAlign="left"
                  />
                </div>
              </Col>
            </Col>
          </Row>
          <Row className="mt-40">
            <Col span={24}>
              <Text.Heading
                fontSize={16}
                fontFamily={Fonts["Poppins-Regular"]}
                color={Colors.TextColor2}
                fontWeight={400}
                text={messageDetails.message}
                textAlign="left"
              />
            </Col>
          </Row>
          {messageDetails.supportMessageReplies.length > 0 && (
            <div className="mt-20">
              <Text.Heading
                fontSize={20}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={"Your Replies"}
              />
              <Row className="mt-20 reply-div">
                {messageDetails.supportMessageReplies.map((item, index) => (
                  <Col className="reply-col" span={24}>
                    <Text.Heading
                      fontSize={16}
                      fontFamily={Fonts["Poppins-Medium"]}
                      color={Colors.DarkGrayText}
                      fontWeight={500}
                      text={item.reply}
                      textAlign="left"
                    />
                    <Text.Heading
                      fontSize={12}
                      fontFamily={Fonts["Poppins-Medium"]}
                      color={Colors.grayText}
                      fontWeight={500}
                      text={moment(item.createdAt).calendar(null, {
                        sameDay: "[Today] [at] hh:mm a",
                        nextDay: "[Tomorrow] [at] hh:mm a",
                        lastDay: "[Yesterday] [at] hh:mm a",
                        sameElse: "DD MMMM [at] hh:mm a",
                      })}
                      textAlign="left"
                    />
                  </Col>
                ))}
              </Row>
            </div>
          )}
          <Row className="mt-40 modal-textarea">
            <Col span={24}>
              <TextField.Textarea
                value={reply}
                placeholder={"Write your message here"}
                rows={6}
                handleChange={(e) => setReply(e.target.value)}
              />
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={replyError}
                textAlign="center"
              />
            </Col>
          </Row>
          <Row className="mt-20 flex-end">
            <Col>
              <Button.Basic
                onClick={handleSendReply}
                text={"Send"}
                startIcon={<Icon.AiOutlineSend color="white" />}
                className="message-btn w-500 mr-10"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
    </>
  );
}

export default ContactSupport;
