import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Pagination } from ".."

const Simple = ({ data, tableStyle, headStyle, bodyStyle, rowStyle, cellStyle, alignTitle, alignContent, pageSize, rowsPerPageOptions, containerStyle, paginationStyle, paginationCount, paginationColor, onChangePage, defaultPage, ...props }) => {

    return (
        <>
            {/* <div style={{ width: "80vw", padding: "20px" }}> */}
            <div style={containerStyle}>
                <Table style={tableStyle}>
                    <TableHead style={headStyle}>
                        <TableRow style={rowStyle}>
                            {data.map((column, index) => (
                                <TableCell
                                    style={cellStyle}
                                    key={index}
                                    align={alignTitle}
                                >
                                    {column.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={bodyStyle}>
                        {data[0].values.map((_, rowIndex) => (
                            <TableRow style={rowStyle} key={rowIndex}>
                                {data.map((row, colIndex) => (
                                    <TableCell
                                        style={cellStyle}
                                        key={colIndex}
                                        align={alignContent}
                                    >
                                        {row.values[rowIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination.Basic
                    count={paginationCount}
                    color={paginationColor || "primary"}
                    paginationStyle={{ padding: "20px 0", ...paginationStyle }}
                    onChange={onChangePage}
                    defaultPage={defaultPage || 1}
                />
            </div>
        </>
    )
}

export default Simple;
