import { AiFillPlusCircle } from "react-icons/ai";
import { BsArrowLeftShort } from "react-icons/bs";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import {
  Box,
  Button,
  Loader,
  Modal,
  Table,
  Text,
  TextField,
} from "../../components";
import { Colors, Fonts, Images } from "../../config";
import { ServiceAction } from "../../store/actions";
import "./style.css";

function ServiceDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { subServices } = useSelector((state) => state.service);

  const state = location.state.record;

  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editService, setEditService] = useState(false);
  const [isSubServicesDirty, setIsSubServicesDirty] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [selectedSubServices, setSelectedSubServices] = useState([]);
  const [error, setError] = useState({
    id: "",
    name: "",
    subService: [""],
    image: "",
  });
  const [service, setService] = useState({
    serviceName: "",
    subServices: [{}],
    serviceImage: null,
  });

  const handleAddMoreSubservices = () => {
    let err = [...error.subService];
    var bool = false;
    selectedSubServices.forEach((subService, index) => {
      if (subService.name.trim() !== "") {
        err[index] = "";
      } else {
        bool = true;
        err[index] = "Please enter sub service name";
      }
    });
    setError({ ...error, subService: err });
    if (!bool) {
      setSelectedSubServices([
        ...selectedSubServices,
        {
          id: service.subServices.length + Math.floor(Math.random() * 1000),
          name: "",
          new: true,
          noOfUsers: null,
          index: null,
          isEdit: true,
        },
      ]);
    }
  };

  const handleDeleteSubservice = (id) => {
    dispatch(
      ServiceAction.deleteSubService(id, () => {
        setDeleteModal(false);
        setSelectedService({});
        setIsSubServicesDirty(true);
      })
    );
  };

  const handleAddSubservice = () => {
    if (service.serviceName === "") {
      setError({ ...error, name: "Service name is required" });
    } else {
      let err = [...error.subService];
      var bool = false;
      selectedSubServices.forEach((subService, index) => {
        if (subService.name.trim() !== "") {
          err[index] = "";
        } else {
          bool = true;
          err[index] = "Please enter sub service name";
        }
      });
      if (bool == true) {
        setError({ ...error, name: "", subService: err });
        return;
      } else {
        setError({ ...error, subService: err });
        const formData = new FormData();
        formData.append("name", service.serviceName);
        selectedSubServices.forEach((subService, index) => {
          formData.append(`subServices[]`, subService.name);
        });
        formData.append("picture", service.serviceImage);
        dispatch(
          ServiceAction.updateService(state.id, formData, () => {
            setShowModal(false);
            setEditService(false);
            setIsSubServicesDirty(true);
          })
        );
      }
    }
  };

  useEffect(() => {
    if (state) {
      setService({
        serviceName: state.name,
        subServices: state.subServices.map((subService, index) => {
          return {
            ...subService,
            new: false,
          };
        }),
        serviceImage: state.imageUrl,
      });
    }
  }, []);

  useEffect(() => {
    if (isSubServicesDirty) {
      dispatch(
        ServiceAction.getAllSubServices(state.id, (res) => {
          setService({ ...service, subServices: res.data.subServices });
          setIsSubServicesDirty(false);
        })
      );
    }
  }, [isSubServicesDirty]);
  return (
    <>
      <Box>
        <Row justify="space-between">
          <Col className="row-flex-start">
            <BsArrowLeftShort
              onClick={() => navigate("/service-management")}
              className="service-details-back-btn"
            />
            <Text.Heading
              fontSize={24}
              fontFamily={Fonts["Poppins-SemiBold"]}
              color={Colors.Black}
              fontWeight={600}
              text={service.serviceName}
            />
          </Col>
          <Col>
            <Button.Basic
              onClick={() => {
                setEditService(false);
                setShowModal(true);
                setSelectedSubServices([...service.subServices]);
              }}
              text={"Add Sub Service"}
              startIcon={<AiFillPlusCircle color="white" />}
              className="sub-service-add-btn"
            />
          </Col>
        </Row>
        <Row justify="space-between" className="mt-20">
          {isSubServicesDirty ? (
            <Col span={24} className="row-flex-center mt-20">
              <Loader.Circular />
            </Col>
          ) : (
            <Col span={24}>
              <Table.AntDesignTable
                className="service-details-table"
                columns={[
                  {
                    title: "Sub Service Name",
                    dataIndex: "name",
                    key: "name",
                    render: (text, record) => {
                      return <span>{text}</span>;
                    },
                  },
                  {
                    title: "Number Of User",
                    dataIndex: "noOfUsers",
                    key: "noOfUsers",
                    render: (text, record) => {
                      return <span>{text === null ? "-" : text}</span>;
                    },
                  },
                  {
                    title: "Action",
                    dataIndex: "subscription",
                    key: "subscription",
                    render: (text, record, outerIndex) => (
                      <span>
                        <img
                          src={Images.EditIcon}
                          onClick={() => {
                            setSelectedSubServices([
                              ...service.subServices.map((el) =>
                                el.id === record.id
                                  ? { ...el, isEdit: true }
                                  : el
                              ),
                            ]);
                            setEditService(record);
                            setShowModal(true);
                          }}
                        />{" "}
                        {service.subServices.length > 1 && (
                          <img
                            onClick={() => {
                              setSelectedService(record);
                              setDeleteModal(true);
                            }}
                            src={Images.DeleteIcon}
                          />
                        )}
                      </span>
                    ),
                  },
                ]}
                data={service.subServices}
                pagination={true}
                rowKey="key"
                loading={isSubServicesDirty}
              />
            </Col>
          )}
        </Row>
        {/* <Row justify="center" style={{ marginTop: "20px" }}>
          <Col>
            <Pagination
              responsive="true"
              showLessItems="true"
              pageSize={5}
              total={service.subServices.length}
              onChange={() => {}}
            />
          </Col>
        </Row> */}
      </Box>
      {deleteModal && (
        <Modal.ConfirmationModal
          title={""}
          isModalVisible={deleteModal}
          heading={"Are you sure you want to delete sub service?"}
          handleCancel={() => setDeleteModal(false)}
          handleDelete={() => {
            setSelectedSubServices((prev) =>
              prev.filter((subServ) => subServ.id !== selectedService.id)
            );
            handleDeleteSubservice(selectedService.id);
          }}
        ></Modal.ConfirmationModal>
      )}
      {showModal && (
        <Modal.AntDesignModal
          title=""
          isModalVisible={showModal}
          handleCancel={() => {
            setEditService(false);
            setError({
              name: "",
              subService: "",
              image: "",
            });
            setShowModal(false);
          }}
        >
          <Row justify="center">
            <Col>
              <Text.Heading
                fontSize={24}
                fontFamily={Fonts["Poppins-SemiBold"]}
                color={Colors.Black}
                fontWeight={600}
                text={editService ? "Edit Sub Service" : "Add Sub Service"}
              />
            </Col>
          </Row>
          <Row justify="center" className="mt-20">
            <Col span={24}>
              <TextField.CustomInput
                label="Service Name: "
                placeholder="Engine Checkup"
                value={service.serviceName}
                disabled={true}
                size="large"
                type="text"
                className="service-input"
                onChange={(e) =>
                  setService({ ...service, serviceName: e.target.value })
                }
              />
              <Text.Heading
                fontSize={14}
                fontFamily={Fonts["Poppins-Regular"]}
                color={"red"}
                fontWeight={400}
                text={error.name}
                textAlign="center"
                className="mb-10"
              />
            </Col>
          </Row>
          {selectedSubServices.map((subService, index) => {
            return (
              <Row key={index} justify="center" className="mt-20 mb-10">
                <Col span={24}>
                  <TextField.CustomInput
                    label={
                      index === 0
                        ? `Sub Service Name`
                        : `Sub Service Name ${index + 1}`
                    }
                    placeholder="Enter Sub Service Name"
                    value={subService.name}
                    disabled={subService?.isEdit ? false : true}
                    size="large"
                    type="text"
                    className="service-input"
                    suffix={
                      (subService?.isEdit && !subService?.new ? false : true) &&
                      selectedSubServices.length > 1 && (
                        <img
                          className="cursor"
                          onClick={() => {
                            if (subService.new) {
                              setSelectedSubServices((prev) =>
                                prev.filter(
                                  (subServ) => subServ.id !== subService.id
                                )
                              );
                            } else {
                              const deletedSubservice =
                                selectedSubServices.find(
                                  (sub) => sub.id === subService.id
                                );
                              setSelectedService(deletedSubservice);
                              setDeleteModal(true);
                            }
                          }}
                          src={Images.DeleteSubServiceIcon}
                          width={20}
                        />
                      )
                    }
                    onChange={(e) => {
                      let selectedSubServicesCopy = JSON.parse(
                        JSON.stringify(selectedSubServices)
                      );
                      let newService = {
                        ...selectedSubServices[index],
                        name: e.target.value,
                      };
                      selectedSubServicesCopy[index] = newService;
                      setSelectedSubServices([...selectedSubServicesCopy]);
                    }}
                  />
                  <Text.Heading
                    fontSize={14}
                    fontFamily={Fonts["Poppins-Regular"]}
                    color={"red"}
                    fontWeight={400}
                    text={error.subService[index]}
                    textAlign="center"
                    className="mb-10"
                  />
                </Col>
              </Row>
            );
          })}

          {selectedSubServices.length < 20 && editService === false && (
            <Col className="row-flex-end">
              <Button.Basic
                startIcon={<img src={Images.AddSquareIcon} alt="" />}
                text={"Add More"}
                className="sub-service-add-btn-modal"
                onClick={handleAddMoreSubservices}
              />
            </Col>
          )}
          <Row justify="center">
            <Col span={24}>
              <Button.Basic
                text={"Submit"}
                onClick={handleAddSubservice}
                className="sub-service-add-btn mt-20"
              />
            </Col>
          </Row>
        </Modal.AntDesignModal>
      )}
    </>
  );
}

export default ServiceDetails;
