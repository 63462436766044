import Autocomplete from "./Autocomplete";
import Basic from "./Basic";
import IconField from "./IconField";
import InputWrapper from "./CustomText";
import SearchTextField from "./SearchTextField";
import SelectField from "./SelectField";
import Textarea from "./Textarea";

export default {
  Basic,
  SelectField,
  SearchTextField,
  IconField,
  CustomInput: InputWrapper,
  Textarea,
  Autocomplete,
};
