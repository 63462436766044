import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import ReactCodeInput from "react-code-input";
import useMedia from "use-media";

import { AuthAction } from "../../store/actions";
import { Button, ContainerBox, Text } from "../../components";
import { Colors, Fonts, Images, Utils } from "../../config";

const VerifyOTP = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [otpCode, setOtpCode] = useState("");
  const [otpCodeErrMsg, setOtpCodeErrMsg] = useState("");
  const [counter, setCounter] = useState(60);

  const isMobile = useMedia({ minWidth: "450px" });
  const isLaptop = useMedia({ minWidth: "1200px" });

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleResendOtp = () => {
    const body = {
      email: location.state.email,
    };
    dispatch(
      AuthAction.forgotPassword(body, (response) => {
        Utils.showSnackBar({
          message: "OTP sent to your email",
        });
        setCounter(60);
      })
    );
  };

  const verifyCode = () => {
    if (otpCode.length === 0) {
      setOtpCodeErrMsg("Please enter code");
    } else if (otpCode.length < 4 && otpCode.length > 0) {
      setOtpCodeErrMsg("Incomplete Code");
    } else {
      setOtpCodeErrMsg("");
      const body = {
        code: parseInt(otpCode),
        email: location.state.email,
      };
      dispatch(
        AuthAction.verifyCode(body, (response) => {
          navigate("/reset-password", {
            state: {
              email: location.state.email,
            },
          });
        })
      );
    }
  };

  const handleChange = (val) => {
    setOtpCode(val);
  };

  return (
    <ContainerBox>
      <Row justify="center" style={{ width: "100%" }}>
        <Col
          xl={8}
          lg={9}
          md={12}
          sm={14}
          xs={21}
          style={{
            backgroundColor: Colors.White,
            borderRadius: "10px",
          }}
        >
          <Row
            justify="center"
            gutter={[0, 24]}
            style={{ width: "100%", marginBlock: "35px" }}
          >
            <Col>
              <img src={Images.Logo} alt="logo"></img>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Text.Heading
                fontSize={isMobile ? 30 : 24}
                fontFamily={Fonts["Poppins-Bold"]}
                color={Colors.Black}
                fontWeight={700}
                text={"Enter the 4 Digit Code"}
              />
              <Text.Heading
                fontSize={isMobile ? 15 : 13}
                fontFamily={Fonts["Poppins-Regular"]}
                color={Colors.LightTextColor}
                fontWeight={400}
                textALign={"center"}
                style={{
                  marginInline: isLaptop ? "20%" : isMobile ? "15%" : "10%",
                }}
                text={
                  "Please enter the 4-digit OTP you have received on your email"
                }
              />
            </Col>
            <Col
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <ReactCodeInput
                type="number"
                inputStyle={{
                  height: isLaptop ? "65px" : isMobile ? "60px" : "55px",
                  width: isLaptop ? "65px" : isMobile ? "60px" : "55px",
                  textAlign: "center",
                  fontFamily: Fonts["Poppins-Bold"],
                  fontWeight: "bold",
                  margin: isMobile ? "10px" : "5px",
                  MozAppearance: "textfield",
                  borderRadius: "12px",
                  fontSize: isMobile ? "30px" : "24px",
                  backgroundColor: "white",
                  color: Colors.TextColor,
                  border: "0.800000011920929px solid #B9B9B9",
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Col>
            {otpCodeErrMsg !== "" && (
              <Col
                span={18}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Text.ErrorText
                  style={{ width: "100%" }}
                  textAlign={"center"}
                  text={otpCodeErrMsg}
                />
              </Col>
            )}
            <Col span={18}>
              <Button.Basic
                variant="outlined"
                style={{
                  backgroundColor: Colors.Primary,
                  textTransform: "capitalize",
                  color: Colors.White,
                  width: "100%",
                  borderRadius: 8,
                  borderColor: Colors.Transparent,
                  fontFamily: `${Fonts["Poppins-Regular"]}`,
                }}
                text="Continue"
                onClick={verifyCode}
                containerStyle={{ margin: "10px 0" }}
                size="large"
              />
            </Col>
            <Col span={24}>
              {counter > 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "auto",
                  }}
                >
                  <Text.SubHeading
                    text={"Resend OTP in "}
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "15px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  >
                    {"  "}0:{counter < 10 ? `0${counter}` : counter}
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "auto",
                  }}
                  onClick={handleResendOtp}
                >
                  <Text.SubHeading
                    text={"Didn't receive any code?"}
                    style={{
                      cursor: "pointer",
                      marginRight: "5px",
                      fontSize: isMobile ? "15px" : "12px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: isMobile ? "15px" : "12px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    {"  "}Resend Code
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerBox>
  );
};

export default VerifyOTP;
