const Colors = {
  PlaceHolder: (opacity = "0.5") => `rgba(235, 235, 235, ${opacity})`,
  BlackOpacity: (opacity = "0.5") => `rgba(0, 0, 0, ${opacity})`,
  WhiteOpacity: (opacity = "0.5") => `rgba(255, 255, 255, ${opacity})`,
  Black: "#000000",
  Transparent: "transparent",
  Primary: "#FAB700",
  Secondary: "#000000",
  DarkGray: "#1E1E22",
  DarkGrayText: "#42425B",
  LightGrayText: "#8E8E8E",
  White: "#ffffff",
  TextColor: "#05031A",
  LightTextColor: "#707586",
  TextColor2: "#33323B",
  TextColor3: "#272727",
  gray: "#AEAEAE8A",
  grayText: "#9DA3A5",
  Danger: "#FF494C",
  ShadowColor: "#0000001A",
  dropdownBgColor: "#F1F6FA",
  dropdownTextColor: "#71828A",
  Green: "#00B517",
  Pink: "#FE6779",
  BlueText: "#0658FD99",
};

export default Colors;
