import {
  GET_ADS_REQUEST,
  GET_ADS_SUCCESS,
  GET_ADS_FAILURE,
  UPDATE_AD_REQUEST,
  UPDATE_AD_SUCCESS,
  UPDATE_AD_FAILURE,
  EDIT_AD_REQUEST,
  EDIT_AD_FAILURE,
  EDIT_AD_SUCCESS,
  DELETE_AD_REQUEST,
  DELETE_AD_SUCCESS,
  DELETE_AD_FAILURE,
} from "../constants";

const initialState = {
  getAdsLoader: false,
  updateAdLoader: false,
  editAdLoader: false,
  deleteAdLoader: false,
  ads: [],
  adsCount: 0,
};

export default function AdManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADS_REQUEST:
      state = {
        ...state,
        getAdsLoader: true,
        ads: [],
      };
      break;
    case GET_ADS_SUCCESS:
      state = {
        ...state,
        getAdsLoader: false,
        ads: action.payload,
        adsCount: action.count,
      };
      break;
    case GET_ADS_FAILURE:
      state = {
        ...state,
        getAdsLoader: false,
      };
      break;

    case UPDATE_AD_REQUEST:
      state = {
        ...state,
        updateAdLoader: true,
      };
      break;
    case UPDATE_AD_SUCCESS:
      state = {
        ...state,
        updateAdLoader: false,
      };
      break;
    case UPDATE_AD_FAILURE:
      state = {
        ...state,
        updateAdLoader: false,
      };
      break;

    case EDIT_AD_REQUEST:
      state = {
        ...state,
        editAdLoader: true,
      };
      break;
    case EDIT_AD_SUCCESS:
      state = {
        ...state,
        editAdLoader: false,
      };
      break;
    case EDIT_AD_FAILURE:
      state = {
        ...state,
        editAdLoader: false,
      };
      break;

    case DELETE_AD_REQUEST:
      state = {
        ...state,
        deleteAdLoader: true,
        error: null,
      };
      break;
    case DELETE_AD_SUCCESS:
      state = {
        ...state,
        deleteAdLoader: false,
      };
      break;
    case DELETE_AD_FAILURE:
      state = {
        ...state,
        deleteAdLoader: false,
      };
      break;

    default:
      break;
  }
  return state;
}
