import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const Toggle = ({ data, orientation, color, value, onChange, containerStyle, toggleViewStyle, toggleStyle, sx }) => {
    return (
        <div style={containerStyle}>
            <ToggleButtonGroup
                orientation={orientation} //"vertical"
                color={color || "primary"} // 'standard' | 'primary' | 'secondary'| 'error' | 'info'| 'success'| 'warning'
                value={value} // give array of values for multple selection
                onChange={onChange}
                style={toggleViewStyle}
                exclusive
            >
                {data.map((toggle) => {
                    return (
                        <ToggleButton
                            sx={sx}
                            style={toggleStyle}
                            value={toggle.value}
                        >
                            {toggle?.leftIcon}
                            {toggle.label}
                            {toggle?.rightIcon}
                        </ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
        </div>
    );
}

export default Toggle
