import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { SubAdminAction } from "../actions";

export default class SubAdminMiddleware {
  static *getSubAdmins(params) {
    const { cb, payload } = params;
    const { limit, offset } = payload;
    try {
      let res = yield ApiCaller.Get(
        `/admin/sub-admin?limit=${limit}&offset=${offset}`
      );

      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(SubAdminAction.getAllSubAdminsSuccess(res?.data));
      } else {
        yield put(
          SubAdminAction.getAllSubAdminsFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(SubAdminAction.getAllSubAdminsFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *addSubAdmin(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(`/admin/sub-admin`, payload);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(res);
        }
        yield put(SubAdminAction.addSubAdminSuccess(res?.data));
      } else {
        yield put(SubAdminAction.addSubAdminFailure(res?.data?.error?.message));
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(res);
        }
      }
    } catch (err) {
      yield put(SubAdminAction.addSubAdminFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *deleteSubAdmin(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/sub-admin/${id}`);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(true);
        }
        yield put(SubAdminAction.deleteSubAdminSuccess(res?.data));
      } else {
        yield put(
          SubAdminAction.deleteSubAdminFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(SubAdminAction.deleteSubAdminFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
  static *updateSubAdmin(params) {
    const { id, payload, cb } = params;
    try {
      let res = yield ApiCaller.Put(`/admin/sub-admin/${id}`, payload);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res?.data?.message });
        if (cb) {
          cb(res);
        }
        yield put(SubAdminAction.updateSubAdminSuccess(res?.data));
      } else {
        yield put(
          SubAdminAction.updateSubAdminFailure(res?.data?.error?.message)
        );
        Utils.showSnackBar({
          message: res?.data?.error?.message,
          severity: "error",
        });
        if (cb) {
          cb(res);
        }
      }
    } catch (err) {
      yield put(SubAdminAction.updateSubAdminFailure());
      Utils.showSnackBar({ message: err?.message, severity: "error" });
    }
  }
}
