import { Layout } from "antd";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./dashboardLayout.css";
import NavHeader from "./navHeader.js";

const { Header } = Layout;
const DashboardLayout = ({ expandible, children }) => {
  const location = useLocation();
  const locationPathname = location.pathname;

  return locationPathname !== "/login" ? (
    <Layout className="dashboard-layout">
      <Layout className="dashboard-main">
        <Header className={"dashboard-header"}>
          <NavHeader />
        </Header>
        <div className="dashboard-wrapper">
          <div
            className={`dashboard-main-content`}
            style={{
              boxShadow:
                locationPathname !== "/dashboard"
                  ? "0px 3px 10px #0000001A"
                  : "none",
            }}
          >
            <Outlet />
          </div>
        </div>
      </Layout>
    </Layout>
  ) : null;
};
export default DashboardLayout;
