import {
    GET_ALL_PROMOS, GET_ALL_PROMOS_FAILURE, GET_ALL_PROMOS_SUCCESS,
    EDIT_PROMO, EDIT_PROMO_FAILURE, EDIT_PROMO_SUCCESS,
    DELETE_PROMO, DELETE_PROMO_FAILURE, DELETE_PROMO_SUCCESS,
    UPDATE_PROMO_STATUS, UPDATE_PROMO_STATUS_SUCCESS, UPDATE_PROMO_STATUS_FAILURE, ADD_PROMO, ADD_PROMO_SUCCESS, ADD_PROMO_FAILURE
} from "../constants"

export default class PromoManagementAction {
    static getPromos(payload) {
        return {
            type: GET_ALL_PROMOS,
            payload
        }
    }
    static getPromosSuccess(payload) {
        return {
            type: GET_ALL_PROMOS_SUCCESS,
            payload
        }
    }
    static getPromosFailure(params) {
        return {
            type: GET_ALL_PROMOS_FAILURE,
            params
        }
    }

    static addPromo(payload, cb) {
        return {
            type: ADD_PROMO,
            payload,
            cb
        }
    }
    static addPromoSuccess(params) {
        return {
            type: ADD_PROMO_SUCCESS,
            params
        }
    }
    static addPromoFailure(params) {
        return {
            type: ADD_PROMO_FAILURE,
            params
        }
    }

    static editPromo(payload, cb) {
        return {
            type: EDIT_PROMO,
            payload,
            cb
        }
    }
    static editPromoSuccess(params) {
        return {
            type: EDIT_PROMO_SUCCESS,
            params
        }
    }
    static editPromoFailure(params) {
        return {
            type: EDIT_PROMO_FAILURE,
            params
        }
    }

    static deletePromo(id, cb) {
        return {
            type: DELETE_PROMO,
            id,
            cb
        }
    }
    static deletePromoSuccess(params) {
        return {
            type: DELETE_PROMO_SUCCESS,
            params
        }
    }
    static deletePromoFailure(params) {
        return {
            type: DELETE_PROMO_FAILURE,
            params
        }
    }

    static updatePromoStatus(id, payload, cb) {
        return {
            type: UPDATE_PROMO_STATUS,
            id,
            payload,
            cb
        }
    }
    static updatePromoStatusSuccess(params) {
        return {
            type: UPDATE_PROMO_STATUS_SUCCESS,
            params
        }
    }
    static updatePromoStatusFailure(params) {
        return {
            type: UPDATE_PROMO_STATUS_FAILURE,
            params
        }
    }

}