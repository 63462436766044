import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { ServiceAction } from "../actions";

export default class ServiceMiddleware {
  static *getServices(params) {
    const { cb, payload } = params;
    try {
      let res = yield ApiCaller.Get(
        `/admin/service?limit=${payload.limit}&offset=${payload.offset}`
      );

      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        // Utils.showSnackBar({ message: res.data.message });
        if (cb) {
          cb(true);
        }
        yield put(ServiceAction.getAllServicesSuccess(res.data));
      } else {
        yield put(ServiceAction.getAllServicesFailure(res.data.error.message));
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceAction.getAllServicesFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }
  static *addService(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(`/admin/service`, payload);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res.data.message });
        if (cb) {
          cb(true);
        }
        yield put(ServiceAction.addServiceSuccess(res.data));
      } else {
        yield put(ServiceAction.addServiceFailure(res.data.error.message));
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceAction.addServiceFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }
  static *deleteService(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/service/${id}`);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res.data.message });
        if (cb) {
          cb(true);
        }
        yield put(ServiceAction.deleteServiceSuccess(res.data));
      } else {
        yield put(ServiceAction.deleteServiceFailure(res.data.error.message));
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceAction.deleteServiceFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }
  static *updateService(params) {
    const { id, payload, cb } = params;
    try {
      let res = yield ApiCaller.Patch(`/admin/service/${id}`, payload);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res.data.message });
        if (cb) {
          cb(true);
        }
        yield put(ServiceAction.updateServiceSuccess(res.data));
      } else {
        yield put(ServiceAction.updateServiceFailure(res.data.error.message));
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceAction.updateServiceFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }
  static *deleteSubService(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Delete(`/admin/sub-service/${id}`);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        Utils.showSnackBar({ message: res.data.message });
        if (cb) {
          cb(true);
        }
        yield put(ServiceAction.deleteSubServiceSuccess(res.data));
      } else {
        yield put(
          ServiceAction.deleteSubServiceFailure(res.data.error.message)
        );
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceAction.deleteSubServiceFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }
  static *getSubServices(params) {
    const { id, cb } = params;
    try {
      let res = yield ApiCaller.Get(`/admin/service/${id}/sub-service`);
      if (res == undefined) {
        Utils.showSnackBar({
          message: "No Internet Connection",
          severity: "error"
        })
      } else if (res?.status == 200) {
        yield put(ServiceAction.getAllSubServicesSuccess(res.data));
        Utils.showSnackBar({ message: res.data.message });
        if (cb) {
          cb(res.data);
        }
      } else {
        yield put(
          ServiceAction.getAllSubServicesFailure(res.data.error.message)
        );
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(ServiceAction.getAllSubServicesFailure());
      Utils.showSnackBar({ message: err.message, severity: "error" });
    }
  }
}
