import {
  ADD_SUB_ADMIN,
  ADD_SUB_ADMIN_FAILURE,
  ADD_SUB_ADMIN_SUCCESS,
  DELETE_SUB_ADMIN,
  DELETE_SUB_ADMIN_FAILURE,
  DELETE_SUB_ADMIN_SUCCESS,
  GET_ALL_SUB_ADMINS,
  GET_ALL_SUB_ADMINS_FAILURE,
  GET_ALL_SUB_ADMINS_SUCCESS,
  UPDATE_SUB_ADMIN,
  UPDATE_SUB_ADMIN_FAILURE,
  UPDATE_SUB_ADMIN_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  error: null,
  subAdmins: [],
  subAdmin: {},
  count: 0,
};

export default function SubAdminReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUB_ADMINS:
      state = {
        ...state,
        isLoading: true,
        error: null,
        subAdmins: [],
      };
      break;
    case GET_ALL_SUB_ADMINS_SUCCESS:
      state = {
        ...state,
        subAdmins: action?.payload?.data?.subAdmins,
        count: action?.payload?.data?.count,
        isLoading: false,
      };
      break;
    case GET_ALL_SUB_ADMINS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      break;
    case ADD_SUB_ADMIN:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case ADD_SUB_ADMIN_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case ADD_SUB_ADMIN_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case DELETE_SUB_ADMIN:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case DELETE_SUB_ADMIN_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case DELETE_SUB_ADMIN_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case UPDATE_SUB_ADMIN:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case UPDATE_SUB_ADMIN_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case UPDATE_SUB_ADMIN_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      state = {
        ...state,
      };
      break;
  }
  return state;
}
