import {
  GET_NO_OF_SERVICE_STATS,
  GET_NO_OF_SERVICE_STATS_FAILURE,
  GET_NO_OF_SERVICE_STATS_SUCCESS,
  GET_POST_CREATED_STATS,
  GET_POST_CREATED_STATS_ERROR,
  GET_POST_CREATED_STATS_SUCCESS,
  GET_SERVICE_USER_STATS,
  GET_SERVICE_USER_STATS_FAILURE,
  GET_SERVICE_USER_STATS_SUCCESS,
  GET_SUBSCRIPTION_STATS,
  GET_SUBSCRIPTION_STATS_FAILURE,
  GET_SUBSCRIPTION_STATS_SUCCESS,
  GET_USER_STATS,
  GET_USER_STATS_FAILURE,
  GET_USER_STATS_SUCCESS,
  SERVICE_REQUESTED_STATS,
  SERVICE_REQUESTED_STATS_FAILURE,
  SERVICE_REQUESTED_STATS_SUCCESS,
  GET_COUNT,
  GET_COUNT_SUCCESS,
  GET_COUNT_FAILURE,
  ACTION_REQ, ACTION_REQ_FAILURE, ACTION_REQ_SUCCESS,
  SIGNUPS, SIGNUPS_SUCCESS, SIGNUPS_FAILURE,
  DELETED_ACC, DELETED_ACC_SUCCESS, DELETED_ACC_FAILURE,
  SERVICE_PROVIDER, SERVICE_PROVIDER_SUCCESS, SERVICE_PROVIDER_FAILURE,
  JOBS, JOBS_SUCCESS, JOBS_FAILURE,
  ADS, ADS_SUCCESS, ADS_FAILURE,
  SERVICES, SERVICES_SUCCESS, SERVICES_FAILURE,
  POST_AND_REPORTS, POST_AND_REPORTS_SUCCESS, POST_AND_REPORTS_FAILURE
} from "../constants";

const initialState = {
  isLoading: false,
  userStats: [],
  error: null,
  postStats: [],
  serviceUserStats: [],
  noOfServices: [],
  servicesRequested: [],
  subscriptionStats: [],
  count: [],
  action: [],
  signups: [],
  deletedAcc: [],
  servicePro: [],
  jobs: [],
  ads: [],
  service: [],
  postAndReports: [],
};

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_STATS:
      state = {
        ...state,
        isLoading: true,
        userStats: [],
      };
      break;
    case GET_USER_STATS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        userStats: action.payload,
      };
      break;
    case GET_USER_STATS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_POST_CREATED_STATS:
      state = {
        ...state,
        isLoading: true,
        postStats: [],
      };
      break;
    case GET_POST_CREATED_STATS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        postStats: action.payload,
      };
      break;
    case GET_POST_CREATED_STATS_ERROR:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_SERVICE_USER_STATS:
      state = {
        ...state,
        isLoading: true,
        serviceUserStats: [],
      };
      break;
    case GET_SERVICE_USER_STATS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        serviceUserStats: action.payload,
      };
      break;
    case GET_SERVICE_USER_STATS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_NO_OF_SERVICE_STATS:
      state = {
        ...state,
        isLoading: true,
        noOfServices: [],
      };
      break;
    case GET_NO_OF_SERVICE_STATS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        noOfServices: action.payload,
      };
      break;
    case GET_NO_OF_SERVICE_STATS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case SERVICE_REQUESTED_STATS:
      state = {
        ...state,
        isLoading: true,
        servicesRequested: [],
      };
      break;
    case SERVICE_REQUESTED_STATS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        servicesRequested: action.payload,
      };
      break;
    case SERVICE_REQUESTED_STATS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_SUBSCRIPTION_STATS:
      state = {
        ...state,
        isLoading: true,
        subscriptionStats: [],
      };
      break;
    case GET_SUBSCRIPTION_STATS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        subscriptionStats: action.payload,
      };
      break;
    case GET_SUBSCRIPTION_STATS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_COUNT:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case GET_COUNT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        count: action.payload,
      };
      break;
    case GET_COUNT_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case ACTION_REQ:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case ACTION_REQ_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        action: action.payload,
      };
      break;
    case ACTION_REQ_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case SIGNUPS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case SIGNUPS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        signups: action.payload,
      };
      break;
    case SIGNUPS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case DELETED_ACC:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case DELETED_ACC_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        deletedAcc: action.payload,
      };
      break;
    case DELETED_ACC_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

      case SERVICE_PROVIDER:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case SERVICE_PROVIDER_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        servicePro: action.payload,
      };
      break;
    case SERVICE_PROVIDER_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

      case JOBS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case JOBS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        jobs: action.payload,
      };
      break;
    case JOBS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

      case ADS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case ADS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        ads: action.payload,
      };
      break;
    case ADS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

      case SERVICES:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case SERVICES_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        service: action.payload,
      };
      break;
    case SERVICES_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
      case POST_AND_REPORTS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case POST_AND_REPORTS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        postAndReports: action.payload,
      };
      break;
    case POST_AND_REPORTS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
