import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import DashboardLayout from "../../components/Layout/dashboardLayout";

import {
  ContactSupport,
  Dashboard,
  ForgotPassword,
  ProviderManagement,
  ReportsManagement,
  ResetPassword,
  ServiceDetails,
  ServiceManagement,
  ServiceProviderDetails,
  SignIn,
  SubAdminManagement,
  UserManagement,
  VerifyOTP,
  AdManagement,
  PromoManagement,
} from "../../containers";

const AuthRoutes = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.isLoggedIn);
  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);
  return (
    <div className="App">
      {!user ? (
        <Routes>
          <>
            <Route path="/login" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify-code" element={<VerifyOTP />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        </Routes>
      ) : (
        <Routes>
          <Route element={<DashboardLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route
              path="/provider-management"
              element={<ProviderManagement />}
            />
            <Route
              path="/provider-management/details"
              element={<ServiceProviderDetails />}
            />
            <Route path="/service-management" element={<ServiceManagement />} />
            <Route
              path="/service-management/details"
              element={<ServiceDetails />}
            />
            <Route
              path="/sub-admin-management"
              element={<SubAdminManagement />}
            />
            <Route path="/reports-management" element={<ReportsManagement />} />
            <Route path="/contact-support" element={<ContactSupport />} />
            <Route path="/ad-management" element={<AdManagement />} />
            <Route path="/promo-management" element={<PromoManagement />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Route>
        </Routes>
      )}
    </div>
  );
};

export default AuthRoutes;
