// Login Flow
export const SIGNIN = "SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";

export const VERIFY_CODE = "VERIFY_CODE";
export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const VERIFY_CODE_FAILURE = "VERIFY_CODE_FAILURE";

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";
export const REMOVE_AUTH_ERROR = "REMOVE_AUTH_ERROR";

// service provider
export const GET_ALL_SERVICE_PROVIDERS = "GET_ALL_SERVICE_PROVIDERS";
export const GET_ALL_SERVICE_PROVIDERS_SUCCESS =
  "GET_ALL_SERVICE_PROVIDERS_SUCCESS";
export const GET_ALL_SERVICE_PROVIDERS_FAILURE =
  "GET_ALL_SERVICE_PROVIDERS_FAILURE";

export const GET_SERVICE_PROVIDER_DETAILS = "GET_SERVICE_PROVIDER_DETAILS";
export const GET_SERVICE_PROVIDER_DETAILS_SUCCESS =
  "GET_SERVICE_PROVIDER_DETAILS_SUCCESS";
export const GET_SERVICE_PROVIDER_DETAILS_FAILURE =
  "GET_SERVICE_PROVIDER_DETAILS_FAILURE";

export const UPDATE_SERVICE_PROVIDER_STATUS = "UPDATE_SERVICE_PROVIDER_STATUS";
export const UPDATE_SERVICE_PROVIDER_STATUS_SUCCESS =
  "UPDATE_SERVICE_PROVIDER_STATUS_SUCCESS";
export const UPDATE_SERVICE_PROVIDER_STATUS_FAILURE =
  "UPDATE_SERVICE_PROVIDER_STATUS_FAILURE";

export const SEND_SERVICE_PROVIDER_EMAIL = "SEND_SERVICE_PROVIDER_EMAIL";
export const SEND_SERVICE_PROVIDER_EMAIL_SUCCESS =
  "SEND_SERVICE_PROVIDER_EMAIL_SUCCESS";
export const SEND_SERVICE_PROVIDER_EMAIL_FAILURE =
  "SEND_SERVICE_PROVIDER_EMAIL_FAILURE";

//Service Management
export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_FAILURE = "GET_ALL_SERVICES_FAILURE";

export const GET_ALL_SUB_SERVICES = "GET_ALL_SUB_SERVICES";
export const GET_ALL_SUB_SERVICES_SUCCESS = "GET_ALL_SUB_SERVICES_SUCCESS";
export const GET_ALL_SUB_SERVICES_FAILURE = "GET_ALL_SUB_SERVICES_FAILURE";

export const ADD_SERVICE = "ADD_SERVICE";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAILURE = "ADD_SERVICE_FAILURE";

export const DELETE_SERVICE = "DELETE_SERVICE";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAILURE = "DELETE_SERVICE_FAILURE";

export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAILURE = "UPDATE_SERVICE_FAILURE";

export const DELETE_SUB_SERVICE = "DELETE_SUB_SERVICE";
export const DELETE_SUB_SERVICE_SUCCESS = "DELETE_SUB_SERVICE_SUCCESS";
export const DELETE_SUB_SERVICE_FAILURE = "DELETE_SUB_SERVICE_FAILURE";

// user management
export const GET_ALL_SERVICE_USERS = "GET_ALL_SERVICE_USERS";
export const GET_ALL_SERVICE_USERS_SUCCESS = "GET_ALL_SERVICE_USERS_SUCCESS";
export const GET_ALL_SERVICE_USERS_FAILURE = "GET_ALL_SERVICE_USERS_FAILURE";

export const UPDATE_SERVICE_USER_STATUS = "UPDATE_SERVICE_USER_STATUS";
export const UPDATE_SERVICE_USER_STATUS_SUCCESS =
  "UPDATE_SERVICE_USER_STATUS_SUCCESS";
export const UPDATE_SERVICE_USER_STATUS_FAILURE =
  "UPDATE_SERVICE_USER_STATUS_FAILURE";

export const SEND_SERVICE_USER_EMAIL = "SEND_SERVICE_USER_EMAIL";
export const SEND_SERVICE_USER_EMAIL_SUCCESS =
  "SEND_SERVICE_USER_EMAIL_SUCCESS";
export const SEND_SERVICE_USER_EMAIL_FAILURE =
  "SEND_SERVICE_USER_EMAIL_FAILURE";

//Sub admin Management
export const GET_ALL_SUB_ADMINS = "GET_ALL_SUB_ADMINS";
export const GET_ALL_SUB_ADMINS_SUCCESS = "GET_ALL_SUB_ADMINS_SUCCESS";
export const GET_ALL_SUB_ADMINS_FAILURE = "GET_ALL_SUB_ADMINS_FAILURE";

export const ADD_SUB_ADMIN = "ADD_SUB_ADMIN";
export const ADD_SUB_ADMIN_SUCCESS = "ADD_SUB_ADMIN_SUCCESS";
export const ADD_SUB_ADMIN_FAILURE = "ADD_SUB_ADMIN_FAILURE";

export const DELETE_SUB_ADMIN = "DELETE_SUB_ADMIN";
export const DELETE_SUB_ADMIN_SUCCESS = "DELETE_SUB_ADMIN_SUCCESS";
export const DELETE_SUB_ADMIN_FAILURE = "DELETE_SUB_ADMIN_FAILURE";

export const UPDATE_SUB_ADMIN = "UPDATE_SUB_ADMIN";
export const UPDATE_SUB_ADMIN_SUCCESS = "UPDATE_SUB_ADMIN_SUCCESS";
export const UPDATE_SUB_ADMIN_FAILURE = "UPDATE_SUB_ADMIN_FAILURE";

//Reports management
export const BAN_USER = "BAN_USER";
export const BAN_USER_SUCCESS = "BAN_USER_SUCCESS";
export const BAN_USER_FAILURE = "BAN_USER_FAILURE";

export const SUSPEND_USER = "SUSPEND_USER";
export const SUSPEND_USER_SUCCESS = "SUSPEND_USER_SUCCESS";
export const SUSPEND_USER_FAILURE = "SUSPEND_USER_FAILURE";

//--------profile reports
export const GET_ALL_PROFILES = "GET_ALL_PROFILES";
export const GET_ALL_PROFILES_SUCCESS = "GET_ALL_PROFILES_SUCCESS";
export const GET_ALL_PROFILES_FAILURE = "GET_ALL_PROFILES_FAILURE";

export const IGNORE_PROFILE_REPORT = "IGNORE_PROFILE_REPORT";
export const IGNORE_PROFILE_REPORT_SUCCESS = "IGNORE_PROFILE_REPORT_SUCCESS";
export const IGNORE_PROFILE_REPORT_FAILURE = "IGNORE_PROFILE_REPORT_FAILURE";

//--------comments reports
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const GET_ALL_COMMENTS_SUCCESS = "GET_ALL_COMMENTS_SUCCESS";
export const GET_ALL_COMMENTS_FAILURE = "GET_ALL_COMMENTS_FAILURE";

export const IGNORE_COMMENT_REPORT = "IGNORE_COMMENT_REPORT";
export const IGNORE_COMMENT_REPORT_SUCCESS = "IGNORE_COMMENT_REPORT_SUCCESS";
export const IGNORE_COMMENT_REPORT_FAILURE = "IGNORE_COMMENT_REPORT_FAILURE";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

//-------posts reports
export const GET_ALL_POSTS = "GET_ALL_POSTS";
export const GET_ALL_POSTS_SUCCESS = "GET_ALL_POSTS_SUCCESS";
export const GET_ALL_POSTS_FAILURE = "GET_ALL_POSTS_FAILURE";

export const IGNORE_POST_REPORT = "IGNORE_POST_REPORT";
export const IGNORE_POST_REPORT_SUCCESS = "IGNORE_POST_REPORT_SUCCESS";
export const IGNORE_POST_REPORT_FAILURE = "IGNORE_POST_REPORT_FAILURE";

export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const DELETE_POLL = "DELETE_POLL";
export const DELETE_POLL_SUCCESS = "DELETE_POLL_SUCCESS";
export const DELETE_POLL_FAILURE = "DELETE_POLL_FAILURE";

//Dashboard
export const GET_USER_STATS = "GET_USER_STATS";
export const GET_USER_STATS_SUCCESS = "GET_USER_STATS_SUCCESS";
export const GET_USER_STATS_FAILURE = "GET_USER_STATS_FAILURE";

export const GET_POST_CREATED_STATS = "GET_POST_CREATED_STATS";
export const GET_POST_CREATED_STATS_SUCCESS = "GET_POST_CREATED_STATS_SUCCESS";
export const GET_POST_CREATED_STATS_ERROR = "GET_POST_CREATED_STATS_ERROR";

export const GET_SERVICE_USER_STATS = "GET_SERVICE_USER_STATS";
export const GET_SERVICE_USER_STATS_SUCCESS = "GET_SERVICE_USER_STATS_SUCCESS";
export const GET_SERVICE_USER_STATS_FAILURE = "GET_SERVICE_USER_STATS_FAILURE";

export const GET_NO_OF_SERVICE_STATS = "GET_NO_OF_SERVICE_STATS";
export const GET_NO_OF_SERVICE_STATS_SUCCESS =
  "GET_NO_OF_SERVICE_STATS_SUCCESS";
export const GET_NO_OF_SERVICE_STATS_FAILURE =
  "GET_NO_OF_SERVICE_STATS_FAILURE";

export const GET_SUBSCRIPTION_STATS = "GET_SUBSCRIPTION_STATS";
export const GET_SUBSCRIPTION_STATS_SUCCESS = "GET_SUBSCRIPTION_STATS_SUCCESS";
export const GET_SUBSCRIPTION_STATS_FAILURE = "GET_SUBSCRIPTION_STATS_FAILURE";

export const SERVICE_REQUESTED_STATS = "SERVICE_REQUESTED_STATS";
export const SERVICE_REQUESTED_STATS_SUCCESS =
  "SERVICE_REQUESTED_STATS_SUCCESS";
export const SERVICE_REQUESTED_STATS_FAILURE =
  "SERVICE_REQUESTED_STATS_FAILURE";

//Contact Support
export const GET_ALL_SUPPORT_MESSAGES = "GET_ALL_SUPPORT_MESSAGES";
export const GET_ALL_SUPPORT_MESSAGES_SUCCESS =
  "GET_ALL_SUPPORT_MESSAGES_SUCCESS";
export const GET_ALL_SUPPORT_MESSAGES_FAILURE = "GET_ALL_MESSAGES_FAILURE";

export const DELETE_SUPPORT_MESSAGE = "DELETE_SUPPORT_MESSAGE";
export const DELETE_SUPPORT_MESSAGE_SUCCESS = "DELETE_SUPPORT_MESSAGE_SUCCESS";
export const DELETE_SUPPORT_MESSAGE_FAILURE = "DELETE_SUPPORT_MESSAGE_FAILURE";

export const SEND_SUPPORT_MESSAGE_REPLY = "SEND_SUPPORT_MESSAGE_REPLY";
export const SEND_SUPPORT_MESSAGE_REPLY_SUCCESS =
  "SEND_SUPPORT_MESSAGE_REPLY_SUCCESS";
export const SEND_SUPPORT_MESSAGE_REPLY_FAILURE =
  "SEND_SUPPORT_MESSAGE_REPLY_FAILURE";

export const GET_USER_EMAILS = "GET_USER_EMAILS";
export const GET_USER_EMAILS_SUCCESS = "GET_USER_EMAILS_SUCCESS";
export const GET_USER_EMAILS_FAILURE = "GET_USER_EMAILS_FAILURE";

export const SEND_CUSTOM_EMAIL = "SEND_CUSTOM_EMAIL";
export const SEND_CUSTOM_EMAIL_SUCCESS = "SEND_CUSTOM_EMAIL_SUCCESS";
export const SEND_CUSTOM_EMAIL_FAILURE = "SEND_CUSTOM_EMAIL_FAILURE";

export const GET_ADS_REQUEST = "GET_ADS_REQUEST";
export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS";
export const GET_ADS_FAILURE = "GET_ADS_FAILURE";

export const UPDATE_AD_REQUEST = "UPDATE_AD_REQUEST";
export const UPDATE_AD_SUCCESS = "UPDATE_AD_SUCCESS";
export const UPDATE_AD_FAILURE = "UPDATE_AD_FAILURE";

export const DELETE_AD_REQUEST = "DELETE_AD_REQUEST";
export const DELETE_AD_SUCCESS = "DELETE_AD_SUCCESS";
export const DELETE_AD_FAILURE = "DELETE_AD_FAILURE";

export const EDIT_AD_REQUEST = "EDIT_AD_REQUEST";
export const EDIT_AD_SUCCESS = "EDIT_AD_SUCCESS";
export const EDIT_AD_FAILURE = "EDIT_AD_FAILURE";

//Promo code
export const GET_ALL_PROMOS = "GET_ALL_PROMOS";
export const GET_ALL_PROMOS_SUCCESS = "GET_ALL_PROMOS_SUCCESS";
export const GET_ALL_PROMOS_FAILURE = "GET_ALL_PROMOS_FAILURE";

export const ADD_PROMO = "ADD_PROMO";
export const ADD_PROMO_SUCCESS = "ADD_PROMO_SUCCESS";
export const ADD_PROMO_FAILURE = "ADD_PROMO_FAILURE";

export const EDIT_PROMO = "EDIT_PROMO";
export const EDIT_PROMO_SUCCESS = "EDIT_PROMO_SUCCESS";
export const EDIT_PROMO_FAILURE = "EDIT_PROMO_FAILURE";

export const DELETE_PROMO = "DELETE_PROMO";
export const DELETE_PROMO_SUCCESS = "DELETE_PROMO_SUCCESS";
export const DELETE_PROMO_FAILURE = "DELETE_PROMO_FAILURE";

export const UPDATE_PROMO_STATUS = "UPDATE_PROMO_STATUS";
export const UPDATE_PROMO_STATUS_SUCCESS = "UPDATE_PROMO_STATUS_SUCCESS";
export const UPDATE_PROMO_STATUS_FAILURE = "UPDATE_PROMO_STATUS_FAILURE";

//Service provider business documents
export const SERVICE_PROVIDER_BUSINESS_STATUS = "SERVICE_PROVIDER_BUSINESS_STATUS"
export const SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS = "SERVICE_PROVIDER_BUSINESS_STATUS_SUCCESS"
export const SERVICE_PROVIDER_BUSINESS_STATUS_FAILURE = "SERVICE_PROVIDER_BUSINESS_STATUS_FAILURE"

//count 
export const GET_COUNT = "GET_COUNT";
export const GET_COUNT_SUCCESS = "GET_COUNT_SUCCESS";
export const GET_COUNT_FAILURE = "GET_COUNT_FAILURE";

//dashboard Counts section Update 
export const ACTION_REQ = "ACTION_REQ";
export const ACTION_REQ_SUCCESS = "ACTION_REQ_SUCCESS";
export const ACTION_REQ_FAILURE = "ACTION_REQ_FAILURE";

export const SIGNUPS = "SIGNUPS";
export const SIGNUPS_SUCCESS = "SIGNUPS_SUCCESS";
export const SIGNUPS_FAILURE = "SIGNUPS_FAILURE";

export const DELETED_ACC = "DELETED_ACC";
export const DELETED_ACC_SUCCESS = "DELETED_ACC_SUCCESS";
export const DELETED_ACC_FAILURE = "DELETED_ACC_FAILURE";

export const SERVICE_PROVIDER = "SERVICE_PROVIDER";
export const SERVICE_PROVIDER_SUCCESS = "SERVICE_PROVIDER_SUCCESS";
export const SERVICE_PROVIDER_FAILURE = "SERVICE_PROVIDER_FAILURE";

export const JOBS = "JOBS";
export const JOBS_SUCCESS = "JOBS_SUCCESS";
export const JOBS_FAILURE = "JOBS_FAILURE";

export const ADS = "ADS";
export const ADS_SUCCESS = "ADS_SUCCESS";
export const ADS_FAILURE = "ADS_FAILURE";

export const SERVICES = "SERVICES";
export const SERVICES_SUCCESS = "SERVICES_SUCCESS";
export const SERVICES_FAILURE = "SERVICES_FAILURE";

export const POST_AND_REPORTS = "POST_AND_REPORTS";
export const POST_AND_REPORTS_SUCCESS = "POST_AND_REPORTS_SUCCESS";
export const POST_AND_REPORTS_FAILURE = "POST_AND_REPORTS_FAILURE";