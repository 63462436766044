import Axios from "axios";
// import AsyncStorage from "@react-native-community/async-storage";
import { AppAction, AuthAction } from "../../store/actions";
import Store from "../../store";
import variables from "../variables";

const { baseUrl } = variables


Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    ({ response }) => {
      if (response.status == 401) {
        try {
          localStorage.removeItem("authUser");
          Store.dispatch(AuthAction.signInSuccess());
          window.location.reload();
        } catch (err) {}
      }
      return response;
    }
  );
  
  Axios.interceptors.request.use(
    function (config) {
      if (localStorage.getItem("authUser")) {
        config.headers = {
          ...config?.headers,
          "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
        };
        return config;
      } else {
        return config;
      }
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  const CancelToken = Axios.CancelToken;

export default class ApiCaller {

    static Get = (endPoint = "", headers = {}) => {
        return Axios.get(`${baseUrl}${endPoint}`, {
            headers
        }).then((res) => res).catch((err) => err.response)
    }

    static Post = (endPoint = "", body = {}, headers = {}) => {
        return Axios.post(`${baseUrl}${endPoint}`, body, {
            headers
        }).then((res) => res).catch((err) => err.response)
    }
    static Patch = (endPoint = "", body = {}, headers = {}) => {
        return Axios.patch(`${baseUrl}${endPoint}`, body, {
            headers
        }).then((res) => res).catch((err) => err.response)
    }
    static Put = (endPoint = "", body = {}, headers = {}) => {
        return Axios.put(`${baseUrl}${endPoint}`, body, {
            headers
        }).then((res) => res).catch((err) => err.response)
    }
    static Delete = (endPoint = "", headers = {}) => {
        return Axios.delete(`${baseUrl}${endPoint}`, {
            headers
        }).then((res) => res).catch((err) => err.response)
    }
}